import { useContext, useState, useEffect } from 'react';

// External libraries
import { Button, Form, Modal, Image, Row, Col, Card } from 'react-bootstrap';
import numeral from 'numeral';
import '../../../utils/numeral/it';

// Components & utils
import { GlobalContext } from '../../Layout/Layout';
import { editFavorite, getCart } from '../../../utils/users';
import { getSellerDetails, addToCart } from '../../../utils/products';
import ProductDetailModal from './ProductDetailModal';

// Assets
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import {ReactComponent as Waiter} from '../../../assets/img/waiter-white.svg';
import {ReactComponent as WaiterRed} from '../../../assets/img/waiter-red.svg';
import DoneIcon from '../../../assets/img/icons/done-icon.svg';
import ShoppingCardIcon from '../../../assets/img/icons/shoppingcart-icon.svg';
import {ReactComponent as IconGelo } from '../../../assets/img/icons/icon-gelo.svg';

export default function MobileHorizontalProductCard({ product }) {
    const { user, setUser, domain, setCart, setDisplayMiniCart } = useContext(GlobalContext);
    const [ isFavorite, setIsFavorite ] = useState(false);
    const [ image, setImage ] = useState(`${domain}/wp-content/plugins/qualimenti-react/assets/img/no-image-wide.png`);
    const [ quantity, setQuantity ] = useState(1);
    const [ minQuantity, setMinQuantity ] = useState(1);
    const [ quantityStep, setQuantityStep ] = useState(1);
    const [ waiting, setWaiting ] = useState(false);
    const [ loadFavorite, setLoadFavorite ] = useState(false);
    const [ done, setDone ] = useState(false);
    const [ showDescription, setShowDescription ] = useState(false);
    const [ sellerDetails, setSellerDetails ] = useState(null);
    numeral.locale('it');

    const manageFavorite = (e) => {
        setLoadFavorite(true);
        editFavorite(product.id, user.favorites && user.favorites.includes(product.id.toString()) ? "remove" : "add").then(response => {
            setLoadFavorite(false);
            if(response.favorites) {
                setUser({
                    ...user,
                    favorites: response.favorites
                })
            }
        }).catch(error => {
            setLoadFavorite(false);
            alert('A technical error occured')
        })
    }

    const handleAddToCart = (e) => {
        setWaiting(true)
        if((quantity*1000).toFixed(0)%(quantityStep*1000).toFixed(0) !== 0) {
            alert(`La quantità richiesta non è valida, deve essere un multiplo di ${quantityStep}`)
            setWaiting(false)
            return;
        }

        addToCart(product.id, quantity, user['wc-nonce']).then(response => {
            getCart(user).then(cart => {
                setShowDescription(false)
                setCart(cart)
                setWaiting(false)
                setDisplayMiniCart(true)
                setDone(true)
                setTimeout(() => {
                    setDone(false)
                }, 5000)
            }).catch(error => {
                console.log(error)
                alert('A technical error occured')
            })
        }).catch(error => {
            alert('A technical error occured')
        })
    }

    const handleDisplaySellerDetails = (id) => {
        getSellerDetails(id).then(response => {
            setSellerDetails(response)
        }).catch(error => {
            alert('A technical error occured')
        })
    }

    useEffect(() => {
        if(user.favorites && user.favorites.includes(product.id.toString())) {
            setIsFavorite(true);
        } else {
            setIsFavorite(false);
        }

        if(product.minimum_quantity && product.minimum_quantity !== "" && product.minimum_quantity > 0) {
            setMinQuantity(product.minimum_quantity);
            setQuantity(product.minimum_quantity);
        }

        if(product.quantity_step && product.quantity_step !== "" && product.quantity_step > 0) setQuantityStep(product.quantity_step);
        
        // eslint-disable-next-line
    }, [user]);

    useEffect(() => {
        if(product.image) 
            setImage(product.image);
        else {
            product.categories.sort((a, b) => b.id - a.id)
            product.categories.some(category => {
                if(category.image) {
                    setImage(category.image);
                    return true;
                } else {
                    return false;
                }
            })
        }
        // eslint-disable-next-line
    }, [product]);

    return (
        <Card className="w-100 shadow" style={{ borderRadius: '27px' }}>
            {product.description && product.description !== "" && (
                <ProductDetailModal 
                    product={product} 
                    showDescription={showDescription} 
                    setShowDescription={setShowDescription} 
                    image={image} 
                    quantity={quantity} 
                    setQuantity={setQuantity} 
                    handleAddToCart={handleAddToCart} 
                    waiting={waiting} 
                    done={done} 
                    minQuantity={minQuantity} 
                    quantityStep={quantityStep}
                    loadFavorite={loadFavorite}
                    isFavorite={isFavorite}
                    manageFavorite={manageFavorite}
                />
            )}

            <Modal show={sellerDetails ? true : false} onHide={() => setSellerDetails(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>{sellerDetails ? sellerDetails.name : ''}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {sellerDetails && sellerDetails.infos && sellerDetails.infos.minimum_order && sellerDetails.infos.minimum_order > 0 ? (
                        <><b>Ordine minimo</b>: {sellerDetails.infos.minimum_order} €<br /></>
                    ) : null}
                    {sellerDetails && sellerDetails.infos && sellerDetails.infos.shippment_price && sellerDetails.infos.shippment_price > 0 ? (
                        <><b>Spese di spedizione</b>: {sellerDetails.infos.shippment_price} €<br /></>
                    ) : null}
                    {sellerDetails && sellerDetails.infos && sellerDetails.infos.free_shipping && sellerDetails.infos.free_shipping > 0 ? (
                        <><b>Spedizione gratuita con un ordine minimo di</b>: {sellerDetails.infos.free_shipping} €<br /></>
                    ) : null}
                    {sellerDetails && sellerDetails.infos && sellerDetails.infos.discount_rules && sellerDetails.infos.discount_rules.length > 0 && (
                        <>
                            <hr />
                            <h5>Sconti fornitori</h5>
                            {sellerDetails.infos.discount_rules.map((rule, index) => (
                                <div key={index}>
                                    Da {numeral(rule.min).format('0,0.00 $')} a {numeral(rule.max).format('0,0.00 $')} : {numeral(rule.discount/100).format('0,0.00 %')}
                                </div>
                            ))}
                        </>
                    )}
                </Modal.Body>
            </Modal>
            
            <Card.Body>
                <Row>
                    <Col xs={5} style={{position: 'relative'}}>
                        <Image src={image} style={{
                            width: '100%', 
                            objectFit: 'cover',
                            aspectRatio: '1/1',
                            borderRadius: '15px'
                        }} onClick={() => setShowDescription(true)} />
                        <div style={{
                            position: 'absolute',
                            top: 0,
                            right: 10,
                            padding: 10
                        }}>
                            <div style={{
                                width: 28,
                                minWidth: 28,
                                height: 28,
                                backgroundColor: '#fff',
                                borderRadius: '50%',
                            }} className="shadow d-flex justify-content-center align-items-center" onClick={manageFavorite}>
                                {loadFavorite ? (
                                    <WaiterRed style={{width: 14, height: 14}} />
                                ) : <>
                                    {isFavorite ? 
                                        <AiFillHeart className="favorite-star" style={{width: 14, height: 14, filter: 'drop-shadow(0px 0px 4px rgb(255 255 255))'}} /> : 
                                        <AiOutlineHeart className="favorite-star" style={{width: 14, height: 14, filter: 'drop-shadow(0px 0px 4px rgb(255 255 255))'}} />}
                                </>}
                            </div>
                        </div>
                        {product && product.metas && product.metas.condition && product.metas.condition.value.toLowerCase() === 'gelo' && <div style={{
                            position: 'absolute',
                            bottom: 10,
                            left: 20,
                        }}><IconGelo /></div>}
                    </Col>
                    <Col xs={7} className="d-flex flex-column justify-content-between">
                        <h5 style={{fontSize: '16px', fontWeight: '500'}} className="truncate-2lines mb-1" onClick={() => setShowDescription(true)}>{product.name}</h5>
                        <div className="text-truncate" style={{fontSize: '14px', textDecoration: 'underline' }} onClick={() => handleDisplaySellerDetails(product.seller_id)}>{product && product.seller}</div>
                        <div style={{color: '#ae3335', fontSize: '18px', fontWeight: '700'}}>{numeral(product.price).format('0,0.00$')}{product.quantity_type && product.quantity_type !== "" ? <span>/{product.quantity_type}</span> : product.weight && product.weight !== "" && product.weight > 0 ? <span>/KG</span> : <span>/PZ</span>}</div>
                    </Col>
                </Row>
                <Row className="mt-3 align-items-center">
                    <Col xs={5} className="d-flex justify-content-between align-items-center">
                        <div style={{ minWidth: '28px', width: '28px', height: '28px', backgroundColor: '#fff', border: '1px solid #AE3335', color: '#AE3335', fontSize: 18, fontWeight: 500 }} className="rounded-circle d-flex justify-content-center align-items-center" onClick={() => {
                            if(quantity - quantityStep >= minQuantity) {
                                setQuantity(Math.floor(((quantity - quantityStep)*1000))/1000)
                            }
                        }}>-</div>
                        <Form.Control type="number" style={{ width: 62, borderRadius: '8px', fontSize: 12}} min={minQuantity} step={quantityStep} value={quantity} onChange={e => setQuantity(e.target.value)} />
                        <div style={{ minWidth: '28px', width: '28px', height: '28px', backgroundColor: '#fff', border: '1px solid #AE3335', color: '#AE3335', fontSize: 18, fontWeight: 500 }} className="rounded-circle d-flex justify-content-center align-items-center" onClick={() => {
                            setQuantity(Math.floor(((quantity + quantityStep)*1000))/1000)
                        }}>+</div>
                    </Col>
                    <Col xs={5} className="text-nowrap">
                        Totale : {numeral(product.price * quantity).format('0,0.00 $')}
                    </Col>
                    <Col xs={2} className="d-flex justify-content-end">
                        <Button variant={done ? 'qualimenti-outlined' : 'qualimenti'} className="text-nowrap d-flex justify-content-center align-items-center p-0" onClick={handleAddToCart} disabled={waiting} style={{ borderRadius: '40px', width: 36, height: 36 }}>
                            {waiting ? <Waiter style={{width: 23, height: 23}} /> : done ? <Image src={DoneIcon} style={{width: 23}} /> : <Image src={ShoppingCardIcon} style={{width: 23}} />}
                        </Button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}