import { Card } from 'react-bootstrap';
import { useContext, useState, useEffect } from 'react';
import numeral from 'numeral';
import { Row, Col, Button, Form, Modal } from 'react-bootstrap';
import '../../../utils/numeral/it';
import { GlobalContext } from '../../Layout/Layout';
import { editFavorite, getCart } from '../../../utils/users';
import { getSellerDetails } from '../../../utils/products';
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { TbCheck } from "react-icons/tb";
import { FaBalanceScaleLeft } from "react-icons/fa";
import { addToCart } from '../../../utils/products';
import {ReactComponent as Waiter} from '../../../assets/img/waiter-white.svg';
import {ReactComponent as WaiterRed} from '../../../assets/img/waiter-red.svg';
// import { FaCheck } from "react-icons/fa";
import { BsShop } from "react-icons/bs";

export default function ProductCard({ product }) {
    const { user, setUser, domain, setCart, setDisplayMiniCart } = useContext(GlobalContext);
    const [ isFavorite, setIsFavorite ] = useState(false);
    const [ image, setImage ] = useState(`${domain}/wp-content/plugins/qualimenti-react/assets/img/no-image-wide.png`);
    const [ quantity, setQuantity ] = useState(1);
    const [ minQuantity, setMinQuantity ] = useState(1);
    const [ quantityStep, setQuantityStep ] = useState(1);
    const [ waiting, setWaiting ] = useState(false);
    const [ loadFavorite, setLoadFavorite ] = useState(false);
    const [ done, setDone ] = useState(false);
    const [ displayFullTitle, setDisplayFullTitle ] = useState(false);
    const [ showDescription, setShowDescription ] = useState(false);
    const [ sellerDetails, setSellerDetails ] = useState(null);
    numeral.locale('it');

    const manageFavorite = (e) => {
        setLoadFavorite(true);
        editFavorite(product.id, user.favorites && user.favorites.includes(product.id.toString()) ? "remove" : "add").then(response => {
            setLoadFavorite(false);
            if(response.favorites) {
                setUser({
                    ...user,
                    favorites: response.favorites
                })
            }
        }).catch(error => {
            setLoadFavorite(false);
            alert('A technical error occured')
        })
    }

    const handleAddToCart = (e) => {
        setWaiting(true)
        if((quantity*100).toFixed(0)%(quantityStep*100).toFixed(0) !== 0) {
            alert(`La quantità richiesta non è valida, deve essere un multiplo di ${quantityStep}`)
            setWaiting(false)
            return;
        }
        
        addToCart(product.id, quantity, user['wc-nonce']).then(response => {
            getCart(user).then(cart => {
                setCart(cart)
                setWaiting(false)
                setDisplayMiniCart(true)
                setDone(true)
                setTimeout(() => {
                    setDone(false)
                }, 5000)
            }).catch(error => {
                console.log(error)
                alert('A technical error occured')
            })
        }).catch(error => {
            alert('A technical error occured')
        })
    }

    const handleDisplaySellerDetails = (id) => {
        getSellerDetails(id).then(response => {
            setSellerDetails(response)
        }).catch(error => {
            alert('A technical error occured')
        })
    }

    useEffect(() => {
        if(user.favorites && user.favorites.includes(product.id.toString())) {
            setIsFavorite(true);
        } else {
            setIsFavorite(false);
        }
        // eslint-disable-next-line
    }, [user]);

    useEffect(() => {
        if(product.image) 
            setImage(product.image);
        else {
            product.categories.sort((a, b) => b.id - a.id)
            product.categories.some(category => {
                if(category.image) {
                    setImage(category.image);
                    return true;
                } else {
                    return false;
                }
            })
        }

        if(product.minimum_quantity && product.minimum_quantity !== "" && product.minimum_quantity > 0) {
            setMinQuantity(product.minimum_quantity);
            setQuantity(product.minimum_quantity);
        }

        if(product.quantity_step && product.quantity_step !== "" && product.quantity_step > 0) setQuantityStep(product.quantity_step);
        // eslint-disable-next-line
    }, [product]);

    return (
        <Card className="w-100 shadow-none" onMouseEnter={() => setDisplayFullTitle(true)} onMouseLeave={() => setDisplayFullTitle(false)}>
            {product.description && product.description !== "" && (
                <Modal show={showDescription} onHide={() => setShowDescription(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{product.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body dangerouslySetInnerHTML={{__html: product.description}} />
                </Modal>
            )}

            <Modal show={sellerDetails ? true : false} onHide={() => setSellerDetails(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>{sellerDetails ? sellerDetails.name : ''}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {sellerDetails && sellerDetails.infos && sellerDetails.infos.minimum_order && sellerDetails.infos.minimum_order > 0 ? (
                        <><b>Ordine minimo</b>: {sellerDetails.infos.minimum_order} €<br /></>
                    ) : null}
                    {sellerDetails && sellerDetails.infos && sellerDetails.infos.shippment_price && sellerDetails.infos.shippment_price > 0 ? (
                        <><b>Spese di spedizione</b>: {sellerDetails.infos.shippment_price} €<br /></>
                    ) : null}
                    {sellerDetails && sellerDetails.infos && sellerDetails.infos.free_shipping && sellerDetails.infos.free_shipping > 0 ? (
                        <><b>Spese di spedizione gratuite</b>: {sellerDetails.infos.free_shipping} €<br /></>
                    ) : null}
                    {sellerDetails && sellerDetails.infos && sellerDetails.infos.discount_rules && sellerDetails.infos.discount_rules.length > 0 && (
                        <>
                            <hr />
                            <h5>Sconti fornitori</h5>
                            {sellerDetails.infos.discount_rules.map((rule, index) => (
                                <div key={index}>
                                    Da {numeral(rule.min).format('0,0.00 $')} a {numeral(rule.max).format('0,0.00 $')} : {numeral(rule.discount/100).format('0,0.00 %')}
                                </div>
                            ))}
                        </>
                    )}
                </Modal.Body>
            </Modal>
            
            <div className={`full-title ${displayFullTitle ? "fadeIn" : "fadeOut"}`}>
                {product.name}
                {product.description && product.description !== "" && (
                    <Button variant="qualimenti mt-2" style={{fontSize: '0.8em'}} onClick={() => setShowDescription(true)}>Maggiori informazioni</Button>
                )}
            </div>
            {product.weight && product.weight !== "" && product.weight > 0 && (
                <div style={{
                    position: 'absolute',
                    top: 70,
                    right: 10,
                    backgroundColor: 'RGBA(174, 52, 53, 0.7)',
                    color: 'white',
                    padding: '0.5em',
                    borderRadius: '0.3em',
                    textAlign: 'right'
                }} className={`${displayFullTitle ? "fadeOut" : "fadeIn"}`}>
                    <small><FaBalanceScaleLeft /> {numeral(parseFloat(product.weight)).format('0,0.00')} kg<br />{numeral(product.price / parseFloat(product.weight)).format('0,0.00 $')} / Kg</small>
                </div>
            )}
            <Card.Img variant="top" src={image} style={{height: 250, objectFit: 'contain'}} />
            <Card.Body className="d-flex flex-column justify-content-between">
                <Card.Title dangerouslySetInnerHTML={{__html: `${product.name}`}} style={{fontSize: '1em', margin: 0}} className="text-truncate" />
                <hr />
                <Row className="align-items-center">
                    <Col xs={7} className="d-flex align-items-center">
                        <Form.Control size="sm" type="number" min={minQuantity} step={quantityStep} value={quantity} onChange={e => setQuantity(e.target.value)} />
                        <Button size="sm" className="btn-qualimenti ms-2" style={{ borderRadius: '50px' }} onClick={handleAddToCart} disabled={waiting}>
                            {waiting ? <Waiter style={{width: 14, height: 14, marginTop: '-3px'}} /> : done ? <TbCheck size="14" style={{marginTop: '-3px'}} /> : <HiOutlineShoppingBag style={{marginTop: '-3px'}} size="14" />}
                        </Button>
                    </Col>
                    {product.price && (
                        <Col xs={5}>
                            <div className="d-flex align-items-center justify-content-end">
                                <h6 className="text-nowrap text-right m-0" style={{fontSize: '1.1rem'}}><b className="text-red">{numeral(product.price).format('0,0.00 $')}</b></h6>
                                {product.quantity_type && product.quantity_type !== "" ? <small className='ms-1 text-red'>/{product.quantity_type}</small> : product.weight && product.weight !== "" && product.weight > 0 ? <small className='ms-1 text-red'>/KG</small> : <small className='ms-1 text-red'>/PZ</small>}
                            </div>
                            <div className="text-right" style={{lineHeight: '0.7em'}}>
                                <small style={{fontSize: '0.6em'}}>IVA : {numeral(product.price_tax - product.price).format('0,0.00 $')}</small>
                            </div>
                        </Col>
                    )}
                </Row>
                <Row className="mt-1">
                    <Col xs={2} onClick={manageFavorite}>
                        {loadFavorite ? (
                            <WaiterRed style={{width: 16, height: 16}} />
                        ) : <>
                            {isFavorite ? <AiFillHeart className="favorite-star" style={{filter: 'drop-shadow(0px 0px 4px rgb(255 255 255))'}} /> : <AiOutlineHeart className="favorite-star" style={{filter: 'drop-shadow(0px 0px 4px rgb(255 255 255))'}} />}
                        </>}
                    </Col>
                    <Col xs={10} className="d-flex align-items-center justify-content-end">
                        <a href="#!" className="text-dark" onClick={() => handleDisplaySellerDetails(product.seller_id)}><small className="text-truncate">{product && product.seller}</small><BsShop size={14} className="ms-1" style={{marginTop: '-3px'}} /></a>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}