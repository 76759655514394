const axios = require('axios');
const mapsApiKey = "AIzaSyCm5p1S0oexj3My9srtc4gupMa8l5ypmD8";

export async function getPlace(id) {
    return new Promise((resolve, reject) => {
        const url = `https://places.googleapis.com/v1/places/${id}?fields=id,displayName,formattedAddress,addressComponents,primaryTypeDisplayName&key=${mapsApiKey}`;
        axios.get(url)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
    });
}