import { useContext, useEffect, useState, useRef } from 'react'
import { Navigate, Link } from 'react-router-dom'
import { GlobalContext } from '../Layout/Layout'
import { Row, Col, Card, Form, Badge, Button } from 'react-bootstrap'
import ProductCard from './components/ProductCard'
import HorizontalProductCard from './components/HorizontalProductCard'
import MobileHorizontalProductCard from './components/MobileHorizontalProductCard'
import { useNavigate } from 'react-router-dom'
import Loader from './components/Loader';
import { getProductsByIds, getTopProductCategories, getHighlightedProducts } from '../../utils/products'
import { FaRegTimesCircle } from 'react-icons/fa';
import { BsArrowRightSquare, BsArrowDownSquare } from 'react-icons/bs';
import { TbTruckDelivery, TbShoppingCart } from 'react-icons/tb';
import numeral from 'numeral';
import { updateCartQuantity, removeCartItem } from '../../utils/products'
import { getCart, clearUserShippingSession } from '../../utils/users'
import { Puff } from 'react-loader-spinner'
import { isMobile } from 'react-device-detect'

export default function Home() {
    numeral.locale('it');
    const navigate = useNavigate()
    const { user, cart, setCart, domain, setDisplayCart, displayCart, sessionShipping } = useContext(GlobalContext)
    const [ favorites, setFavorites ] = useState([])
    const [ favoriteSellers, setFavoriteSellers ] = useState([])
    const [ displayMode, setDisplayMode ] = useState('favorites')
    const [ displayAllFavites, setDisplayAllFavorites ] = useState(false)
    const [ orderable, setOrderable ] = useState(true)
    const [ gLoading, setGLoading ] = useState(true)

    const [ categoryFilters, setCategoryFilters ] = useState({})
    const [ selectedCategories, setSelectedCategories ] = useState([])
    const [ filterOpen, setFilterOpen ] = useState(false)
    const [ productDisplayMode, setProductDisplayMode ] = useState(isMobile ? 'grid' : 'list');

    const [ headCategories, setHeadCategories ] = useState([])
    const [ hightlightProducts, setHightlightProducts ] = useState([])
    

    const handleCategoryFilter = (e) => {
        if (e.target.checked) {
            setSelectedCategories([...selectedCategories, parseInt(e.target.id)])
        } else {
            setSelectedCategories(selectedCategories.filter(item => item !== parseInt(e.target.id)))
        }
    }

    const handleOpenFilter = () => {
        setFilterOpen(filterOpen ? false : true)
    }

    useEffect(() => {
        if(user && user.favorites && user.favorites.length > 0) {
            setGLoading(true)
            getProductsByIds(user.favorites).then(products => {
                setGLoading(false)
                let displayProducts = [];
                products.products.map(product => {
                    product.categories.sort((a, b) => b.id - a.id)
                    if(product.categories.length > 0) setCategoryFilters(categoryFilters => ({...categoryFilters, [product.categories[0].id]: product.categories[0]}))
                    if(selectedCategories.length > 0) {
                        if(selectedCategories.includes(product.categories[0].id)) displayProducts.push(product)
                    } else {
                        displayProducts.push(product)
                    }
                    return true;
                })
                setFavorites(displayProducts)
                products.filters.sellers.sort((a, b) => a.name < b.name ? -1 : 1)
                setFavoriteSellers(products.filters.sellers)
            }).catch(error => {
                setGLoading(false)
                alert('A technical error occured')
            })
        }
        getHighlightedProducts().then(products => {
            setGLoading(false)
            let displayProducts = [];
            products.products.map(product => {
                product.categories.sort((a, b) => b.id - a.id)
                if(product.categories.length > 0) setCategoryFilters(categoryFilters => ({...categoryFilters, [product.categories[0].id]: product.categories[0]}))
                if(selectedCategories.length > 0) {
                    if(selectedCategories.includes(product.categories[0].id)) displayProducts.push(product)
                } else {
                    displayProducts.push(product)
                }
                return true;
            })
            setHightlightProducts(displayProducts)
        }).catch(error => {
            setGLoading(false)
            console.log(error)
        })
    }, [user, selectedCategories])

    useEffect(() => {
        if(displayCart === true) setDisplayMode('cart'); else setDisplayMode('favorites');
    }, [displayCart])

    useEffect(() => {
        let block = false;
        cart && cart.sellers && Object.values(cart.sellers).map(seller => {
            if(seller.total < seller.seller.minimum_quantity) block = true;
            return true;
        })

        if(!user || !user.sdi || !user.iva || user.sdi === "" || user.iva === "") block = true;

        if(block === true) setOrderable(false); else setOrderable(true);
        // eslint-disable-next-line
    }, [cart])

    useEffect(() => {
        getTopProductCategories().then(categories => {
            setHeadCategories(categories)
        }).catch(error => {
            console.log(error)
        })
        setProductDisplayMode(isMobile ? 'grid' : 'list')
    }, [])

    const handleResetSession = () => {
        clearUserShippingSession().then(() => {
            console.log('session cleared')
            window.location.reload()
        }).catch(error => {
            console.log(error)
        })
    }

    const CartItem = ({ item }) => {
        const [ quantity, setQuantity ] = useState(item.quantity)
        const [ minQuantity, setMinQuantity ] = useState(item.min_quantity)
        const [ quantityStep, setQuantityStep ] = useState(item.quantity_step)
        const [ loading, setLoading ] = useState(false)
        const [ image, setImage ] = useState(`${domain}/wp-content/plugins/qualimenti-react/assets/img/no-image-wide.png`);
        const quantityTimer = useRef(null)

        const handleQuantityChange = (e) => {
            setQuantity(e.target.value)
            if(quantityTimer.current) clearTimeout(quantityTimer.current)
            quantityTimer.current = setTimeout(() => {
                if((e.target.value*1000).toFixed(0)%(quantityStep*1000).toFixed(0) !== 0) {
                    alert(`La quantità richiesta non è valida, deve essere un multiplo di ${quantityStep}`)
                    return;
                }
                setLoading(true)
                updateCartQuantity(item.key, e.target.value, user['wc-nonce']).then(response => {
                    getCart(user).then(cart => {
                        setCart(cart);
                        setLoading(false)
                    }).catch(error => {
                        console.log(error)
                        setLoading(false)
                        alert('Unable to update cart informations')    
                    })
                }).catch(error => {
                    console.log(error)
                    setLoading(false)
                    alert('Unable to update quantity in cart')
                })
            }, 500)
        }

        const handleRemoveCartItem = (e) => {
            setLoading(true)
            removeCartItem(item.key, user['wc-nonce']).then(response => {
                getCart(user).then(cart => {
                    setCart(cart);
                    setLoading(false)
                }).catch(error => {
                    console.log(error)
                    setLoading(false)
                    alert('Unable to update cart informations')
                })
            }).catch(error => {
                console.log(error)
                setLoading(false)
                alert('Unable to remove item from cart')
            })
        }

        useEffect(() => {
            if(item.image) 
                setImage(item.image);
            else if(item.categories) {
                item.categories.sort((a, b) => b.id - a.id)
                item.categories.some(category => {
                    if(category.image) {
                        setImage(category.image);
                        return true;
                    } else {
                        return false;
                    }
                })
            }

            if(item.minimum_quantity && item.minimum_quantity !== "" && item.minimum_quantity > 0) setMinQuantity(item.minimum_quantity);
            if(item.quantity_step && item.quantity_step !== "" && item.quantity_step > 0) setQuantityStep(item.quantity_step);
        }, [item])

        return <Row key={item.key}>
            <Col xs={12} md={2} className="d-none d-md-block" style={{paddingTop: '30px'}}>
                <img src={image} alt={item.name} className="img-fluid h-100 w-100" style={{aspectRatio: 1, objectFit: 'contain'}} />
            </Col>
            <Col xs={12} md={10}>
                <hr />
                <Row className="d-flex">
                    <Col xs={12}>
                        <Row>
                            <Col xs={10}>
                                <div className="text-truncate" style={{fontWeight: '500'}} dangerouslySetInnerHTML={{__html: `${item.name}`}} />
                            </Col>
                            <Col xs={2} className="d-flex justify-content-end">
                                {loading ? (
                                    <Puff
                                        height="20"
                                        width="20"
                                        radius={1}
                                        color="#ae3335"
                                        ariaLabel="puff-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                    />
                                ) : (
                                    <FaRegTimesCircle color="#ae3335" style={{cursor: 'pointer'}} onClick={handleRemoveCartItem} />
                                )}
                            </Col>
                        </Row>
                        <Row className="mt-3 align-items-center">
                            <Col xs={4}>
                                <Form.Control min={minQuantity} step={quantityStep} value={quantity} onChange={handleQuantityChange} type="number" style={{width: '70px'}} />
                            </Col>
                            <Col xs={4} className="d-flex justify-content-center">
                                {numeral(item.price).format('0,0.00 $')}
                            </Col>
                            <Col xs={4} className="d-flex align-items-end flex-column text-right">
                                <h5 className="text-qualimenti">{numeral(item.line_total).format('0,0.00 $')}</h5>
                                {item.discount_type && item.discount_type !== "" && <small>
                                    {item.discount_type === "seller" ? "Sconto venditore : " : null}
                                    {item.discount_type === "product" ? "Sconto prodotto : " : null}
                                    {item.discount_type === "seller_volume" ? "Sconto volume venditore : " : null}
                                    {item.discount_type === "" ? "Sconto : " : null}
                                    {numeral((item.product_price - item.price)*quantity).format('0,0.00 $')}
                                </small>}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    }

    if(!user) return <Navigate to="/login" />
    if(user.iva === "") return <Navigate to="/register" />
    
    return (
        <Row className="bg-content">
            <Col xs={12}>
                <div className="wrapper">
                    {window.innerWidth >= 768 && displayMode === 'favorites' && (
                        <Row className='d-none d-md-flex' style={{marginBottom: "1em", marginTop: '1em'}}>
                            <Col xs={12} className={`d-none d-md-flex ${Object.keys(headCategories).length > 5 ? 'justify-content-between' : 'justify-content-center'}`} style={{gap: '10px'}}>
                                {Object.keys(headCategories).map(category => {
                                    return <Link to={`/products/c/alimenti/${headCategories[category].slug}`} key={category} className="text-decoration-none text-dark">
                                        <div key={category} className="d-fex flex-column align-items-center justify-content-center" style={{
                                            width: '100px',
                                            height: 'auto',
                                        }}>
                                            <div data-id={category} style={{
                                                backgroundImage: headCategories[category].image && headCategories[category].image !== false ? `url(${headCategories[category].image})` : `url(${domain}/wp-content/plugins/qualimenti-react/assets/img/no-image-wide.png)`,
                                                width: '100px',
                                                height: '100px',
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                                backgroundRepeat: 'no-repeat',
                                                borderRadius: '50%',
                                            }}>&nbsp;</div>
                                            <div className="text-center text-truncate mt-2">{headCategories[category].name}</div>
                                        </div>
                                    </Link>
                                })}
                            </Col>
                        </Row>
                    )}
                    <Row style={{marginBottom: "1em"}}>
                        <Col xs={12} className="d-flex justify-content-center justify-content-md-end">
                            <div className="home-tab-container d-flex align-items-center">
                                <div className={`home-tab-item text-uppercase p-2 d-flex align-items-center ${displayMode === 'favorites' ? 'active' : ''}`} onClick={() => { setDisplayMode('favorites'); setDisplayCart(false);}}>
                                    Il mio catalogo
                                </div>
                                <div className={`home-tab-item text-uppercase p-2 d-flex align-items-center ${displayMode === 'cart' ? 'active' : ''}`} onClick={() => { setDisplayMode('cart'); setDisplayCart(true); }}>
                                    Carrello {cart && cart.count && cart.count > 0 && <Badge pill bg="qualimenti" style={{marginLeft: 5}}>{cart.count}</Badge>}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {window.innerWidth < 768 && displayMode === 'favorites' && (
                            <Col xs={12}>
                                {favorites && favorites.length > 0 && (
                                    <>
                                        <div>
                                            <h3 className="text-center mb-4">Il mio catalogo</h3>
                                        </div>
                                        <div>
                                            {favorites.map((product, i) => {
                                                if(!displayAllFavites && i >= 3) return null;
                                                return <div key={product.id} className="mb-3"><MobileHorizontalProductCard product={product} /></div>
                                            })}
                                        </div>
                                        {!displayAllFavites && favorites.length > 3 && (
                                            <div className="text-center mt-4">
                                                <Button variant="qualimenti-outlined" onClick={() => setDisplayAllFavorites(true)}>Mostra tutti i prodotti</Button>
                                            </div>
                                        )}
                                    </>
                                )}
                                {headCategories && Object.keys(headCategories).length > 0 && (
                                    <Row className="mt-4">
                                        <Col xs={12}>
                                            <h3 className="text-center mb-4">Categorie in evidenza</h3>
                                        </Col>
                                        {Object.keys(headCategories).map(category => {
                                            return (
                                                <Col key={category} xs={4} className="mb-4" onClick={() => navigate(`/products/c/alimenti/${headCategories[category].slug}`)}>
                                                    <Card style={{borderRadius: '15px'}}>
                                                        <Card.Img style={{
                                                            height: '84px',
                                                            objectFit: 'cover',
                                                            borderTopLeftRadius: '15px',
                                                            borderTopRightRadius: '15px',
                                                        }} variant="top" src={headCategories[category].image && headCategories[category].image !== false ? headCategories[category].image : `${domain}/wp-content/plugins/qualimenti-react/assets/img/no-image-wide.png`} />
                                                        <Card.Body style={{height: 60}}>
                                                            <Card.Title className="truncate-2lines" style={{fontSize: '14px'}}>{headCategories[category].name}</Card.Title>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                )}
                                {hightlightProducts && hightlightProducts.length > 0 && (
                                    <>
                                        <div>
                                            <h3 className="text-center mb-4">Prodotti suggeriti</h3>
                                        </div>
                                        <div>
                                            {hightlightProducts.map(product => {
                                                return <div key={product.id} className="mb-3"><MobileHorizontalProductCard product={product} /></div>
                                            })}
                                        </div>
                                    </>
                                )}
                            </Col>

                        )}
                        {window.innerWidth >= 768 && displayMode === 'favorites' && favorites && favorites.length > 0 && (
                            <Col xs={12} md={3}>
                                <Card className="mb-3 filters">
                                    <Card.Body className="pb-0">
                                        <Card.Title className="d-block d-md-none">
                                            <Row>
                                                <Col xs={10}>Filtri</Col>
                                                <Col xs={2}>{filterOpen ? <BsArrowDownSquare fill="rgb(174, 51, 53)" onClick={handleOpenFilter} /> : <BsArrowRightSquare fill="rgb(174, 51, 53)" onClick={handleOpenFilter} />}</Col>
                                            </Row>
                                        </Card.Title>
                                        <div className={filterOpen ? "d-block d-md-block" : "d-none d-md-block"}>
                                            <hr className="d-block d-md-none" />
                                            <Row>
                                                <Col xs={12}>
                                                    <h5>Categoria</h5>
                                                    <ul className="filter mt-3 mb-0" style={{maxHeight: 150, overflowY: 'auto'}}>
                                                        {Object.keys(categoryFilters).map(key => (
                                                            <li key={key}><Form.Check type="checkbox" onChange={handleCategoryFilter} id={categoryFilters[key].id} label={categoryFilters[key].name} /></li>
                                                        ))}
                                                    </ul>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card.Body>
                                </Card>
                                {sessionShipping === true && <Card className="mb-3 filters">
                                    <Card.Body className="pb-0">
                                        <div>Hai definito un indirizzo di consegna diverso durante il checkout. Vuoi resettarlo?</div>
                                        <div className="mt-3">
                                            <Button variant="qualimenti" onClick={handleResetSession}>Resetta indirizzo di consegna</Button>
                                        </div>
                                    </Card.Body>
                                </Card>}
                            </Col>
                        )}
                        {window.innerWidth >= 768 && displayMode === 'favorites' && (
                            <Col xs={12} md={9} className="position-relative">
                                {gLoading && <Loader />}
                                {!gLoading && favorites && favorites.length === 0 ? (
                                    <>
                                    <p style={{
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                    }}>Non hai ancora nessun prodotto preferito. Sfoglia le nostre categorie per scegliere i tuoi prodotti preferiti.</p>
                                    <p style={{
                                        textAlign: 'center',
                                    }}>Ecco alcuni prodotti che potrebbero piacerti:</p>
                                    {hightlightProducts && hightlightProducts.length > 0 && (
                                        <Row>
                                            {hightlightProducts.map(product => (
                                                <Col xs={12} key={product.id} className="d-flex" style={{marginBottom: '1em'}}>
                                                    {productDisplayMode === "list" && <HorizontalProductCard product={product} />}
                                                    {productDisplayMode === "grid" && <ProductCard product={product} />}
                                                </Col>
                                            ))}
                                        </Row>
                                    )}
                                    </>
                                ) : (
                                    <Row>
                                        <Col xs={12}>
                                            {favoriteSellers && favoriteSellers.length > 0 && favoriteSellers.map(seller => {
                                                return (
                                                    <Row key={seller.id}>
                                                        <Col xs={12}>
                                                            <h3>{seller.name}</h3>
                                                            <Row>
                                                                {favorites.map(product => {
                                                                    if(parseInt(product.seller_id) === parseInt(seller.id)) {
                                                                        return <Col xs={12} key={product.id} className="d-flex align-items-stretch" style={{marginBottom: '1em'}}>
                                                                            <HorizontalProductCard product={product} />
                                                                        </Col>
                                                                    } else { 
                                                                        return null;
                                                                    }
                                                                })}
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                )
                                            })}
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                        )}
                        {displayMode === 'cart' && (
                            <>
                                {cart && cart.count && cart.count > 0 ? (
                                    <Col xs={12} md={9} className="pe-md-5">
                                        <Row>
                                            <Col xs={12}>
                                                {Object.values(cart.sellers).map(seller => {
                                                    return (
                                                        <Card className="mb-3 p-3">
                                                            <Card.Body>
                                                                <Row key={seller.seller.id} style={{marginBottom: '1em'}}>
                                                                    <Col xs={12}>
                                                                        <Row>
                                                                            <Col xs={12} md={3}>
                                                                                <h4 className="text-uppercase">{seller.seller.name}</h4>
                                                                            </Col>
                                                                            <Col xs={12} md={9}>
                                                                                {seller.total < seller.seller.minimum_quantity && (
                                                                                    <span className="text-qualimenti">Devi raggiungere un ordine minimo di {numeral(seller.seller.minimum_quantity).format('0,0.00 $')}</span>
                                                                                )}
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col xs={12}>
                                                                                {seller.items.map(item => {
                                                                                    return (
                                                                                        <div key={item.key}>
                                                                                            <CartItem key={item.id} item={item} />
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </Col>
                                                                        </Row>
                                                                        {/* <Row className='mt-2'>
                                                                            <Col xs={12}>
                                                                                <Row>
                                                                                    <Col xs={3} className="d-flex align-items-center"></Col>
                                                                                    <Col xs={3} className="text-nowrap d-flex justify-content-end align-items-center text-uppercase"></Col>
                                                                                    <Col xs={3} className="text-right text-nowrap d-flex justify-content-end align-items-center"><b>Totale parziale</b></Col>
                                                                                    <Col xs={3} className="text-right text-nowrap d-flex justify-content-end align-items-center">
                                                                                        <b>{numeral((Math.ceil(seller.total * 100) / 100)).format('0,0.00 $')}</b>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                        </Row> */}
                                                                        {/* {seller.fees && seller.fees.length > 0 && (
                                                                            <Row className="mt-2">
                                                                                <Col xs={12}>
                                                                                    {seller.fees.map(item => (
                                                                                        <Row key={item.id}>
                                                                                            <Col xs={3} className="d-flex align-items-center"></Col>
                                                                                            <Col xs={3} className="text-nowrap d-flex justify-content-end align-items-center"></Col>
                                                                                            <Col xs={3} className="text-right text-nowrap d-flex justify-content-end align-items-center">
                                                                                                <div className="text-truncate" dangerouslySetInnerHTML={{__html: `${item.name.replace(/\([0-9]+\)/, '')}`}} />
                                                                                            </Col>
                                                                                            <Col xs={3} className="text-right text-nowrap d-flex justify-content-end align-items-center">
                                                                                                {numeral(item.amount).format('0,0.00 $')}
                                                                                            </Col>
                                                                                        </Row>
                                                                                    ))}
                                                                                </Col>
                                                                            </Row>
                                                                        )}
                                                                        <Row>
                                                                            <Col xs={12}>
                                                                                <Row>
                                                                                    <Col xs={3} className="d-flex align-items-center"></Col>
                                                                                    <Col xs={3} className="text-nowrap d-flex justify-content-end align-items-center text-uppercase"></Col>
                                                                                    <Col xs={3} className="text-right text-nowrap d-flex justify-content-end align-items-center">Spedizione</Col>
                                                                                    <Col xs={3} className="text-right text-nowrap d-flex justify-content-end align-items-center">
                                                                                        {numeral(seller.shipping).format('0,0.00 $')}
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                        </Row> */}
                                                                    </Col>
                                                                </Row>
                                                            </Card.Body>
                                                        </Card>
                                                    )
                                                })}
                                            </Col>
                                        </Row>
                                    </Col>
                                ) : (
                                    <Col xs={9}>
                                        <p>Il tuo cestino è vuoto</p>
                                    </Col>
                                )}
                            </>
                        )}
                        {displayMode === 'cart' && (
                            <Col xs={12} md={3}>
                                <Row style={{ borderLeft: '4px solid rgb(58,40,33)' }}>
                                    <Col xs={12} className="mb-2">
                                        <h3 className="text-brown text-uppercase">Totale</h3>
                                    </Col>
                                    <Col xs={12}>
                                        {/* <Row>
                                            <Col xs={4} className="text-center"><h4><TbDiscount2 /></h4></Col>
                                            <Col xs={8} className="d-flex justify-content-end text-nowrap">{numeral(parseFloat(cart.totals.fee_total)).format('0,0.00 $')}</Col>
                                        </Row> */}
                                        <Row>
                                            <Col xs={4} className="text-center"><h4><TbTruckDelivery /></h4></Col>
                                            <Col xs={8} className="d-flex justify-content-end text-nowrap">{numeral(parseFloat(cart.totals.shipping_total)).format('0,0.00 $')}</Col>
                                        </Row>
                                        <hr />
                                        <Row>
                                            <Col xs={4} className="text-center">IVA</Col>
                                            <Col xs={8} className="d-flex justify-content-end text-nowrap">{numeral(parseFloat(cart.totals.total_tax)).format('0,0.00 $')}</Col>
                                        </Row>
                                        <hr />
                                        <Row>
                                            <Col xs={4} className="text-center"><h3><TbShoppingCart className="text-qualimenti" /></h3></Col>
                                            <Col xs={8} className="d-flex align-items-end flex-column text-nowrap">
                                                <h3 className="m-0">{numeral(parseFloat(cart.totals.total - cart.totals.total_tax)).format('0,0.00 $')}</h3>
                                                <small>{numeral(parseFloat(cart.totals.total)).format('0,0.00 $')} IVA inclusa</small>
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <Col xs={12} className="d-grid">
                                                <Button variant="qualimenti-outlined" size="lg" disabled={!orderable} onClick={() => document.location.href="/checkout"} style={{borderRadius: '25px'}}>Ordina</Button>
                                                {(!user.sdi || !user.iva || user.sdi === "" || user.iva === "") && <Link to="/my-account/" className="text-red text-decoration-none"><center><small className="text-red">Prima di ordinare, fornisci la tua Partita IVA e codice SDI</small></center></Link>}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        )}
                    </Row>
                </div>
            </Col>
        </Row>
    )
}