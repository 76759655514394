import { useState, useContext } from 'react';
import { Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Navigate, Link } from 'react-router-dom';
import { GlobalContext } from '../Layout/Layout';
import { passwordLost, passwordReset } from '../../utils/users';

export default function PasswordLost() {
    const [email, setEmail] = useState("");
    const [ password, setPassword ] = useState('');
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [resetError, setResetError] = useState("");
    const [resetSuccess, setResetSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const { user } = useContext(GlobalContext);
    let { login, key } = useParams();

    // Get login & key from query string
    if(!login && !key) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        login = urlParams.get('id');
        key = urlParams.get('key');
    }

    const handlePasswordLost = (e) => {
        e.preventDefault();
        setLoading(true);
        setError("");
        setSuccess("");
        if (email === "") {
            setError("Devi compilare tutti i campi");
            setLoading(false);
            return;
        } else {
            passwordLost(email)
                .then((response) => {
                    setSuccess("Email inviata con successo");
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setError(error.message);
                    setLoading(false);
                });
        }
    }

    const handlePasswordReset = (e) => {
        e.preventDefault();
        setLoading(true);
        setResetError("");
        setResetSuccess("");
        if (password === "") {
            setResetError("Devi compilare tutti i campi");
            setLoading(false);
            return;
        } else {
            passwordReset(login, key, password).then((response) => {
                setResetSuccess("Password cambiata con successo");
                setLoading(false);
            }).catch((error) => {
                console.log(error);
                setResetError(error.message);
                setLoading(false);
            });
        }
    };

    if(user) {
        return <Navigate to="/" />
    }

    if(login && key) {
        return <Row className="justify-content-center mt-5">
        <Col xs={12} md={5}>
            <h1 style={{whiteSpace: 'nowrap'}}>Password dimenticata</h1>
            <Form onSubmit={handlePasswordReset}>
                <Form.Group>
                    <Form.Label>Inserisci la tua nuova password</Form.Label>
                    <Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </Form.Group>
                {resetError && <Alert className="mt-2" variant="danger">{resetError}</Alert>}
                {resetSuccess && <Alert className="mt-2" variant="success">{resetSuccess} <Link to="/login">Puoi identificarti</Link></Alert>}
                <div className="d-grid gap-2 mt-2">
                    <Button type="submit" variant="qualimenti" disabled={loading}>
                        {loading ? "Caricamento..." : "Invia password"}
                    </Button>
                </div>
            </Form>
        </Col>
    </Row>
    } else {
        return <Row className="justify-content-center mt-5">
            <Col xs={12} md={5}>
                <h1 style={{whiteSpace: 'nowrap'}}>Password dimenticata</h1>
                <Form onSubmit={handlePasswordLost}>
                    <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </Form.Group>
                    {error && <Alert className="mt-2" variant="danger">{error}</Alert>}
                    {success && <Alert className="mt-2" variant="success">{success}</Alert>}
                    <div className="d-grid gap-2 mt-2">
                        <Button type="submit" variant="qualimenti" disabled={loading}>
                            {loading ? "Caricamento..." : "Invia email"}
                        </Button>
                    </div>
                </Form>
            </Col>
        </Row>
    }
}