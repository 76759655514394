import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const domain = document.location.hostname === 'localhost' ? 'https://qualimenti.twiga.io' : `https://${document.location.hostname}`;

export const getSellerDetails = (id) => {
    return new Promise((resolve, reject) => {
        let options = {
            url: `${domain}/wp-json/qualimenti/v1/seller/${id}`,
            method: 'GET',
            headers: {}
        }
        if(typeof cookies.get('token') !== "undefined") options.headers.Authorization = `Bearer ${cookies.get('token')}`;
        axios(options).then(response => {
            if(response.data) {
                resolve(response.data);
            } else {
                reject(new Error('Seller not found'));
            }
        }).catch(error => {
            reject(new Error('Api Error'));
        })
    })
}

export const getProductCategories = (slug, search) => {
    return new Promise((resolve, reject) => {
        let url = new URL(`${domain}/wp-json/qualimenti/v1/categories`)
        if(slug) url.searchParams.append('slug', slug);
        if(search) url.searchParams.append('search', search);

        let options = {
            url: url.toString(),
            method: 'GET',
            headers: {}
        }

        if(typeof cookies.get('token') !== "undefined") options.headers.Authorization = `Bearer ${cookies.get('token')}`;

        axios(options).then(response => {
            if(response.data) {
                let categories = {}

                response.data.forEach(category => {
                    if(category.parent === 0) {
                        categories["cat" + category.id.toString()] = {
                            id: category.id,
                            name: category.name,
                            slug: category.slug,
                            image: category.image,
                            count: category.count,
                            childs: {},
                        };
                        response.data.forEach(subcategory => {
                            if(subcategory.parent === category.id) {
                                categories["cat" + category.id.toString()].childs["cat" + subcategory.id.toString()] = {
                                    id: subcategory.id,
                                    name: subcategory.name,
                                    slug: subcategory.slug,
                                    image: subcategory.image,
                                    count: subcategory.count,
                                    childs: {},
                                };
                                response.data.forEach(grandChild => {
                                    if(grandChild.parent === subcategory.id) {
                                        categories["cat" + category.id.toString()].childs["cat" + subcategory.id.toString()].childs["cat" + grandChild.id.toString()] = {
                                            id: grandChild.id,
                                            name: grandChild.name,
                                            slug: grandChild.slug,
                                            image: grandChild.image,
                                            count: grandChild.count,
                                        };
                                    }
                                    return true;
                                });
                            }
                            return true;
                        })
                    }
                    return true;
                })
                resolve({list: response.data, tree: categories});
            } else {
                reject(new Error('Categories not found'));
            }
        }).catch(error => {
            console.log(error)
            reject(new Error('Api Error'));
        })
    })
}

export const getProducts = (params) => {
    return new Promise((resolve, reject) => {
        let url = `${domain}/wp-json/qualimenti/v1/products`
        let urlParams = new URLSearchParams();
        urlParams.append('page', params.page ? params.page : '1');
        urlParams.append('per_page', params.per_page ? params.per_page : '12');
        if(params.categories) urlParams.append('category', params.categories);
        if(params.search) urlParams.append('search', params.search);
        if(params.priceRange) {
            urlParams.append('min_price', params.priceRange.min);
            urlParams.append('max_price', params.priceRange.max);
        }

        if(params.priceOrder) {
            urlParams.append('orderby', 'price');
            urlParams.append('order', params.priceOrder);
        }

        if(params.sellers) {
            params.sellers.map(seller => {
                urlParams.append('seller[]', seller);
                return true;
            })
        }

        for(var key in params) {
            // If key begins with meta:, add it to url params
            if(key.indexOf('meta:') === 0) {
                urlParams.append(key, params[key]);
            }
        }

        let options = {
            url: `${url}?${urlParams.toString()}`,
            method: 'GET',
            headers: {}
        }

        if(typeof cookies.get('token') !== "undefined") options.headers.Authorization =`Bearer ${cookies.get('token')}`;
        axios(options).then(response => {
            if(response.data) {
                resolve(response.data);
            } else {
                reject(new Error('Products not found'));
            }
        }).catch(error => {
            console.log(error)
            reject(new Error('Api Error'));
        })
    })
}

/**
 * Get product list by ids (for favorites in homepage for example)
 * @param {*} ids 
 * @returns product list object
 */
export const getProductsByIds = (ids) => {
    return new Promise((resolve, reject) => {
        let options = {
            url: `${domain}/wp-json/qualimenti/v1/products/?include=${ids}&per_page=100`,
            method: 'GET',
            headers: {}
        }

        if(typeof cookies.get('token') !== "undefined") options.headers.Authorization = `Bearer ${cookies.get('token')}`;

        axios(options).then(response => {
            if(response.data) {
                resolve(response.data);
            } else {
                reject(new Error('Products not found'));
            }
        }).catch(error => {
            console.log(error)
            reject(new Error('Api Error'));
        })
    })
}

/**
 * Get products with highlight flag
 * @returns product list object
 */
export const getHighlightedProducts = () => {
    return new Promise((resolve, reject) => {
        let options = {
            url: `${domain}/wp-json/qualimenti/v1/products/highlight`,
            method: 'GET',
            headers: {}
        }

        if(typeof cookies.get('token') !== "undefined") options.headers.Authorization = `Bearer ${cookies.get('token')}`;

        axios(options).then(response => {
            if(response.data) {
                resolve(response.data);
            } else {
                reject(new Error('Products not found'));
            }
        }).catch(error => {
            console.log(error)
            reject(new Error('Api Error'));
        })
    })
}

export const addToCart = ( productId, quantity, nonce ) => {
    return new Promise((resolve, reject) => {
        var data = new FormData();
        data.append('action', 'qualimenti_add_to_cart');
        data.append('product_id', productId);
        data.append('quantity', quantity);
        
        let options = {
            url: `${domain}/wp-admin/admin-ajax.php`,
            method: 'POST',
            // headers: {},
            data: data
        }
        // if(typeof cookies.get('token') !== "undefined") options.headers.Authorization = `Bearer ${cookies.get('token')}`;
        axios(options).then(response => {
            if(response.data) {
                resolve(response.data);
            } else {
                reject(new Error('Cart add error'));
            }
        }).catch(error => {
            console.log(error)
            reject(new Error('Api Error'));
        })
    })
}

export const updateCartQuantity = (itemKey, quantity, nonce) => {
    return new Promise((resolve, reject) => {
        var data = new FormData();
        data.append('action', 'qualimenti_update_cart');
        data.append('key', itemKey);
        data.append('quantity', quantity);
        
        let options = {
            url: `${domain}/wp-admin/admin-ajax.php`,
            method: 'POST',
            data: data
        }

        axios(options).then(response => {
            if(response.data) {
                resolve(response.data);
            } else {
                reject(new Error('Cart update error'));
            }
        }).catch(error => {
            console.log(error)
            reject(new Error('Api Error'));
        })
    })
}

// export const updateCartQuantity = (itemKey, quantity, nonce) => {
//     return new Promise((resolve, reject) => {
//         let options = {
//             url: `${domain}/wp-json/wc/store/cart/update-item`,
//             method: 'POST',
//             headers: {
//                 'X-WC-Store-API-Nonce': nonce,
//                 // 'Nonce': nonce,
//             },
//             data: {
//                 key: itemKey,
//                 quantity: quantity
//             }
//         }
//         if(typeof cookies.get('token') !== "undefined") options.headers.Authorization = `Bearer ${cookies.get('token')}`;
//         axios(options).then(response => {
//             if(response.data) {
//                 resolve(response.data);
//             } else {
//                 reject(new Error('Cart update error'));
//             }
//         }).catch(error => {
//             console.log(error)
//             reject(new Error('Api Error'));
//         })
//     })
// }

export const removeCartItem = (itemKey, nonce) => {
    return new Promise((resolve, reject) => {
        let options = {
            url: `${domain}/wp-json/wc/store/cart/remove-item`,
            method: 'POST',
            headers: {
                'X-WC-Store-API-Nonce': nonce,
                // 'Nonce': nonce,
            },
            data: {
                key: itemKey,
            }
        }
        if(typeof cookies.get('token') !== "undefined") options.headers.Authorization = `Bearer ${cookies.get('token')}`;
        axios(options).then(response => {
            if(response.data) {
                resolve(response.data);
            } else {
                reject(new Error('Cart remove error'));
            }
        }).catch(error => {
            console.log(error)
            reject(new Error('Api Error'));
        })
    })
}

export const getOrders = () => {
    return new Promise((resolve, reject) => {
        let options = {
            url: `${domain}/wp-json/qualimenti/v1/orders`,
            method: 'GET',
            headers: {}
        }
        if(typeof cookies.get('token') !== "undefined") options.headers.Authorization = `Bearer ${cookies.get('token')}`;
        axios(options).then(response => {
            if(response.data) {
                resolve(response.data);
            } else {
                reject(new Error('Orders not found'));
            }
        }).catch(error => {
            console.log(error)
            reject(new Error('Api Error'));
        })
    })
}

export const getTopProductCategories = () => {
    return new Promise((resolve, reject) => {
        let options = {
            url: `${domain}/wp-json/qualimenti/v1/categories/top`,
            method: 'GET',
            headers: {}
        }
        if(typeof cookies.get('token') !== "undefined") options.headers.Authorization = `Bearer ${cookies.get('token')}`;
        axios(options).then(response => {
            if(response.data) {
                resolve(response.data);
            } else {
                reject(new Error('Categories not found'));
            }
        }).catch(error => {
            console.log(error)
            reject(new Error('Api Error'));
        })
    })
}

export const getOrderStatus = (status) => {
    switch(status) {
        case 'pending':
            return 'In attesa';
        case 'processing':
            return 'In lavorazione';
        case 'on-hold':
            return 'In attesa';
        case 'completed':
            return 'Completato';
        case 'cancelled':
            return 'Annullata';
        case 'partially-paid':
            return 'Parzialmente pagato';
        case 'refunded':
            return 'Rimborsata';
        case 'failed':
            return 'Fallito';
        default:
            return null;
    }
}

export const duplicateOrder = (orderId) => {
    return new Promise((resolve, reject) => {
        let options = {
            url: `${domain}/wp-json/qualimenti/v1/orders/${orderId}/duplicate`,
            method: 'GET',
            headers: {}
        }
        if(typeof cookies.get('token') !== "undefined") options.headers.Authorization = `Bearer ${cookies.get('token')}`;
        axios(options).then(response => {
            if(response.data && response.data.success) {
                resolve(response.data);
            } else {
                reject(new Error('Order not found'));
            }
        }).catch(error => {
            console.log(error)
            reject(new Error('Api Error'));
        })
    })
}

export const getCategoryBaseUrl = (type, searchTerm, categories, id, url) => {
    let urlTypePrefix = type;
    if(searchTerm && searchTerm.length >= 3) urlTypePrefix = 's';
    if(!url) url = '/products/' + urlTypePrefix;
    if(searchTerm) url = url + '/' + searchTerm;
    for(var key in categories ) {
        if(key === id) {
            url = url + '/' + categories[key].slug;
        } else {
            if(categories[key].childs && Object.keys(categories[key].childs).length > 0) {
                for(var key1 in categories[key].childs ) {
                    if(key1 === id) {
                        url = url + '/' + categories[key].slug + '/' + categories[key].childs[key1].slug;
                    } else {
                        if(categories[key].childs[key1].childs && Object.keys(categories[key].childs[key1].childs).length > 0) {
                            for(var key2 in categories[key].childs[key1].childs ) {
                                if(key2 === id) {
                                    url = url + '/' + categories[key].slug + '/' + categories[key].childs[key1].slug + '/' + categories[key].childs[key1].childs[key2].slug;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    return url;
}

export const getCategoryFilters = (categories, id) => {
    for(var key in categories ) {
        if(id === 0) {
            return categories;
        } else {
            if(key === "cat" + id) {
                if(categories[key].childs && Object.keys(categories[key].childs).length > 0) {
                    return categories[key].childs
                    // break;
                } else {
                    return categories
                    // break;
                }
            } else {
                if(categories[key].childs && Object.keys(categories[key].childs).length > 0) {
                    let search = getCategoryFilters(categories[key].childs, id);
                    if(search) return search;
                } else {
                    continue;
                }
            }
        }
    }
}