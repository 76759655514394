import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { GlobalContext } from '../Layout/Layout'
import { Card, Row, Col, Button } from 'react-bootstrap'
import { TbX } from 'react-icons/tb';
import numeral from 'numeral'

export default function MiniCart() {
    const { cart, displayMiniCart, setDisplayMiniCart, setDisplayCart } = useContext(GlobalContext)
    const navigate = useNavigate()

    const handleDisplayCart = (e) => {
        setDisplayCart(true);
        setDisplayMiniCart(false);
        navigate('/')
    }
    
    return (
        <Card className={`${window.scrollY > 100 ? "miniCart-fixed" : "miniCart"} d-none ${displayMiniCart ? "d-md-block" : "d-md-none"}`} style={{cursor: 'pointer'}}>
            <Card.Body>
                <Row>
                    <Col onClick={handleDisplayCart} xs={9}>
                        {cart && cart.count > 0 ? <p>Hai {cart ? cart.count : 0} prodotti nel carrello per un totale di:</p> : <p>Il tuo carrello è vuoto</p>}
                    </Col>
                    <Col xs={3} className="display-flex justify-content-center">
                        <Button 
                            variant="link" 
                            className="pointer rounded-circle" 
                            color="rgba(174, 51, 53, 255)" 
                            onClick={() => setDisplayMiniCart(false)}
                        >
                            <TbX color="rgba(174, 51, 53, 255)" size={30} />
                        </Button>
                    </Col>
                    <Col onClick={handleDisplayCart} xs={12}>
                        {cart && cart.count > 0 && (
                            <>
                                <h3>{numeral(parseFloat(cart.totals.total - cart.totals.total_tax)).format('0,0.00 $')}</h3>
                            </>
                        )}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}