import { useContext, useState, useEffect } from 'react';

// External librairies
import { Button, Form, Modal, Image, Card } from 'react-bootstrap';
import numeral from 'numeral';
import '../../../utils/numeral/it';

// Components & libs
import { GlobalContext } from '../../Layout/Layout';
import { editFavorite, getCart } from '../../../utils/users';
import { getSellerDetails, addToCart } from '../../../utils/products';
import ProductDetailModal from './ProductDetailModal.js';

// Assets
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { FaShoppingCart } from "react-icons/fa";
import {ReactComponent as Waiter} from '../../../assets/img/waiter-white.svg';
import {ReactComponent as WaiterRed} from '../../../assets/img/waiter-red.svg';
import { TbCheck } from "react-icons/tb";
import { BsShop } from "react-icons/bs";
import { AiOutlinePlusCircle } from "react-icons/ai";
import {ReactComponent as IconGelo } from '../../../assets/img/icons/icon-gelo.svg';

export default function HorizontalProductCard({ product }) {
    const { user, setUser, domain, setCart, setDisplayMiniCart } = useContext(GlobalContext);
    const [ isFavorite, setIsFavorite ] = useState(false);
    const [ image, setImage ] = useState(`${domain}/wp-content/plugins/qualimenti-react/assets/img/no-image-wide.png`);
    const [ quantity, setQuantity ] = useState(1);
    const [ minQuantity, setMinQuantity ] = useState(1);
    const [ quantityStep, setQuantityStep ] = useState(1);
    const [ waiting, setWaiting ] = useState(false);
    const [ loadFavorite, setLoadFavorite ] = useState(false);
    const [ done, setDone ] = useState(false);
    const [ showDescription, setShowDescription ] = useState(false);
    const [ sellerDetails, setSellerDetails ] = useState(null);
    numeral.locale('it');

    const manageFavorite = (e) => {
        setLoadFavorite(true);
        editFavorite(product.id, user.favorites && user.favorites.includes(product.id.toString()) ? "remove" : "add").then(response => {
            setLoadFavorite(false);
            if(response.favorites) {
                setUser({
                    ...user,
                    favorites: response.favorites
                })
            }
        }).catch(error => {
            setLoadFavorite(false);
            alert('A technical error occured')
        })
    }

    const handleAddToCart = (e) => {
        setWaiting(true);
        if((quantity*1000).toFixed(0)%(quantityStep*1000).toFixed(0) !== 0) {
            alert(`La quantità richiesta non è valida, deve essere un multiplo di ${quantityStep}`)
            setWaiting(false)
            return;
        }

        addToCart(product.id, quantity, user['wc-nonce']).then(response => {
            setShowDescription(false)
            getCart(user).then(cart => {
                setCart(cart)
                setWaiting(false)
                setDisplayMiniCart(true)
                setDone(true)
                setTimeout(() => {
                    setDone(false)
                }, 5000)
            }).catch(error => {
                console.log(error)
                alert('A technical error occured')
            })
        }).catch(error => {
            alert('A technical error occured')
        })
    }

    const handleDisplaySellerDetails = (id) => {
        getSellerDetails(id).then(response => {
            setSellerDetails(response)
        }).catch(error => {
            alert('A technical error occured')
        })
    }

    useEffect(() => {
        if(user.favorites && user.favorites.includes(product.id.toString())) {
            setIsFavorite(true);
        } else {
            setIsFavorite(false);
        }

        if(product.minimum_quantity && product.minimum_quantity !== "" && product.minimum_quantity > 0) {
            setMinQuantity(product.minimum_quantity);
            setQuantity(product.minimum_quantity);
        }

        if(product.quantity_step && product.quantity_step !== "" && product.quantity_step > 0) setQuantityStep(product.quantity_step);
        
        // eslint-disable-next-line
    }, [user]);

    useEffect(() => {
        if(product.image) 
            setImage(product.image);
        else {
            product.categories.sort((a, b) => b.id - a.id)
            product.categories.some(category => {
                if(category.image) {
                    setImage(category.image);
                    return true;
                } else {
                    return false;
                }
            })
        }
        // eslint-disable-next-line
    }, [product]);

    return (
        <Card className="w-100 shadow" style={{borderRadius: '27px'}}>
            <ProductDetailModal 
                product={product} 
                showDescription={showDescription} 
                setShowDescription={setShowDescription} 
                image={image} 
                quantity={quantity} 
                setQuantity={setQuantity} 
                handleAddToCart={handleAddToCart} 
                waiting={waiting} 
                done={done} 
                minQuantity={minQuantity} 
                quantityStep={quantityStep}
                loadFavorite={loadFavorite}
                isFavorite={isFavorite}
                manageFavorite={manageFavorite}
            />

            <Modal show={sellerDetails ? true : false} onHide={() => setSellerDetails(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>{sellerDetails ? sellerDetails.name : ''}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {sellerDetails && sellerDetails.infos && sellerDetails.infos.delivery && sellerDetails.infos.delivery.type ? (
                        <>
                            {sellerDetails.infos.delivery.minimum_order && sellerDetails.infos.delivery.minimum_order > 0 ? (
                                <><b>Ordine minimo</b>: {sellerDetails.infos.delivery.minimum_order} €<br /></>
                            ) : <><b>Ordine minimo</b>: senza minimo di ordine<br /></>}
                            {sellerDetails.infos.delivery.shipping_price && sellerDetails.infos.delivery.shipping_price > 0 ? (
                                <><b>Spese di spedizione</b>: {sellerDetails.infos.delivery.shipping_price} € {sellerDetails.infos.delivery.free_shipping && sellerDetails.infos.delivery.free_shipping > 0 ? (
                                    <>(gratuita da {sellerDetails.infos.delivery.free_shipping} € di ordine)</>
                                ) : null}<br /></>
                            ) : <><b>Spese di spedizione</b>: gratuita<br /></>}
                            {sellerDetails.infos.delivery.days && sellerDetails.infos.delivery.days.length > 0 && (
                                <><b>Giorni di consegna</b>: {sellerDetails.infos.delivery.days.join(', ')}<br /></>
                            )}
                            {sellerDetails.infos.delivery.delay && sellerDetails.infos.delivery.delay === "0" ? (
                                <><b>Tempo di consegna</b>: Oggi (per tutti gli ordini effettuati entro le ore {sellerDetails.infos.delivery.order_limit})<br /></>
                            ) : sellerDetails.infos.delivery.delay && sellerDetails.infos.delivery.delay === "1" ? (
                                <><b>Tempo di consegna</b>: Domani (per tutti gli ordini effettuati entro le ore {sellerDetails.infos.delivery.order_limit})<br /></>
                            ) : sellerDetails.infos.delivery.delay && sellerDetails.infos.delivery.delay === "2" ? (
                                <><b>Tempo di consegna</b>: Dopodomani<br /></>
                            ) : (
                                <><b>Tempo di consegna</b>: entro {sellerDetails.infos.delivery.delay} giorni<br /></>
                            )}
                            {sellerDetails.infos.delivery.comment && sellerDetails.infos.delivery.comment !== "" && (
                                <><b>Note</b>: <span dangerouslySetInnerHTML={{__html: sellerDetails.infos.delivery.comment.replace("\n", "<br />")}} /><br /></>
                            )}
                        </>
                    ) : (
                        <>
                            {sellerDetails && sellerDetails.infos && sellerDetails.infos.minimum_order && sellerDetails.infos.minimum_order > 0 ? (
                                <><b>Ordine minimo</b>: {sellerDetails.infos.minimum_order} €<br /></>
                            ) : <><b>Ordine minimo</b>: senza minimo di ordine<br /></>}
                            {sellerDetails && sellerDetails.infos && sellerDetails.infos.shippment_price && sellerDetails.infos.shippment_price > 0 ? (
                                <><b>Spese di spedizione</b>: {sellerDetails.infos.shippment_price} € {sellerDetails && sellerDetails.infos && sellerDetails.infos.free_shipping && sellerDetails.infos.free_shipping > 0 ? (
                                    <>(gratuita da {sellerDetails.infos.free_shipping} € di ordine)<br /></>
                                ) : null}<br /></>
                            ) : <><b>Spese di spedizione</b>: gratuita<br /></>}

                            {sellerDetails && sellerDetails.infos && sellerDetails.infos.discount_rules && sellerDetails.infos.discount_rules.length > 0 && (
                                <>
                                    <hr />
                                    <h5>Sconti fornitori</h5>
                                    {sellerDetails.infos.discount_rules.map((rule, index) => (
                                        <div key={index}>
                                            Da {numeral(rule.min).format('0,0.00 $')} a {numeral(rule.max).format('0,0.00 $')} : {numeral(rule.discount/100).format('0,0.00 %')}
                                        </div>
                                    ))}
                                </>
                            )}
                        </>
                    )}
                </Modal.Body>
            </Modal>
            
            <Card.Body className="d-flex flex-column justify-content-between">
                <div className="d-flex justify-content-between" style={{gap: 10}}>
                    <div style={{width: 120, position: 'relative'}}>
                        <Image src={image} style={{height: 120, width: 120, objectFit: 'cover', borderRadius: '15px'}} />
                        <div style={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                            width: 28,
                            minWidth: 28,
                            height: 28,
                            backgroundColor: '#fff',
                            borderRadius: '50%',
                        }} className="shadow d-flex justify-content-center align-items-center" onClick={manageFavorite}>
                            {loadFavorite ? (
                                <WaiterRed style={{width: 14, height: 14}} />
                            ) : <>
                                {isFavorite ? 
                                    <AiFillHeart className="favorite-star" style={{width: 14, height: 14, filter: 'drop-shadow(0px 0px 4px rgb(255 255 255))'}} /> : 
                                    <AiOutlineHeart className="favorite-star" style={{width: 14, height: 14, filter: 'drop-shadow(0px 0px 4px rgb(255 255 255))'}} />}
                            </>}
                        </div>
                        {product && product.metas && product.metas.condition && product.metas.condition.value.toLowerCase() === 'gelo' && <div style={{
                            position: 'absolute',
                            bottom: 10,
                            left: 10,
                        }}><IconGelo /></div>}
                    </div>
                    <div style={{width: '100%'}} className="d-flex justify-content-between flex-column">
                        <div className="d-flex justify-content-between">
                            <div style={{display: 'table', tableLayout: 'fixed', width: '100%'}} className="overflow-hidden">
                                <h5 className="d-flex align-items-center" style={{cursor: 'pointer'}} onClick={() => setShowDescription(true)}>
                                    {product.name}
                                </h5>
                                {product.description && product.description !== "" && (
                                    <div className="text-truncate" style={{textOverflow: 'test'}}>
                                        {product.description.length > 50 ? (
                                            <>
                                                {product.description.substring(0, 50)}...
                                            </>
                                        ) : <>
                                            {product.description}
                                        </>}
                                        {(product.description.length > 50) && <>
                                            <Button variant="link" style={{padding: 0, marginTop: -4, marginLeft: 5, color: '#333'}} onClick={() => setShowDescription(true)}>
                                                <AiOutlinePlusCircle />
                                            </Button>
                                        </>}
                                    </div>
                                )}
                                <div>
                                    
                                </div>
                            </div>
                            <div className="d-flex" style={{gap: 10}}>
                                <div>
                                    <Form.Control type="number" style={{width: 100}} min={minQuantity} step={quantityStep} value={quantity} onChange={e => setQuantity(e.target.value)} />
                                </div>
                                <div style={{minWidth: 140}} className="text-center text-nowrap">
                                    <b>Totale :</b> {numeral(product.price * quantity).format('0,0.00 $')}<br />
                                    <small>IVA : {numeral((product.price_tax - product.price) * quantity).format('0,0.00 $')}</small>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="w-100">
                                <a href="#!" className="text-dark text-nowrap" onClick={() => handleDisplaySellerDetails(product.seller_id)}><BsShop size={14} className="me-1" style={{marginTop: '-3px'}} /><small className="text-truncate">{product && product.seller}</small></a>
                            </div>
                            <div className="d-flex align-items-center justify-content-center flex-column w-100">
                                <div className="d-flex align-items-center">
                                    <h4 className="text-nowrap m-0" style={{fontSize: '1.6rem'}}>
                                        <b className="text-red">
                                            {numeral(product.price).format('0,0.00 $')}
                                        </b>
                                    </h4>
                                    {product.quantity_type && product.quantity_type !== "" ? <span className='ms-1 text-red'>/{product.quantity_type}</span> : product.weight && product.weight !== "" && product.weight > 0 ? <span className='ms-1 text-red'>/KG</span> : <span className='ms-1 text-red'>/PZ</span>}
                                </div>
                                {product.tax && product.tax.label && <div style={{lineHeight: '0.7em'}} className="ms-2">
                                    <small>{product.tax.label}</small>
                                </div>}
                            </div>
                            <div className="d-flex" style={{gap: 10}}>
                                {product && product.seller_available === false ? (
                                    <Button variant="secondary" className="text-nowrap" disabled>Non disponibile</Button>
                                ) : (
                                    <Button variant="qualimenti" className="text-nowrap" onClick={handleAddToCart} disabled={waiting}>
                                        {waiting ? <><Waiter style={{width: 16, height: 16, marginTop: '-3px'}} /> Aggiungi</> : done ? <><TbCheck size="16" style={{marginTop: '-3px'}} /> Aggiungi</> : <><FaShoppingCart /> Aggiungi</>}
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}