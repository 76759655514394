import { useState } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import { updateAddress } from '../../../utils/users';

export default function PostalCode() {
    const [ postalCode, setPostalCode ] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        updateAddress('shipping', {postcode: postalCode}).then(response => {
            document.location.reload();
        }).catch(error => {
            alert('Error updating address');
        })
    }

    return <div className="postcode-wrapper">
        <Card style={{height: 'fit-content', maxWidth: '90%'}}>
            <Card.Body>
                <h3 className="text-center mb-3">Dove vuoi essere consegnato?</h3>
                <p>Per poter affinare la disponibilità dei prodotti in base alla tua posizione,<br />ti preghiamo di fornirci il tuo codice postale.</p>
                <Form onSubmit={handleSubmit}>
                    <Form.Control value={postalCode} onChange={(e) => setPostalCode(e.target.value)} type="text" placeholder="Inserisci il tuo codice postale" />
                    <div className="d-grid">
                        <Button variant="qualimenti" type="submit" className="mt-3">Aggiornare</Button>
                    </div>
                </Form>
            </Card.Body>
        </Card>
    </div>;
}