import axios from 'axios'
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const domain = document.location.hostname === 'localhost' ? 'https://qualimenti.twiga.io' : `https://${document.location.hostname}`;

export const getFooterMenu = async () => {
    const response = await fetch(`${domain}/wp-json/qualimenti/v1/footer`);
    const data = await response.json();
    return data;
}

export const getStripePubKey = async () => {
    return new Promise((resolve, reject) => {
        if(typeof cookies.get('token') !== "undefined") {
            let options = {
                url: `${domain}/wp-json/qualimenti/v1/stripe/key`,
                method: 'GET',
                headers: {}
            }
            options.headers.Authorization = `Bearer ${cookies.get('token')}`;

            axios(options).then(response => {
                if(response.data.success) {
                    resolve(response.data.public_key);
                } else {
                    reject(new Error(response.data.message));
                }
            }).catch(error => {
                console.log(error)
                reject(new Error(error));
            })
        } else {
            reject(new Error('Token not found'));
        }
    })
}