import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Third party
import { Row, Col, Form, Button, InputGroup, Alert } from 'react-bootstrap';

// Functions
import { login, register, getUser, getCart } from '../../../../utils/users'
import { GlobalContext } from '../../../Layout/Layout'
import countries from './countries.json'

// Assets
import { TbShoppingCart } from 'react-icons/tb';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { ThreeDots } from 'react-loader-spinner'
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";


export default function Step1({ setStep }) {
    const navigate = useNavigate();
    countries.sort((a, b) => a.dial_code - b.dial_code)
    
    const { setUser, setCart } = useContext(GlobalContext)

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    // const [shippingCompany, setShippingCompany] = useState('');
    const [phone, setPhone] = useState('');
    const [cguChecked, setCguChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [registerError, setRegisterError] = useState('');
    const [phoneIndicator, setPhoneIndicator] = useState('+39');
    const [errors, setErrors] = useState({});
    const [ displayPassword, setDisplayPassword ] = useState(false);

    const handleRegister = (e) => {
        e.preventDefault();
        setLoading(true);
        const concatPhone = phoneIndicator + phone;
        register(email, password, concatPhone).then(registered => {
            if(registered) {
                login(email, password).then(logued => {
                    if(logued) {
                        getUser().then(userData => {
                            getCart(userData).then(cart => {
                                setCart(cart);
                                setLoading(false);
                                setUser(userData);
                                setStep(2);
                            }).catch(error => {
                                setLoading(false);
                                setRegisterError(error.message)
                            })
                        }).catch(error => {
                            setLoading(false);
                            setRegisterError(error.message)
                        })
                    } else {
                        setLoading(false);
                    }
                }).catch(error => {
                    console.log(error.message)
                    setRegisterError(error.message)
                    setLoading(false);
                })
            } else {

            }
        }).catch(error => {
            console.log(error.message)
            setRegisterError(error.message)
            setLoading(false);
        })
    }

    useEffect(() => {
        setPhone(phone.replace(/[^0-9]/g, ''));
    }, [phone]);

    const checkEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        if(re.test(email)) {
            setErrors({...errors, email: null})
            return true;
        } else {
            setErrors({...errors, email: 'Inserisci un indirizzo email valido.'})
            return false;
        }
    }

    const checkPassword = (password) => {
        if(password.length < 8) {
            setErrors({...errors, password: 'La password deve contenere almeno 8 caratteri.'})
            return false;
        } else {
            setErrors({...errors, password: null})
            return true;
        }
    }

    const checkPhone = (phone) => {
        if(phone.length < 10) {
            setErrors({...errors, phone: 'Inserisci un numero di telefono valido.'})
            return false;
        } else {
            setErrors({...errors, phone: null})
            return true;
        }
    }

    return <Row>
        <Col xs={12}>
            {/* <h3 className="text-uppercase text-center">Iscrizione</h3> */}
            <div className='text-center'><i>Entra nel mercato online dedicato al mondo Ho.Re.Ca.</i></div>
        </Col>
        <Col xs={12} style={{marginTop: '1em'}}>
            <Form onSubmit={handleRegister}>
                <Row>
                    <Col xs={12}>
                    <Form.Group>
                        <Form.Label htmlFor="email" className="form-label">E-mail *</Form.Label>
                        <Form.Control id="email" type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required onBlur={(e) => checkEmail(e.target.value)} isInvalid={errors.email} onFocus={() => setErrors({...errors, email: null})} />
                        {errors.email && <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>}
                    </Form.Group>
                    </Col>
                    <Col xs={12} className='mt-1'>
                        <Form.Group>
                            <Form.Label htmlFor="password" className="form-label">Password *</Form.Label>
                            <InputGroup>
                                <Form.Control id="password" type={displayPassword ? "text" : "password"} placeholder="Password"  value={password} onChange={(e) => setPassword(e.target.value)} required onBlur={(e) => checkPassword(e.target.value)} isInvalid={errors.password} onFocus={() => setErrors({...errors, password: null})} />
                                <Button variant="outline-secondary" id="button-addon2" onClick={() => setDisplayPassword(!displayPassword)} style={{
                                    borderTopRightRadius: 5, 
                                    borderBottomRightRadius: 5,
                                    border: '1px solid #ced4da',
                                    borderLeft: 0,
                                }}>
                                    {displayPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                                </Button>
                                {errors.password ? <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback> : <div className='d-flex align-items-center mt-1 w-100' style={{ gap:5 }}><AiOutlineInfoCircle /> <small>Deve contenere almeno 8 caratteri.</small></div>}
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col xs={12} className='mt-1'>
                        {/* <label htmlFor="phone" className="form-label">Telefono *</label> */}
                        <Form.Group>
                            <Form.Label htmlFor="password" className="form-label">Telefono *</Form.Label>
                            <InputGroup>
                                {/* Sort countries by dial_code */}
                                {/* Input group select from countries */}
                                <Form.Select id="inputGroupPrepend" className="noarrow" value={phoneIndicator} onChange={setPhoneIndicator} style={{borderTopLeftRadius: 5, borderBottomLeftRadius: 5, maxWidth: 70, paddingLeft: 10, paddingRight: 10, borderRight: 0}} isInvalid={errors.phone}>
                                    {countries.map((country, index) => (
                                        <option key={index} value={country.dial_code}>{country.dial_code}</option>
                                    ))}
                                </Form.Select>
                                <Form.Control id="phone" type="tel"  placeholder="Telefono" value={phone} onChange={(e) => setPhone(e.target.value)} required style={{ borderTopRightRadius: 5, borderBottomRightRadius: 5, borderLeft: 0}} onBlur={(e) => checkPhone(e.target.value)} isInvalid={errors.phone} onFocus={() => setErrors({...errors, phone: null})} />
                                {errors.phone && <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>}
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col xs={12} className='mt-2 d-flex justify-content-center'>
                        <div>
                            <Form.Check value={cguChecked} onChange={() => setCguChecked(!cguChecked)} type={'checkbox'} id={`cgu`} label={<small>Dichiaro di aver letto e accettato integralmente le <a href="/termini-e-condizioni/" target="_blank" style={{color: "#333"}}>Condizioni Generali di Qualimenti</a>.</small>} required />
                        </div>
                    </Col>
                    <Col xs={12} className="mt-3 d-flex justify-content-center">
                        <Button variant="qualimenti" type="submit" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50%'}}>
                            {loading ? (
                                <>
                                    <ThreeDots 
                                        width="40"
                                        height="25"
                                        color="#fff"
                                    />
                                    &nbsp;
                                </>
                            ) : (
                                <><TbShoppingCart size="1.3em" className="me-2" style={{transform: 'rotate(-15deg)'}} /> Registrati</>
                            )}
                        </Button>
                    </Col>
                    {registerError && (
                        <Col xs={12} className="mt-3">
                            <Alert variant="danger m-0"><span dangerouslySetInnerHTML={{__html: registerError}} /></Alert>
                        </Col>
                    )}
                    <Col xs={12} className="text-center mt-2">
                        <Button variant="link" className="qualimenti-link" onClick={(e) => navigate('/login')} style={{margin: 0, padding: 0}}>
                            <small>Ho già un account</small>
                        </Button> 
                    </Col>
                </Row>
            </Form>
        </Col>
    </Row>
}