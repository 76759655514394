import Header from './Header'
import Footer from './Footer'
import { Container, Row, Col, Image } from 'react-bootstrap'
import { createContext, useState, useEffect } from 'react';
import { validateToken, getUser, getCart, checkUserShipping } from '../../utils/users'
import { getFooterMenu } from '../../utils/common'
import { getProductCategories } from '../../utils/products'
import Glyphe from '../../assets/img/glyphe-red.png';
import Cookies from 'universal-cookie';

export const GlobalContext = createContext();

export default function Layout(props) {
    const [ user, setUser ] = useState(null);
    const [ cart, setCart ] = useState(null);
    const [ domain, setDomain ] = useState(document.location.hostname === 'localhost' ? 'https://qualimenti.twiga.io' : `https://${document.location.hostname}`);
    const [ searchTerm, setSearchTerm ] = useState('');
    const [ categoryImages, setCategoryImages ] = useState({});
    const [ sessionShipping, setSessionShipping ] = useState(false);
    const [ categories, setCategories ] = useState([]);
    const [ displayCart, setDisplayCart ] = useState(false);
    const [ loading, setLoading ] = useState(true);
    const [ footerMenu, setFooterMenu ] = useState(null);
    const [ displayMiniCart, setDisplayMiniCart ] = useState(false);
    const cookies = new Cookies();

    const refreshUser = () => {
        validateToken().then(isValid => {
            if(isValid) {
                getUser().then(user => {
                    setUser(user);
                    getCart(user).then(cart => {
                        setCart(cart);
                        setLoading(false);
                    }).catch(error => {
                        setLoading(false);
                    })
                    checkUserShipping().then(shipping => {
                        setSessionShipping(shipping);
                    }).catch(error => {
                        console.log(error)
                        setSessionShipping(false);
                    })
                }).catch(error => {
                    console.log(error)
                    setLoading(false);
                })
            } else {
                setLoading(false);
            }
        }).catch(error => {
            console.log(error)
            setLoading(false);
        });
    }

    const refreshCategories = () => {
        return new Promise((resolve, reject) => {
            getProductCategories().then(response => {
                let categoryImages = {};
                response.list.map(category => {
                    if(category.categorimage) categoryImages[category.id] = category.categorimage;
                    return true;
                })
                setCategoryImages(categoryImages);
                setCategories(response.tree)
                return resolve(true)
            }).catch(error => {
                console.log(error)
                alert('Unable to fetch categories')
                return reject(error)
            })
        });
    }

    useEffect(() => {
        refreshUser();

        getFooterMenu().then(menu => {
            setFooterMenu(menu)
        }).catch(error => {
            console.log(error)
        })

        // Partner management
        const urlParams = new URLSearchParams(window.location.search);
        const ptr = urlParams.get('ptr');
        if(ptr) {
            cookies.set('ptr', ptr, { path: '/', expires: new Date(Date.now() + (30*24*60*60*1000))});
        }
        // eslint-disable-next-line
    }, []);
    

    return <>{loading ? (
            <Container fluid>
                <Row>
                    <Col xs={12} className="text-center pt-5 d-flex flex-column align-items-center justify-content-center">
                        <Image src={Glyphe} style={{ maxWidth: '100%', width: '300px' }} alt="Glyphe" className="glyphe" />
                        Loading...
                    </Col>
                </Row>
            </Container>
        ) : (
            <GlobalContext.Provider value={{ 
                user, 
                setUser, 
                domain, 
                setDomain, 
                searchTerm, 
                setSearchTerm, 
                categoryImages, 
                setCategoryImages, 
                cart, 
                setCart, 
                displayCart, 
                setDisplayCart,
                displayMiniCart, 
                setDisplayMiniCart,
                categories, 
                setCategories, 
                footerMenu,
                sessionShipping,

                refreshUser,
                refreshCategories
            }}>
                <Container fluid>
                    <Row>
                        {props.nolayout !== true && <Col xs={12}>
                            <Header 
                                // Pass props to Header
                                props={props}
                            />
                        </Col>}
                        <Col xs={12} style={{minHeight: '300px'}}>
                            {props.children}
                        </Col>
                        {props.nolayout !== true && <Col xs={12} className="mt-5">
                            <Footer />
                        </Col>}
                    </Row>
                </Container>
            </GlobalContext.Provider>  
        )}</>
}