import axios from 'axios'
import Cookies from 'universal-cookie';
import qs from 'qs';
const cookies = new Cookies();

const domain = document.location.hostname === 'localhost' ? 'https://qualimenti.twiga.io' : `https://${document.location.hostname}`;

export const validateToken = () => {
    return new Promise((resolve, reject) => {
        if(typeof cookies.get('token') !== "undefined") {
            axios({
                url: `${domain}/wp-json/jwt-auth/v1/token/validate`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${cookies.get('token')}`
                }
            }).then(response => {
                if(response.data.code && response.data.code === 'jwt_auth_valid_token') {
                    // Token is valid expand it
                    cookies.set('token', cookies.get('token'), {
                        expires: new Date(new Date().getTime() + (30*24*60*60*1000)),
                        path: '/'
                    })
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch(error => {
                console.log(error)
                resolve(false);
            })
        } else {
            resolve(false);
        }
    })
}

export const createSession = () => {
    return new Promise((resolve, reject) => {
        axios({
            url: `${domain}/wp-json/qualimenti/v1/session/create`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${cookies.get('token')}`
            }
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            console.log(error)
            resolve(false);
        })
    })
}

export const checkIva = (iva) => {
    return new Promise((resolve, reject) => {
        let options = {
            url: `${domain}/wp-json/qualimenti/v1/register/iva/${iva}`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${cookies.get('token')}`
            }
        }

        axios(options).then(response => {
            resolve(response.data);
        }).catch(error => {
            console.log(error)
            resolve(false);
        })
    })
}


export const getUser = () => {
    return new Promise((resolve, reject) => {
        createSession().then(session => {
            let options = {
                url: `${domain}/wp-json/wp/v2/users/me`,
                method: 'GET',
                headers: {}
            }
            if(typeof cookies.get('token') !== "undefined") options.headers.Authorization = `Bearer ${cookies.get('token')}`;
            axios(options).then(response => {
                if(response.data.id && response.data.id !== 0) {
                    resolve(response.data);
                } else {
                    reject(new Error('User not found'));
                }
            }).catch(error => {
                console.log(error)
                reject(new Error('Api Error'));
            })
        }).catch(error => {
            console.log(error)
        })
    })
}

/**
 * Get partner data for dashboarding
 * @returns partner data
 */
export const getPartnerData = () => {
    return new Promise((resolve, reject) => {
        createSession().then(session => {
            let options = {
                url: `${domain}/wp-json/qualimenti/v1/partner`,
                method: 'GET',
                headers: {}
            }
            if(typeof cookies.get('token') !== "undefined") options.headers.Authorization = `Bearer ${cookies.get('token')}`;
            axios(options).then(response => {
                if(response.data && response.data.success) {
                    resolve(response.data);
                } else {
                    reject(new Error('User not found'));
                }
            }).catch(error => {
                console.log(error)
                reject(new Error('Api Error'));
            })
        }).catch(error => {
            console.log(error)
            reject(new Error('Session Error'));
        })
    })
}

/**
 * Get partner commissions list
 * @returns commissions
 */
export const getPartnerCommissions = (month, year) => {
    return new Promise((resolve, reject) => {
        createSession().then(session => {
            if(typeof month === "undefined") month = new Date().getMonth() + 1;
            if(typeof year === "undefined") year = new Date().getFullYear();
            let options = {
                url: `${domain}/wp-json/qualimenti/v1/partner/list?month=${month}&year=${year}`,
                method: 'GET',
                headers: {}
            }
            if(typeof cookies.get('token') !== "undefined") options.headers.Authorization = `Bearer ${cookies.get('token')}`;
            axios(options).then(response => {
                if(response.data && response.data.success) {
                    resolve(response.data.commissions);
                } else {
                    reject(new Error('User not found'));
                }
            }).catch(error => {
                console.log(error)
                reject(new Error('Api Error'));
            })
        }).catch(error => {
            console.log(error)
            reject(new Error('Session Error'));
        })
    })
}

export const checkUserShipping = () => {
    return new Promise((resolve, reject) => {
        let options = {
            url: `${domain}/wp-json/qualimenti/v1/user/check_shipping`,
            method: 'GET',
            headers: {}
        }
        if(typeof cookies.get('token') !== "undefined") options.headers.Authorization = `Bearer ${cookies.get('token')}`;
        axios(options).then(response => {
            if(typeof response.data.shipping_session !== "undefined") {
                resolve(response.data.shipping_session);
            } else {
                reject(new Error('Response error'));
            }
        }).catch(error => {
            console.log(error)
            reject(new Error('Api Error'));
        })
    })
}

export const clearUserShippingSession = () => {
    return new Promise((resolve, reject) => {
        let options = {
            url: `${domain}/wp-json/qualimenti/v1/user/clear_shipping`,
            method: 'GET',
            headers: {}
        }
        if(typeof cookies.get('token') !== "undefined") options.headers.Authorization = `Bearer ${cookies.get('token')}`;
        axios(options).then(response => {
            if(response.data.success) {
                resolve();
            } else {
                reject(new Error('Response error'));
            }
        }).catch(error => {
            console.log(error)
            reject(new Error('Api Error'));
        })
    })
}

export const login = (username, password) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `${domain}/wp-json/jwt-auth/v1/token`,
            method: 'POST',
            data: {
                username,
                password
            }
        }).then(response => {
            if(response.data.token) {
                cookies.set('token', response.data.token, {
                    expires: new Date(new Date().getTime() + (30*24*60*60*1000)),
                    path: '/'
                })
                resolve(true);
            } else {
                reject(new Error('login error'));
            }
        }).catch(error => {
            console.log(error.response.data.message)
            reject(new Error(error.response.data.message ? error.response.data.message : 'Api Error'));
        })
    })
}

export const register = (email, password, phone) => {
    return new Promise((resolve, reject) => {
        let data = {
            email,
            password,
            phone
        }
        if(cookies.get('ptr') && cookies.get('ptr') !== "") data.ptr = cookies.get('ptr');
        // if(codice_agent && codice_agent !== "") data.ptr = codice_agent;
        
        axios({
            url: `${domain}/wp-json/qualimenti/v1/register`,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data)
        }).then(response => {
            if(response.data.success) {
                resolve(true);
            } else {
                reject(new Error(response.data.error));
            }
        }).catch(error => {
            console.log(error.response.data.message)
            reject(new Error(error.response.data.message ? error.response.data.message : 'Api Error'));
        })
    })
}

export const registerStep2 = (addressObject, activityType, codice_agent) => {
    return new Promise((resolve, reject) => {
        let data = {
            ...addressObject,
            activityType,
            codice_agent
        }
        axios({
            url: `${domain}/wp-json/qualimenti/v1/register/step2`,
            method: 'POST',
            headers: { 
                Authorization: `Bearer ${cookies.get('token')}`,
                'content-type': 'application/x-www-form-urlencoded' 
            },
            data: qs.stringify(data)
        }).then(response => {
            if(response.data.success) {
                resolve(true);
            } else {
                reject(new Error(response.data.error));
            }
        }).catch(error => {
            console.log(error.response.data.message)
            reject(new Error(error.response.data.message ? error.response.data.message : 'Api Error'));
        })
    })

}

export const changePassword = (oldPassword, newPassword, confirmPassword) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `${domain}/wp-json/qualimenti/v1/password/change`,
            method: 'POST',
            headers: {
                Authorization: `Bearer ${cookies.get('token')}`,
                'content-type': 'application/x-www-form-urlencoded'
            },
            data: qs.stringify({
                old_password: oldPassword,
                new_password: newPassword,
                confirm_password: confirmPassword
            })
        }).then(response => {
            if(response.data.success) {
                resolve(true);
            } else {
                reject(new Error(response.data.message));
            }
        }).catch(error => {
            console.log(error.response.data.message)
            reject(new Error(error.response.data.message ? error.response.data.message : 'Api Error'));
        })
    })
}

export const editFavorite = (id, mode) => {
    return new Promise((resolve, reject) => {
        if(typeof cookies.get('token') !== "undefined") {
            let options = {
                url: `${domain}/wp-json/qualimenti/v1/favorite/${mode}/${id}`,
                method: 'GET',
                headers: {}
            }
            options.headers.Authorization = `Bearer ${cookies.get('token')}`;
            axios(options).then(response => {
                resolve(response.data)
            }).catch(error => {
                console.log(error)
                reject(new Error('login technical error'));
            })
        } else {
            reject(new Error('Token not found'));
        }
    })
}

export const getCart = (user) => {
    return new Promise((resolve, reject) => {
        if(typeof cookies.get('token') !== "undefined") {
            let options = {
                url: `${domain}/wp-json/qualimenti/v1/cart`,
                method: 'GET',
                headers: {
                    'X-WC-Store-API-Nonce': user.nonce,
                    // 'Nonce': user.nonce,
                }
            }
            options.headers.Authorization = `Bearer ${cookies.get('token')}`;
            axios(options).then(response => {
                resolve(response.data)
            }).catch(error => {
                console.log(error)
                reject(new Error('Cart error'));
            })
        } else {
            reject(new Error('Token not found'));
        }
    })
}

export const getCustomerAdresses = () => {
    return new Promise((resolve, reject) => {
        if(typeof cookies.get('token') !== "undefined") {
            let options = {
                url: `${domain}/wp-json/qualimenti/v1/addresses`,
                method: 'GET',
                headers: {}
            }
            options.headers.Authorization = `Bearer ${cookies.get('token')}`;
            axios(options).then(response => {
                resolve(response.data)
            }).catch(error => {
                console.log(error)
                reject(new Error('Addresses error'));
            })
        } else {
            reject(new Error('Token not found'));
        }
    })
}

export const updateAddress = (type, data) => {
    return new Promise((resolve, reject) => {
        if(typeof cookies.get('token') !== "undefined") {
            let options = {
                url: `${domain}/wp-json/qualimenti/v1/address/${type}`,
                method: 'POST',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded'
                },
                data: qs.stringify(data)
            }
            options.headers.Authorization = `Bearer ${cookies.get('token')}`;
            axios(options).then(response => {
                resolve(response.data)
            }).catch(error => {
                console.log(error)
                reject(new Error('Addresses error'));
            })
        } else {
            reject(new Error('Token not found'));
        }
    })
}

export const updateProfile = (data) => {
    return new Promise((resolve, reject) => {
        if(typeof cookies.get('token') !== "undefined") {
            let options = {
                url: `${domain}/wp-json/qualimenti/v1/profile/update`,
                method: 'POST',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded'
                },
                data: qs.stringify(data)
            }
            options.headers.Authorization = `Bearer ${cookies.get('token')}`;
            axios(options).then(response => {
                resolve(response.data)
            }).catch(error => {
                console.log(error)
                reject(new Error('Addresses error'));
            })
        } else {
            reject(new Error('Token not found'));
        }
    })
}

export const getStates = (country) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `${domain}/wp-json/qualimenti/v1/addresses/states`,
            method: 'POST',
            data: qs.stringify({country: country ? country : 'IT'})
        }).then(response => {
            resolve(response.data)
        }).catch(error => {
            console.log(error)
            reject(new Error('States error'));
        })
    })
}

export const updateUserInfos = (user) => {
    return new Promise((resolve, reject) => {
        if(typeof cookies.get('token') !== "undefined") {
            let options = {
                url: `${domain}/wp-json/qualimenti/v1/user`,
                method: 'POST',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded'
                },
                data: qs.stringify({
                    name: user.name,
                    sdi: user.sdi,
                    iva: user.iva
                })
            }
            options.headers.Authorization = `Bearer ${cookies.get('token')}`;
            axios(options).then(response => {
                resolve(response.data)
            }).catch(error => {
                console.log(error)
                reject(new Error('User error'));
            })
        } else {
            reject(new Error('Token not found'));
        }
    })
}

export const passwordLost = (email) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `${domain}/wp-json/qualimenti/v1/password/lost`,
            method: 'POST',
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            data: qs.stringify({
                email: email
            })
        }).then(response => {
            if(response.data.success) {
                resolve(true);
            } else {
                reject(new Error(response.data.message));
            }
        }).catch(error => {
            console.log(error.response.data.message)
            reject(new Error(error.response.data.message ? error.response.data.message : 'Api Error'));
        })
    })
}

export const passwordReset = (login, key, password) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `${domain}/wp-json/qualimenti/v1/password/reset`,
            method: 'POST',
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            data: qs.stringify({
                password: password,
                key: key,
                login: login
            })
        }).then(response => {
            if(response.data.success) {
                resolve(true);
            } else {
                reject(new Error(response.data.message));
            }
        }).catch(error => {
            console.log(error.response.data.message)
            reject(new Error(error.response.data.message ? error.response.data.message : 'Api Error'));
        })
    })
}

export const getB2BPaymentMethod = () => {
    return new Promise((resolve, reject) => {
        if(typeof cookies.get('token') !== "undefined") {
            let options = {
                url: `${domain}/wp-json/qualimenti/v1/user/payment/method`,
                method: 'GET',
                headers: {}
            }
            options.headers.Authorization = `Bearer ${cookies.get('token')}`;
            axios(options).then(response => {
                if(response.data.success) {
                    resolve(response.data);
                } else {
                    reject(new Error(response.data.message));
                }
            }).catch(error => {
                console.log(error)
                reject(new Error(error));
            })
        } else {
            reject(new Error('Token not found'));
        }
    })
}

export const removeB2BPaymentMethod = () => {
    return new Promise((resolve, reject) => {
        if(typeof cookies.get('token') !== "undefined") {
            let options = {
                url: `${domain}/wp-json/qualimenti/v1/user/payment/method`,
                method: 'DELETE',
                headers: {}
            }
            options.headers.Authorization = `Bearer ${cookies.get('token')}`;
            axios(options).then(response => {
                if(response.data.success) {
                    resolve(response.data);
                } else {
                    reject(new Error(response.data.message));
                }
            }).catch(error => {
                console.log(error)
                reject(new Error(error));
            })
        } else {
            reject(new Error('Token not found'));
        }
    })
}

export const attachPaymentMethod = (paymentMethodId) => {
    return new Promise((resolve, reject) => {
        if(typeof cookies.get('token') !== "undefined") {
            let options = {
                url: `${domain}/wp-json/qualimenti/v1/user/payment/method`,
                method: 'POST',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded'
                },
                data: qs.stringify({
                    payment_method_id: paymentMethodId
                })
            }
            options.headers.Authorization = `Bearer ${cookies.get('token')}`;

            axios(options).then(response => {
                if(response.data.success) {
                    resolve(response.data.payment_method);
                } else {
                    reject(new Error(response.data.message));
                }
            }).catch(error => {
                console.log(error)
                reject(new Error(error));
            })
        } else {
            reject(new Error('Token not found'));
        }
    })
}