import { Row, Col, Image } from 'react-bootstrap';
import { useContext, useEffect } from 'react';
import { GlobalContext } from './Layout';
// eslint-disable-next-line
import { TbBrandFacebook, TbBrandInstagram, TbBrandTwitter, TbBrandYoutube, TbBrandPinterest } from "react-icons/tb";
import * as FaIcons from "react-icons/fa";
import logo from '../../assets/img/qualimenti_logo.png'

export default function Footer() {
    const { footerMenu } = useContext(GlobalContext);

    useEffect(() => {
        // console.log(footerMenu['footer-social'])
    }, [footerMenu]);

    const GetSocialIcon = ({slug, size}) => {
        let icon = <>{slug}</>;
        switch (slug) {
            case 'linkedin':
                icon = <FaIcons.FaLinkedinIn size={size ? size : 15} />;
                break;
            case 'instagram':
                icon = <FaIcons.FaInstagramSquare size={size ? size : 15} />;
                break;
            case 'facebook':
                icon = <FaIcons.FaFacebookF size={size ? size : 15} />;
                break;
            default:
                break;
        }
        return icon;
    }

    const GetContactIcon = ({slug, size}) => {
        let icon = <>{slug}</>;
        switch (slug) {
            case 'whatsapp':
                icon = <FaIcons.FaWhatsapp size={size ? size : 15} />;
                break;
            case 'phone':
                icon = <FaIcons.FaPhone size={size ? size : 15} />;
                break;
            case 'email':
                icon = <FaIcons.FaRegEnvelopeOpen size={size ? size : 15} />;
                break;
            default:
                break;
        }
        return icon;
    }

    return <Row>
        <Col xs={12} className="pt-3 pb-3 bg-white">
            <div className="wrapper">
                <Row className="align-items-center">
                    <Col xs={6}>
                        <Image src={logo} style={{ maxHeight: window.innerWidth < 768 ? 35 : 80}} />
                    </Col>
                    <Col xs={6} className="text-right">
                        {footerMenu && footerMenu['footer-social'] && footerMenu['footer-social'].items.length > 0 && footerMenu['footer-social'].items.map((item, index) => {
                            return <a key={item.id} href={item.url} target="_blank" rel="noreferrer" style={{color: '#444444', paddingLeft: 10}} className="text-decoration-none"><GetSocialIcon slug={item.title} size={25} /></a>
                        })}
                    </Col>
                </Row>
                {footerMenu && footerMenu[`footer-text`] && footerMenu[`footer-text`].items.length > 0 && <Row className="mt-3">
                    <Col xs={12}>
                        <p style={{fontSize: window.innerWidth < 768 ? 12 : 16}}>{footerMenu[`footer-text`].items[0].title}</p>
                    </Col>
                </Row>}
                <Row>
                    <Col xs={6}>
                        {footerMenu && footerMenu[`footer-menu`] && footerMenu[`footer-menu`].items.length > 0 && (
                            <>
                                <h5 style={{fontSize: window.innerWidth < 768 ? 16 : 20}}>Link Utili</h5>
                                <ul className="footer-social">
                                    {footerMenu[`footer-menu`].items.map(item => {
                                        return <li key={item.id} className="footer-social-item" style={{fontSize: window.innerWidth < 768 ? 12 : 16}}>
                                            <a href={item.url} style={{ textDecoration: 'none' }}>{item.title}</a>
                                        </li>
                                    })}
                                </ul>
                            </>
                        )}
                    </Col>
                    <Col xs={6}>
                        {footerMenu && footerMenu[`footer-contact`] && footerMenu[`footer-contact`].items.length > 0 && (
                            <>
                                <h5 style={{fontSize: window.innerWidth < 768 ? 16 : 20}}>Contatti</h5>
                                <ul className="footer-social">
                                    {footerMenu[`footer-contact`].items.map(item => {
                                        return <li key={item.id} className="footer-social-item" style={{fontSize: window.innerWidth < 768 ? 12 : 16}}>
                                            <a href={item.url} style={{ textDecoration: 'none' }}>
                                                <GetContactIcon slug={item.title} /> <span style={{marginLeft: 5}}>{item.title === 'whatsapp' ? 'Whatsapp business' : item.url.split(':')[1]}</span>
                                            </a>
                                        </li>
                                    })}
                                </ul>
                            </>
                        )}
                    </Col>
                </Row>
                {footerMenu && footerMenu[`footer-text`] && footerMenu[`footer-text`].items.length > 0 && footerMenu[`footer-text`].items[1] && <Row className="mt-3">
                    <Col xs={12}>
                        <p style={{fontSize: window.innerWidth < 768 ? 12 : 16}}>{footerMenu[`footer-text`].items[1].title}</p>
                    </Col>
                </Row>}
            </div>
        </Col>
    </Row>
}