import { Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { useState } from 'react';
import { changePassword } from '../../../utils/users';


export default function Security() {
    const [ oldPassword, setOldPassword ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ confirmPassword, setConfirmPassword ] = useState('');
    const [ error, setError ] = useState('');
    const [ success, setSuccess ] = useState('');

    const handlePasswordChange = (e) => {
        e.preventDefault();
        setSuccess('')
        setError('')
        if(oldPassword ==='' || password === '' || confirmPassword === '') {
            setError('Devi compilare tutti i campi');
            return;
        } else if(password !== confirmPassword) {
            setError('Le tue nuove password non corrispondono');
            return;
        } else {
            changePassword(oldPassword, password, confirmPassword).then(response => {
                setSuccess('Password cambiata con successo');
                setOldPassword('');
                setPassword('');
                setConfirmPassword('');
            }).catch(error => {
                console.log(error)
                setError(error.message);
            })
        }
    }

    return <Row>
        <Col xs={12}>
            <h3>Sicurezza</h3>
            <p>Puoi modificare la tua password compilando il modulo sottostante</p>
            <Row className="justify-content-center">
                <Col xs={12} md={6}>
                    <form onSubmit={handlePasswordChange}>
                        <Form.Group className="mt-3">
                            <Form.Label>Vecchia password</Form.Label>
                            <Form.Control type="password" placeholder={'********'} value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mt-3">
                            <Form.Label>Nuova password</Form.Label>
                            <Form.Control type="password" placeholder={'********'} value={password} onChange={(e) => setPassword(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mt-3">
                            <Form.Label>Conferma la tua nuova password</Form.Label>
                            <Form.Control type="password" placeholder={'********'} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                        </Form.Group>
                        {success && <Alert className="mt-3" variant="success">{success}</Alert>}
                        {error && <Alert className="mt-3" variant="danger">{error}</Alert>}
                        <div className="d-grid mt-3">
                            <Button variant="qualimenti" type="submit">Conferma</Button>
                        </div>
                    </form>
                </Col>
            </Row>
        </Col>
    </Row>
}