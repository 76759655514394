import { Button, Form, Modal, Image, Row, Col } from 'react-bootstrap';
import numeral from 'numeral';

// Assets
import { TbCheck } from "react-icons/tb";
import {ReactComponent as IconGelo } from '../../../assets/img/icons/icon-gelo.svg';
import {ReactComponent as Waiter} from '../../../assets/img/waiter-white.svg';
import {ReactComponent as WaiterRed} from '../../../assets/img/waiter-red.svg';
import { FaShoppingCart } from "react-icons/fa";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";

export default function ProductDetailModal({ product, showDescription, setShowDescription, image, quantity, setQuantity, handleAddToCart, waiting, done, minQuantity, quantityStep, loadFavorite, isFavorite, manageFavorite }) {
    return <Modal size="md" show={showDescription} onHide={() => setShowDescription(false)} className="QualimentiModal">
        <Modal.Header closeButton>
            <Row>
                <Col xs={4} md={3} className='mb-1'>
                    <div className='w-100 position-relative'>
                        <Image src={image} style={window.width > 768 ? {
                            height: 120, 
                            width: 120, 
                            objectFit: 'cover', 
                            borderRadius: '15px'
                        } : {
                            width: '100%',
                            aspectRatio: '1/1',
                            objectFit: 'cover', 
                            borderRadius: '15px'
                        }} />
                        <div style={{
                            position: 'absolute',
                            // top: window.innerWidth < 768 ? 45 : 10,
                            top: 10,
                            right: 10,
                            width: 28,
                            minWidth: 28,
                            height: 28,
                            backgroundColor: '#fff',
                            borderRadius: '50%',
                        }} className="shadow d-flex justify-content-center align-items-center popup-favorite" onClick={manageFavorite}>
                            {loadFavorite ? (
                                <WaiterRed style={{width: 14, height: 14}} />
                            ) : <>
                                {isFavorite ? 
                                    <AiFillHeart className="favorite-star" style={{width: 14, height: 14, filter: 'drop-shadow(0px 0px 4px rgb(255 255 255))'}} /> : 
                                    <AiOutlineHeart className="favorite-star" style={{width: 14, height: 14, filter: 'drop-shadow(0px 0px 4px rgb(255 255 255))'}} />}
                            </>}
                        </div>
                        {product && product.metas && product.metas.condition && product.metas.condition.value.toLowerCase() === 'gelo' && <div style={{
                            position: 'absolute',
                            bottom: 10,
                            left: 10,
                        }}><IconGelo /></div>}
                    </div>
                </Col>
                <Col xs={8} md={9}>
                    {window.innerWidth > 768 && <h3 className='mt-md-0 pe-5 pe-md-4'>{product.name}</h3>}
                    {window.innerWidth <= 768 && <h5 className='mt-md-0 pe-5 pe-md-4'>{product.name}</h5>}
                    {/* <h3 className='mt-md-0 pe-5 pe-md-4'>{product.name}</h3> */}
                    <div class="d-flex flex-column">
                        <div className="d-flex align-items-center">
                            <h4 className="m-0" style={{fontSize: '1.6rem'}}>
                                <b className="text-red">
                                    {numeral(product.price).format('0,0.00 $')}
                                </b>
                            </h4>
                            {product.quantity_type && product.quantity_type !== "" ? <span className='ms-1 text-red'>/{product.quantity_type}</span> : product.weight && product.weight !== "" && product.weight > 0 ? <span className='ms-1 text-red'>/KG</span> : <span className='ms-1 text-red'>/PZ</span>}
                        </div>
                        {product.tax && product.tax.label && <div style={{lineHeight: '0.7em'}}>
                            <small>{product.tax.label}</small>
                        </div>}
                    </div>
                </Col>
            </Row>
        </Modal.Header>
        <Modal.Body>
            {product.description && product.description !== "" && <Row className='mb-3 mb-3'>
                <Col xs={5} md={4} className='text-secondary'>Descrizione:</Col>
                <Col xs={7} md={8}>{product.description}</Col>
            </Row>}
            {product.seller && product.seller !== "" && <Row className='mt-b mb-3'>
                <Col xs={5} md={4} className='text-secondary'>Fornitore:</Col>
                <Col xs={7} md={8}>{product.seller}</Col>
            </Row>}
            {product && product.metasDisplay && Object.keys(product.metasDisplay).length > 0 && <div>
                {Object.keys(product.metasDisplay).map((meta, index) => (
                    <Row key={index} className='mt-3 mb-3'>
                        <Col xs={5} md={4} className='text-secondary'>{product.metasDisplay[meta].title}:</Col>
                        <Col xs={7} md={8}>{product.metasDisplay[meta].value}</Col>
                    </Row>
                ))}
            </div>}
            <Row className='mt-3'>
                <Col xs={12} className='text-right'>
                    <div className='d-flex justify-content-between justify-content-md-end align-items-center' style={{gap: 20}}>
                        {/* <Button variant="secondary" onClick={() => setShowDescription(false)}>Chiudi</Button> */}
                        {/* <div className="d-flex" style={{gap: 20}}> */}
                            {/* <div>
                                <Form.Control type="number" style={{width: 100}} min={minQuantity} step={quantityStep} value={quantity} onChange={e => setQuantity(e.target.value)} />
                            </div> */}
                            <div className="d-flex justify-content-between align-items-center" style={{columnGap: 10}}>
                                <div style={{ minWidth: '28px', width: '28px', height: '28px', backgroundColor: '#fff', border: '1px solid #AE3335', color: '#AE3335', fontSize: 18, fontWeight: 500 }} className="rounded-circle d-flex justify-content-center align-items-center d-md-none" onClick={() => {
                                    if(quantity - quantityStep >= minQuantity) {
                                        setQuantity(Math.floor(((quantity - quantityStep)*1000))/1000)
                                    }
                                }}>-</div>
                                <Form.Control className='d-none d-md-block' type="number" style={{ width: 100 }} min={minQuantity} step={quantityStep} value={quantity} onChange={e => setQuantity(e.target.value)} />
                                <Form.Control className='d-block d-md-none' type="number" style={{ width: 62, borderRadius: '8px', fontSize: 12}} min={minQuantity} step={quantityStep} value={quantity} onChange={e => setQuantity(e.target.value)} />
                                <div style={{ minWidth: '28px', width: '28px', height: '28px', backgroundColor: '#fff', border: '1px solid #AE3335', color: '#AE3335', fontSize: 18, fontWeight: 500 }} className="rounded-circle d-flex justify-content-center align-items-center d-md-none" onClick={() => {
                                    setQuantity(Math.floor(((quantity + quantityStep)*1000))/1000)
                                }}>+</div>
                            </div>
                            <Button variant="qualimenti" className="text-nowrap" onClick={handleAddToCart} disabled={waiting}>
                                {waiting ? <><Waiter style={{width: 16, height: 16, marginTop: '-3px'}} /> Aggiungi</> : done ? <><TbCheck size="16" style={{marginTop: '-3px'}} /> Aggiungi</> : <><FaShoppingCart /> Aggiungi</>}
                            </Button>
                        {/* </div> */}
                    </div>
                </Col>
            </Row>
        </Modal.Body>
    </Modal>
}