import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Form, Button, Card } from 'react-bootstrap';

import { checkIva, getStates, getUser, getCustomerAdresses, updateAddress } from '../../../../utils/users';

import { GlobalContext } from '../../../Layout/Layout'

// Assets
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { ThreeDots } from 'react-loader-spinner'

export default function Step3({ setStep }) {
    const { setUser } = useContext(GlobalContext)
    const [firstname, setFirsname] = useState('');
    const [lastname, setLastname] = useState('');
    const [ iva, setIva ] = useState('');
    const [loading, setLoading] = useState(false);
    // const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');
    const [addresses, setAddresses] = useState({});
    const [states, setStates] = useState([]);
    const [status, setStatus] = useState('checkiva');
    const [errors, setErrors] = useState({});


    const handleStep3 = async (e) => {
        e.preventDefault();
        setLoading(true)

        try {
            addresses.billing.iva = iva;
            addresses.billing.first_name = firstname;
            addresses.billing.last_name = lastname;
            await updateAddress("billing", addresses.billing);
            await updateAddress("shipping", {
                first_name: firstname,
                last_name: lastname
            });
        } catch(error) {
            console.log(error.message)
        }

        getUser().then(user => {
            console.log(user)
            setUser(user);
            setLoading(false)
            setError('')
            document.location.href = '/';
        }).catch(error => {
            setLoading(false)
            console.log(error.message)
            setError(error.message)
        })
    }

    const updateStates = (state = 'IT') => {
        getStates(state).then(response => {
            setStates(response);
        }).catch(error => {
            console.log(error)
        })
    }

    const checkIvaFormat = (iva) => {
        if(iva.length !== 11) {
            setErrors({ ...errors, iva: 'La partita IVA deve contenere 11 caratteri.' })
        } else {
            setErrors({ ...errors, iva: null })
        }
    }

    const checkIvaNumber = (e) => {
        e.preventDefault();
        if(iva.length !== 11) {
            setError('La partita IVA deve contenere 11 caratteri.')
        } else {
            setLoading(true)
            checkIva(iva).then(registered => {
                if(registered && registered.success) {
                    getUser().then(user => {
                        getCustomerAdresses().then(addresses => {
                            setLoading(false)
                            setAddresses(addresses)
                            setStatus('ivachecked')
                            updateStates(addresses.billing.country);
                        })
                    }).catch(error => {
                        setLoading(false)
                        console.log(error.message)
                        setError("Partita IVA non riconosciuta. Se ritieni che si tratti di un errore, contattaci all'indirizzo info@qualimenti.com")
                    })
                } else {
                    setLoading(false)
                    setStatus('modify')
                    setError("Partita IVA non riconosciuta. Se ritieni che si tratti di un errore, contattaci all'indirizzo info@qualimenti.com")
                }
            }).catch(error => {
                setLoading(false)
                console.log(error.message)
                setStatus('modify')
                setError("Partita IVA non riconosciuta. Se ritieni che si tratti di un errore, contattaci all'indirizzo info@qualimenti.com")
            })
        }
    }

    useEffect(() => {
        setIva(iva.replace(/[^0-9]/g, ''))
        if(iva.length > 11) setIva(iva.substring(0, 11))
    }, [iva])

    useEffect(() => {
        updateStates();
    }, []);

    return <Row>
        <Col xs={12}>
            <h3 className="text-uppercase text-center">Dati di fatturazione</h3>
            <div className='text-center'><i>Ultimo step per accedere ai nostri prodotti</i></div>
            <Form onSubmit={handleStep3}>
                <Row className='mt-3'>
                    <Col xs={12} md={6}>
                        <Form.Group>
                            <Form.Label>Nome*</Form.Label>
                            <Form.Control type="text" required value={firstname} onChange={(e) => setFirsname(e.target.value)} placeholder='Nome' />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6} className='mt-3 mt-md-0'>
                        <Form.Group>
                            <Form.Label>Cognome*</Form.Label>
                            <Form.Control type="text" required value={lastname} onChange={(e) => setLastname(e.target.value)} placeholder='Cognome' />
                        </Form.Group>
                    </Col>
                </Row>
                {status === 'checkiva' && <Row className='mt-3'>
                    <Col xs={12}>
                        <Form.Group hasValidation>
                            <Form.Label>Partita IVA</Form.Label>
                            <Form.Control type="text" value={iva} onChange={(e) => setIva(e.target.value)} placeholder='Partita IVA' required onBlur={(e) => checkIvaFormat(e.target.value)} isInvalid={errors.iva} onFocus={() => setErrors({...errors, iva: null})} />
                            {errors.iva ? <Form.Control.Feedback type="invalid">{errors.iva}</Form.Control.Feedback> : <small className='d-flex align-items-center mt-1' style={{ gap:5 }}><AiOutlineInfoCircle /> Deve contenere 11 caratteri.</small>}
                        </Form.Group>
                    </Col>
                </Row>}
                {error && <Row className='mt-3'>
                    <Col xs={12} className='d-flex justify-content-center'>
                        <div className="alert alert-danger" role="alert">
                            {error}
                        </div>
                    </Col>
                </Row>}
                {status === 'checkiva' && <Row className='mt-3'>
                    <Col xs={12} className='d-flex justify-content-center'>
                        <Button role="button" onClick={checkIvaNumber} variant="qualimenti" type="submit" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50%'}}>
                            {loading ? (
                                <>
                                    <ThreeDots 
                                        width="40"
                                        height="25"
                                        color="#fff"
                                    />
                                    &nbsp;
                                </>
                            ) : (
                                <>Avanti</>
                            )}
                        </Button>
                    </Col>
                </Row>}
                {status === 'ivachecked' && <Row className='mt-3 justify-content-center'>
                    <Col xs={12} md={8}>
                        <Card className='bg-light'>
                            <Card.Body>
                                {addresses && addresses.billing && (
                                    <Row>
                                        <Col xs={12}>
                                            <div><b>{addresses.billing.company}</b></div>
                                            <div>{addresses.billing.address_1}</div>
                                            <div>{addresses.billing.city} {addresses.billing.postcode} ({addresses.billing.state})</div>
                                            <div>{addresses.billing.country}</div>
                                            <div>SDI : {addresses.billing.sdi}</div>
                                            <div>IVA : {addresses.billing.iva}</div>
                                        </Col>
                                    </Row>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={6} className='mt-3 d-flex justify-content-center'>
                        <Button role="button" onClick={() => setStatus('modify')} variant="light" type="submit" className='w-100 w-md-50' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            Modificare
                        </Button>
                    </Col>
                    <Col xs={6} className='mt-3 d-flex justify-content-center'>
                        <Button role="submit" variant="qualimenti" type="submit" className='w-100 w-md-50' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            {loading ? (
                                <>
                                    <ThreeDots 
                                        width="40"
                                        height="25"
                                        color="#fff"
                                    />
                                    &nbsp;
                                </>
                            ) : (
                                <>Confermare</>
                            )}
                        </Button>
                    </Col>
                </Row>}
                {status === 'modify' && <Row className='mt-3'>
                    <Col xs={12}>
                        <Row className='mt-3'>
                            <Col xs={12} md={6}>
                                <Form.Group controlid="formBasicEmail">
                                    <Form.Label>Ragione Sociale*</Form.Label>
                                    <Form.Control required type="text" placeholder="Ragione Sociale" value={addresses && addresses.billing && addresses.billing.company ? addresses.billing.company : ""} onChange={(e) => setAddresses({...addresses, billing: { ...addresses.billing, company: e.target.value }})} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6} className='mt-3 mt-md-0'>
                                <Form.Group controlid="formBasicEmail">
                                    <Form.Label>SDI*</Form.Label>
                                    <Form.Control required type="text" placeholder="SDI" value={addresses && addresses.billing && addresses.billing.sdi ? addresses.billing.sdi : ""} onChange={(e) => setAddresses({...addresses, billing: { ...addresses.billing, sdi: e.target.value }})} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col xs={12} md={12}>
                                <Form.Group controlid="formBasicEmail">
                                    <Form.Label>Indirizzo Fatturazione*</Form.Label>
                                    <Form.Control required type="text" placeholder="Indirizzo Fatturazione" value={addresses && addresses.billing && addresses.billing.address_1 ? addresses.billing.address_1 : ""} onChange={(e) => setAddresses({...addresses, billing: { ...addresses.billing, address_1: e.target.value }})} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col xs={12} md={12}>
                                <Form.Group controlid="formBasicEmail">
                                    <Form.Label>Indirizzo aggiuntivo</Form.Label>
                                    <Form.Control type="text" placeholder="Indirizzo aggiuntivo" value={addresses && addresses.billing && addresses.billing.address_2 ? addresses.billing.address_2 : ""} onChange={(e) => setAddresses({...addresses, billing: { ...addresses.billing, address_2: e.target.value }})} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col xs={12} md={3}>
                                <Form.Group controlid="formBasicEmail">
                                    <Form.Label>CAP*</Form.Label>
                                    <Form.Control required type="text" placeholder="CAP" value={addresses && addresses.billing && addresses.billing.postcode ? addresses.billing.postcode : ""} onChange={(e) => setAddresses({...addresses, billing: { ...addresses.billing, postcode: e.target.value }})} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={9} className='mt-3 mt-md-0'>
                                <Form.Group controlid="formBasicEmail">
                                    <Form.Label>Città*</Form.Label>
                                    <Form.Control required type="text" placeholder="Città" value={addresses && addresses.billing && addresses.billing.city ? addresses.billing.city : ""} onChange={(e) => setAddresses({...addresses, billing: { ...addresses.billing, city: e.target.value }})} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col xs={12} md={3}>
                                <Form.Group controlid="formBasicEmail">
                                    <Form.Label>Provincia*</Form.Label>
                                    <Form.Select
                                        required
                                        value={addresses && addresses.billing && addresses.billing.state}
                                        onChange={(e) => {
                                            setAddresses({...addresses, billing: { ...addresses.billing, state: e.target.value }});
                                        }}>
                                            <option value="">Seleziona uno stato</option>
                                            {Object.keys(states).map((stateCode, index) => {
                                                return <option key={stateCode} value={stateCode}>{states[stateCode]}</option>
                                            })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={9} className='mt-3 mt-md-0'>
                                <Form.Group controlid="formBasicEmail">
                                    <Form.Label>Paese*</Form.Label>
                                    <Form.Select
                                        required
                                        onChange={() => {}}
                                        value={addresses && addresses.billing && addresses.billing.country ? addresses.billing.country : ""}>
                                            <option value="IT">IT</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col xs={12} className='d-flex justify-content-center'>
                                <Button role="submit" variant="qualimenti" type="submit" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50%'}}>
                                    {loading ? (
                                        <>
                                            <ThreeDots 
                                                width="40"
                                                height="25"
                                                color="#fff"
                                            />
                                            &nbsp;
                                        </>
                                    ) : (
                                        <>Confermare</>
                                    )}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>}
            </Form>
        </Col>
    </Row>
}