
import Layout from './components/Layout/Layout'
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from './components/Authentication/Login'
import Home from './components/Products/Home'
import Category from './components/Products/Category'
import Orders from './components/Account/Orders'
import Account from './components/Account/Account'
import Partner from './components/Partner/Partner'
import { Register } from './components/Authentication/Register/Register'
import PasswordLost from './components/Account/PasswordLost'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-K2939282'
}

TagManager.initialize(tagManagerArgs)

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout><Home /></Layout>,
  },
  {
    path: "/login",
    element: <Layout nolayout={true}><Login /></Layout>,
  },
  {
    path: "/register",
    element: <Layout nolayout={true}><Register /></Layout>,
  },
  {
    path: "/password/lost/:login/:key",
    element: <Layout><PasswordLost /></Layout>,
  },
  {
    path: "/my-account/lost-password",
    element: <Layout><PasswordLost /></Layout>,
  },
  {
    path: "/my-account/orders",
    element: <Layout><Orders /></Layout>,
  },
  {
    path: "/my-account/:key",
    element: <Layout><Account /></Layout>,
  },
  {
    path: "/my-account",
    element: <Layout><Account /></Layout>,
  },
  {
    path: "/partner",
    element: <Layout><Partner /></Layout>,
  },
  {
    path: "/products/:type/:param1",
    element: <Layout><Category /></Layout>,
  },
  {
    path: "/products/:type/:param1/:param2",
    element: <Layout><Category /></Layout>,
  },
  {
    path: "/products/:type/:param1/:param2/:param3",
    element: <Layout><Category /></Layout>,
  },
  {
    path: "/products/:type/:param1/:param2/:param3/:param4",
    element: <Layout><Category /></Layout>,
  },
  // {
  //   path: "/search/:keyword",
  //   element: <Layout><Category type={"search"} /></Layout>,
  // },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
