import { useParams, Link, Navigate } from 'react-router-dom';
import { Row, Col, Card, Button, Form, InputGroup, Badge } from 'react-bootstrap';
// import ReactSlider from 'react-slider'
import { useEffect, useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom'
import { getProductCategories, getProducts, getCategoryFilters, getCategoryBaseUrl } from '../../utils/products';
// import ProductCard from './components/ProductCard';
import MobileHorizontalProductCard from './components/MobileHorizontalProductCard';
import HorizontalProductCard from './components/HorizontalProductCard';
import Loader from './components/Loader';
import { GlobalContext } from '../Layout/Layout';
import PostalCode from './components/PostalCode';
// import { isMobile } from 'react-device-detect';

import { BsArrowUpShort } from 'react-icons/bs';
import { FaSearch } from 'react-icons/fa';
import { MdSort } from 'react-icons/md';
import { TbAdjustmentsHorizontal } from 'react-icons/tb';

export default function Category() {
    const navigate = useNavigate()
    const { searchTerm, setSearchTerm, categories, user } = useContext(GlobalContext);
    const { type, param1, param2, param3, param4 } = useParams();
    const [ categoryItem, setCategoryItem ] = useState(null);
    const [ products, setProducts ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ page, setPage ] = useState(null);
    const [ priceSort, setPriceSort ] = useState(null);
    const [ categoryFilter, setCategoryFilter ] = useState(null);
    const [ filterOpen, setFilterOpen ] = useState(false)
    const [ customFilters, setCustomFilters ] = useState([]);
    const [ customFiltersTitle, setCustomFiltersTitle ] = useState({});
    const [ customSelectedFilters, setCustomSelectedFilters ] = useState({});
    const [ sellers, setSellers ] = useState([]);
    const [ selectedSellers, setSelectedSellers ] = useState(null);
    const [ scrollTop, setScrollTop ] = useState(0)
    const [ total, setTotal ] = useState(0);
    // const [ productDisplayMode ,setProductDisplayMode ] = useState(isMobile ? 'grid' : 'list');
    const [ categoryFilterList, setCategoryFilterList ] = useState(categories);
    const searchTimer = useRef(null);
    const categoryTimer = useRef(null);

    const handleOpenFilter = () => {
        setFilterOpen(filterOpen ? false : true)
    }

    const getParams = (pageForced) => {
        let params = {
            page: page ? page : 1
        }

        if(categoryItem && categoryItem.id) params.categories = categoryItem.id;

        if(searchTerm && searchTerm.length >= 3) {
            params.search = searchTerm;
        }

        if(pageForced) {
            params.page = pageForced;
        } else if (page) {
            params.page = page;
        } else {
            params.page = 1;
        }

        if(selectedSellers) {
            params.sellers = selectedSellers;
        }

        if(customSelectedFilters && Object.keys(customSelectedFilters).length > 0) {
            Object.keys(customSelectedFilters).map(key => {
                if(customSelectedFilters[key].length > 0) params["meta:" + key] = customSelectedFilters[key].join(',');
                return null;
            })
        }

        if(priceSort) params.priceOrder = priceSort;
        return params
    }

    useEffect(() => {
        if(selectedSellers) setPage(null);
    }, [selectedSellers])

    useEffect(() => {
        setLoading(true);
        if(categoryTimer.current) clearTimeout(categoryTimer.current);
        categoryTimer.current = setTimeout(() => {
            getProductCategories(null, searchTerm).then(response => {
                setCategoryFilterList(response.tree);
            }).catch(error => {
                console.log(error);
            });
        }, 500);
    }, [searchTerm]);
        
    useEffect(() => {
        setLoading(true);
        setCategoryItem(null)

        let slug = null;
        let search = '';

        if ( type === 's' )  {
            search = param1;
            setSearchTerm(param1);
            slug = param4 ? param4 : param3 ? param3 : param2 ? param2 : null;
        } else if ( type === 'c' ) {
            slug = param3 ? param3 : param2 ? param2 : param1 ? param1 : null;
        }

        getProductCategories(slug, search ? search : searchTerm ? searchTerm : null).then(response => {
            if(response.list && response.list.length > 0) {
                setSelectedSellers(null);
                if(searchTerm === '' && search === '') 
                    setCategoryItem(response.list[0]);
                else if((searchTerm.length > 0 || search.length > 0) && slug && slug !== "") {
                    setCategoryItem(response.list[0])
                } else if((searchTerm.length > 0 || search.length > 0) && (!slug || slug === "")) {
                    setCategoryItem(null)
                }

            } else {
            }
        }).catch(error => {
            console.log(error);
        });
        // setProductDisplayMode(isMobile ? 'grid' : 'list')
        
        // eslint-disable-next-line
    }, [param1, param2, param3, param4]);

    useEffect(() => {
        if((categoryItem && categoryItem.id) || (searchTerm && searchTerm.length >= 3)) {
            setLoading(true);
            if(searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
                setPage(null)
                getProducts(getParams(1)).then(response => {
                    setProducts(response.products);
                    setSellers(response.filters && response.filters.sellers ? response.filters.sellers : []);
                    setCustomFiltersTitle(response.filters_titles ? response.filters_titles : {})
                    if(response.filters) {
                        let tmpFilters = {};
                        Object.keys(response.filters).map(filterKey => {
                            if(filterKey === 'sellers') return null;
                            response.filters[filterKey].map(value => {
                                if(!tmpFilters[filterKey]) tmpFilters[filterKey] = [];
                                tmpFilters[filterKey].push(value);
                                return null;
                            })
                            return null;
                        })
                        setCustomFilters(tmpFilters);
                    }
                    setTotal(response.total_found);
                    setLoading(false);
                }).catch(error => {
                    setProducts([]);
                    setLoading(false);
                });
            }, 500);
        } else if (searchTerm.length < 3) {
            setProducts([]);
        }
        // eslint-disable-next-line
    }, [searchTerm, categoryItem, selectedSellers, priceSort, param1, customSelectedFilters]);

    useEffect(() => {
        console.log(customSelectedFilters)
    }, [customSelectedFilters])

    useEffect(() => {
        if(categoryItem && categoryItem.id && Object.keys(categoryFilterList).length > 0) {
            setCategoryFilter(getCategoryFilters(categoryFilterList, categoryItem.id));
        } else {
            setCategoryFilter(getCategoryFilters(categoryFilterList, 0));
        }
    }, [categoryFilterList, categoryItem])

    useEffect(() => {
        if(page) {
            setLoading(true);
            getProducts(getParams()).then(response => {
                setProducts(products => products.concat([...response.products]));
                setLoading(false);
            }).catch(error => {
                alert('Unable to fetch products')
                setLoading(false);
            })
        }
        // eslint-disable-next-line
    }, [page]);

    // Automatic paging when scrolling
    useEffect(() => {
        if(!loading) {
            const handleScroll = () => {
                setScrollTop(document.documentElement.scrollTop)
                if(loading) return;
                if(window.innerHeight + document.documentElement.scrollTop < document.documentElement.offsetHeight - 200) return;
                if(products.length < total) setPage(page => (page ? page : 1) + 1);
            }
            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }
        // eslint-disable-next-line
    }, [loading]);

    useEffect(() => {
        setLoading(true);
    }, []);

    const handleSearch = (e) => {
        e.preventDefault();
        if(searchTerm.length >= 3) {
            // navigate('/products/s/' + encodeURIComponent(searchTerm.replace(/[/-]/g, "-")))
            navigate('/products/s/' + encodeURIComponent(searchTerm))
        }
    }

    if(!user) return <Navigate to="/login" />
    if(user.iva === "") return <Navigate to="/register" />

    return (
        <Row>
            <Col xs={12} style={{marginTop: "1em"}} className="position-relative">
                {window.innerWidth < 768 && <form onSubmit={handleSearch}>
                    <InputGroup style={{marginBottom: '1em'}}>
                        <Form.Control style={{border: 0, backgroundColor: 'rgba(255,255,255,.9)', borderTopLeftRadius: '40px', borderBottomLeftRadius: '40px'}} type="text" placeholder="Cerca..." value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />
                        <Button variant="search-close" style={{backgroundColor: 'rgba(255,255,255,.9)', borderTopRightRadius: '40px', borderBottomRightRadius: '40px'}} onClick={handleSearch}>
                            <FaSearch />
                        </Button>
                    </InputGroup>
                </form>}
                {!loading && (!user.postcode || user.postcode === "") && <PostalCode />}
                <div className="wrapper">
                    {window.innerWidth >= 768 && <Row>
                        <Col xs={12} md={3} className="mb-3">
                            <h5><Badge className="p-2 text-uppercase badge-brown">{total ? total : 0} prodotti</Badge></h5>
                        </Col>
                        <Col xs={12} md={9} className="mb-3">
                            <Row>
                                <Col xs={12} md={8}>
                                    <h1 className="m-0 text-brown">
                                        {searchTerm !== '' && `"${searchTerm}"`}
                                        {categoryItem && searchTerm !== '' && " in "}
                                        {categoryItem ? categoryItem.name : ""}
                                    </h1>
                                </Col>
                                <Col xs={12} md={4} className='pt-2'>
                                    <InputGroup controlid="priceSort" className="mb-0">
                                        <InputGroup.Text style={{ backgroundColor: '#fff', borderRight: 0 }}><MdSort /></InputGroup.Text>
                                        <Form.Select onChange={(e) => setPriceSort(e.target.value)} style={{ borderLeft: 0, outline: 'none' }} className='removeOutline'>
                                            <option value="">Ordina per</option>
                                            <option value="asc">Prezzo crescente</option>
                                            <option value="desc">Prezzo decrescente</option>
                                        </Form.Select>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>}
                    {window.innerWidth < 768 && <Row className='mb-3'>
                        <Col xs={12}>
                            <h1 className="m-0 mb-2 text-brown">
                                {searchTerm !== '' && `"${searchTerm}"`}
                                {categoryItem && searchTerm !== '' && " in "}
                                {categoryItem ? categoryItem.name : ""}
                            </h1>
                        </Col>
                        <Col xs={6}>
                            <h5><Badge className="p-2 text-uppercase badge-brown">{total ? total : 0} prodotti</Badge></h5>
                        </Col>
                        <Col xs={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button variant="light" onClick={handleOpenFilter}>Filtra e ordina <TbAdjustmentsHorizontal /></Button>
                        </Col>
                    </Row>}
                    <Row>
                        <Col xs={12} md={3}>
                            <Card className={filterOpen ? "d-block mb-3 filters" : "d-none d-md-block mb-3 filters"}>
                                <Card.Body>
                                    <div className={filterOpen ? "d-block filter-border" : "d-none d-md-block filter-border"}>
                                        {window.innerWidth < 768 && <InputGroup controlid="priceSort" className="mb-0 mt-2">
                                            <InputGroup.Text style={{ backgroundColor: '#fff', borderRight: 0 }}><MdSort /></InputGroup.Text>
                                            <Form.Select onChange={(e) => setPriceSort(e.target.value)} style={{ borderLeft: 0, outline: 'none' }} className='removeOutline'>
                                                <option value="">Ordina per</option>
                                                <option value="asc">Prezzo crescente</option>
                                                <option value="desc">Prezzo decrescente</option>
                                            </Form.Select>
                                        </InputGroup>}
                                        <hr className="d-block d-md-none" />
                                        {categoryFilter && categoryFilter && Object.keys(categoryFilter).length > 0 && <>
                                            <h5 className="text-brown">Categorie</h5>
                                            <ul className="list-unstyled filter">
                                                {Object.keys(categoryFilter).map((key, index) => {
                                                    return <li key={index}>
                                                        <Link className="text-decoration-none text-brown" to={`${getCategoryBaseUrl(type, searchTerm, categoryFilterList, key)}`}>
                                                            <Form.Check type="checkbox" onChange={e => console.log(e.target.value)} label={`${categoryFilter[key].name} (${categoryFilter[key].count})`} className="text-secondary" checked={categoryItem && categoryFilter[key].name === categoryItem.name ? true : false} />
                                                        </Link>
                                                    </li>
                                                })}
                                            </ul>
                                        </>}
                                        {sellers && sellers.length > 0 && <>
                                            <hr className="brown" />
                                            <h5 className="text-brown">Venditori</h5>
                                            {sellers.map((seller, index) => {
                                                return <div key={index} className="text-brown">
                                                    <Form.Check type="checkbox" id={seller.id} checked={selectedSellers && selectedSellers.indexOf(seller.id) !== -1 ? true : false} label={`${seller.name}`} className="text-secondary" onChange={(e) => {
                                                        if(e.target.checked) {
                                                            if(!selectedSellers) {
                                                                setSelectedSellers([seller.id]);
                                                            } else {
                                                                setSelectedSellers([...selectedSellers, seller.id]);
                                                            }
                                                        } else {
                                                            setSelectedSellers(selectedSellers.filter(item => item !== seller.id));
                                                        }
                                                    }} />
                                                </div>
                                            })}
                                        </>}
                                        {customFilters && Object.keys(customFilters).length > 0 && Object.keys(customFilters).map((key, index) => {
                                            return <div key={index}>
                                                <hr className="brown" />
                                                <h5 className="text-brown">{customFiltersTitle[key]}</h5>
                                                {customFilters[key].map((filter, index) => {
                                                    return <div key={index} className="text-brown">
                                                        <Form.Check type="checkbox" label={`${filter.name} (${filter.count})`} className="text-secondary" checked={customSelectedFilters[key] && customSelectedFilters[key].indexOf(filter.name) !== -1 ? true : false}
                                                        onChange={() => {
                                                            if(!customSelectedFilters[key]) {
                                                                setCustomSelectedFilters({...customSelectedFilters, [key]: [filter.name]})
                                                            } else {
                                                                if(customSelectedFilters[key].indexOf(filter.name) !== -1) {
                                                                    setCustomSelectedFilters({...customSelectedFilters, [key]: customSelectedFilters[key].filter(item => item !== filter.name)})
                                                                } else {
                                                                    setCustomSelectedFilters({...customSelectedFilters, [key]: [...customSelectedFilters[key], filter.name]})
                                                                }
                                                            }
                                                        }} />
                                                    </div>
                                                })}
                                            </div>
                                        })}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} md={9} className="position-relative">
                            {loading && (
                                <Loader />
                            )}
                            <Row>
                                {products.length > 0 ? (
                                    <>
                                        {products.map(product => (
                                            <Col xs={12} key={product.id} className="d-flex" style={{marginBottom: '1em'}}>
                                                {window.innerWidth < 768 && <MobileHorizontalProductCard product={product} />}
                                                {window.innerWidth >= 768 && <HorizontalProductCard product={product} />}
                                                {/* {productDisplayMode === "list" && <HorizontalProductCard product={product} />}
                                                {productDisplayMode === "grid" && <ProductCard product={product} />} */}
                                            </Col>
                                        ))}
                                    </>
                                ) : !loading ? (
                                    <>
                                        <Col xs={12}>
                                            <h3>Nessun prodotto trovato</h3>
                                        </Col>
                                    </>
                                ) : null}
                            </Row>
                            {total && products.length < total ? <Row className="mb-3">
                                <Col xs={12} className="d-flex justify-content-end">
                                    <Button variant="qualimenti-outlined" className="p-3 text-uppercase" style={{borderRadius: '20px'}} onClick={() => setPage(page ? page + 1 : 2)}>Vedi più prodotti</Button>
                                </Col>
                            </Row> : null}
                        </Col>
                    </Row>
                </div>
                {page > 1 && scrollTop > 100 && <div className="scrollTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                    <BsArrowUpShort />
                </div>}
            </Col>
        </Row>
    )
}