import { useState, useContext, useEffect } from 'react';
import { Row, Col, Form, Button, Alert, Card } from 'react-bootstrap';
import { GlobalContext } from '../../Layout/Layout';
import { getCustomerAdresses, getStates, updateProfile } from '../../../utils/users';


export default function Facturation() {
    const { user, refreshUser } = useContext(GlobalContext);
    const [addresses, setAddresses] = useState({
        shipping:{},
        billing:{}
    });
    const [ states, setStates ] = useState([]);
    const [ email, setEmail ] = useState(user.user_email);
    const [ error, setError ] = useState(false);
    const [ success, setSuccess ] = useState(false);
    const [ isIvaButtonDisabled, setIsIvaButtonDisabled ] = useState(true);

    const handleUpdate = (e) => {
        e.preventDefault();
        let data = {
            email: email,
            billing: addresses.billing,
        };

        if (!addresses?.shipping?.same_as_billing || addresses?.shipping?.same_as_billing === "true" || addresses?.shipping?.same_as_billing === "") {
            data.shipping = addresses.billing;
        }

        updateProfile(data).then((res) => {
            setSuccess(true);
            refreshUser()
            setTimeout(() => {
                setSuccess(false);
            }, 3000);
        }).catch(error => {
            console.log(error)
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 3000);
        })
    }

    useEffect(() => {
        getCustomerAdresses(user.user_id).then((res) => {
            setAddresses(res);
            if(res.billing.iva && res.billing.iva.match(/^[0-9]{11}$/)) {
                setIsIvaButtonDisabled(false)
            }
            getStates(res.billing.country).then(response => {
                setStates(response);
            }).catch(error => {
                console.log(error)
            })
        }).catch(error => {
            console.log(error)
        })
        // eslint-disable-next-line
    }, [])

    const handleIvaUpdate = (e) => {
        let iva = e.target.value;
        iva = iva.replace(/[^0-9]+/g, '');
        
        if(iva.length > 11) {
            iva = iva.substring(0, 11)
        }

        if(iva.length < 11) {
            setIsIvaButtonDisabled(true)
        } else if (iva.length === 11) {
            setIsIvaButtonDisabled(false)
        }
        setAddresses({...addresses, billing: { ...addresses.billing, iva: iva }})
    }

    return <Row>
        <Col xs={12}>
            <Card>
                <Card.Header style={{backgroundColor: '#ae3335', color: 'white'}} className="d-flex justify-content-between">
                    <h5 className="m-0">Profilo</h5>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleUpdate}>
                        <Row>
                            <Col xs={12} md={12}>
                                <Form.Group controlid="formBasicEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="Inserisci email" value={email ? email : ""} onChange={(e) => setEmail(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <hr />
                        <Row className='mt-2'>
                            <Col xs={12} md={6}>
                                <Form.Group controlid="formBasicEmail">
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control type="text" placeholder="Nome" value={addresses.billing.first_name ? addresses.billing.first_name : ""} onChange={(e) => setAddresses({...addresses, billing: { ...addresses.billing, first_name: e.target.value }})} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group controlid="formBasicEmail">
                                    <Form.Label>Cognome</Form.Label>
                                    <Form.Control type="text" placeholder="Cognome" value={addresses.billing.last_name ? addresses.billing.last_name : ""} onChange={(e) => setAddresses({...addresses, billing: { ...addresses.billing, last_name: e.target.value }})} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <hr />
                        <Row className='mt-2'>
                            <Col xs={12} md={12}>
                                <Form.Group controlid="formBasicEmail">
                                    <Form.Label>Ragione Sociale</Form.Label>
                                    <Form.Control type="text" placeholder="Ragione Sociale" value={addresses.billing.company ? addresses.billing.company : ""} onChange={(e) => setAddresses({...addresses, billing: { ...addresses.billing, company: e.target.value }})} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col xs={12} md={12}>
                                <Form.Group controlid="formBasicEmail">
                                    <Form.Label>Indirizzo Fatturazione</Form.Label>
                                    <Form.Control type="text" placeholder="Indirizzo Fatturazione" value={addresses.billing.address_1 ? addresses.billing.address_1 : ""} onChange={(e) => setAddresses({...addresses, billing: { ...addresses.billing, address_1: e.target.value }})} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col xs={12} md={12}>
                                <Form.Group controlid="formBasicEmail">
                                    <Form.Label>Indirizzo aggiuntivo</Form.Label>
                                    <Form.Control type="text" placeholder="Indirizzo aggiuntivo" value={addresses.billing.address_2 ? addresses.billing.address_2 : ""} onChange={(e) => setAddresses({...addresses, billing: { ...addresses.billing, address_2: e.target.value }})} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col xs={12} md={2}>
                                <Form.Group controlid="formBasicEmail">
                                    <Form.Label>CAP</Form.Label>
                                    <Form.Control type="text" placeholder="CAP" value={addresses.billing.postcode ? addresses.billing.postcode : ""} onChange={(e) => setAddresses({...addresses, billing: { ...addresses.billing, postcode: e.target.value }})} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={10}>
                                <Form.Group controlid="formBasicEmail">
                                    <Form.Label>Città</Form.Label>
                                    <Form.Control type="text" placeholder="Città" value={addresses.billing.city ? addresses.billing.city : ""} onChange={(e) => setAddresses({...addresses, billing: { ...addresses.billing, city: e.target.value }})} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col xs={12} md={3}>
                                <Form.Group controlid="formBasicEmail">
                                    <Form.Label>Provincia</Form.Label>
                                    <Form.Select
                                        value={addresses.billing.state}
                                        onChange={(e) => setAddresses({...addresses, billing: { ...addresses.billing, state: e.target.value }})}>
                                            <option value="">Seleziona uno stato</option>
                                            {Object.keys(states).map((stateCode, index) => {
                                                return <option key={stateCode} value={stateCode}>{states[stateCode]}</option>
                                            })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={9}>
                                <Form.Group controlid="formBasicEmail">
                                    <Form.Label>Paese</Form.Label>
                                    <Form.Select
                                        onChange={() => {}}
                                        value={addresses.billing.country ? addresses.billing.country : ""}>
                                            <option value="IT">IT</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <hr />
                        <Row className='mt-2'>
                            <Col xs={12} md={12}>
                                <Form.Group controlid="formBasicEmail">
                                    <Form.Label>Telefono</Form.Label>
                                    <Form.Control type="text" placeholder="Telefono" value={addresses.billing.phone ? addresses.billing.phone : "" } onChange={(e) => setAddresses({...addresses, billing: { ...addresses.billing, phone: e.target.value }})} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <hr />
                        <Row className='mt-2'>
                            <Col xs={12} md={7}>
                                <Form.Group controlid="formBasicEmail">
                                    <Form.Label>Partita IVA</Form.Label>
                                    <Form.Control type="text" placeholder="Partita IVA" value={addresses.billing.iva ? addresses.billing.iva : ""} onChange={handleIvaUpdate} />
                                    {isIvaButtonDisabled && <small style={{color: '#ae3335'}}>Il numero di partita IVA deve contenere 11 cifre</small>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={5}>
                                <Form.Group controlid="formBasicEmail">
                                    <Form.Label>SDI</Form.Label>
                                    <Form.Control type="text" placeholder="SDI" value={addresses.billing.sdi ? addresses.billing.sdi : ""} onChange={(e) => setAddresses({...addresses, billing: { ...addresses.billing, sdi: e.target.value }})} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <hr />
                        <Row className='mt-2'>
                            <Col xs={12} md={12} className='d-grid'>
                                <Button type="submit" variant="qualimenti" className='btn-lg' disabled={isIvaButtonDisabled}>Salva</Button>
                            </Col>
                        </Row>
                        {error && <Row className='mt-2'>
                            <Col xs={12} md={12}>
                                <Alert variant='danger'>Si è verificato un errore durante l'aggiornamento del tuo profilo</Alert>
                            </Col>
                        </Row>}
                        {success && <Row className='mt-2'>
                            <Col xs={12} md={12}>
                                <Alert variant='success'>Profilo aggiornato con successo</Alert>
                            </Col>
                        </Row>}
                    </Form>
                </Card.Body>
            </Card>
        </Col>
    </Row>
}