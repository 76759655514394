import { Row, Col, Button, ButtonGroup, Form, Alert, InputGroup } from 'react-bootstrap'
import { useState, useContext, useEffect } from 'react'
import { useNavigate, Link, Navigate } from "react-router-dom";
import { GlobalContext } from '../Layout/Layout'
import { login, getUser, getCart, passwordLost } from '../../utils/users'
import { ThreeDots } from 'react-loader-spinner'
import { TbLock  } from 'react-icons/tb'
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import logo from '../../assets/img/qualimenti_logo.png'

export default function Login() {
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const { user, setUser, setCart } = useContext(GlobalContext)
    const [ loginError, setLoginError ] = useState('');
    const [ displayLostPassword, setDisplayLostPassword ] = useState(false);
    const [ error, setError ] = useState("");
    const [ success, setSuccess ] = useState("");
    const [ displayPassword, setDisplayPassword ] = useState(false);

    const navigate = useNavigate();

    const handleLogin = (e) => {
        e.preventDefault();
        setLoading(true);
        login(email, password).then(logued => {
            if(logued) {
                getUser().then(user => {
                    setUser(user);
                    getCart(user).then(cart => {
                        setCart(cart);
                        setLoading(false);
                    }).catch(error => {
                        setLoading(false);
                    })
                }).catch(error => {
                    setLoading(false);
                })
            } else {
                setLoading(false);
            }
        }).catch(error => {
            console.log(error.message)
            setLoginError(error.message)
            setLoading(false);
        })
    }

    const handlePasswordLost = (e) => {
        e.preventDefault();
        setLoading(true);
        setError("");
        setSuccess("");
        if (email === "") {
            setError("Devi compilare tutti i campi");
            setLoading(false);
            return;
        } else {
            passwordLost(email)
                .then((response) => {
                    setSuccess("Email inviata con successo");
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setError(error.message);
                    setLoading(false);
                });
        }
    }

    useEffect(() => {   
        if(user && user.id) navigate('/')
        // eslint-disable-next-line
    }, [user])

    document.body.style.backgroundColor = 'white';

    if (user) return <Navigate to="/" />

    return (
        <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            overflowX: 'hidden',
        }}>
            <Row className='h-100 w-100' style={{justifyContent: 'center', position: 'relative'}}>
                <Col xs={12} md={6}>
                    <Row className='justify-content-end align-items-center h-100'>
                        {displayLostPassword ? (
                            <Col xs={12} md={9} style={window.width > 768 ? {marginTop: '-3em'} : {}}>
                                <Form onSubmit={handlePasswordLost}>
                                    <Form.Group>
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </Form.Group>
                                    {error && <Alert className="mt-2" variant="danger">{error}</Alert>}
                                    {success && <Alert className="mt-2" variant="success">{success}</Alert>}
                                    <div className="d-grid gap-2 mt-2">
                                        <Button type="submit" variant="qualimenti" disabled={loading}>
                                            {loading ? "Caricamento..." : "Invia email"}
                                        </Button>
                                    </div>
                                </Form>
                            </Col>
                        ) : (
                            <Col xs={12} md={9} style={window.width > 768 ? {marginTop: '-3em'} : {}}>
                                <Row>
                                    <Col xs={12} className='d-flex justify-content-center d-md-none mb-5'>
                                        <img src={logo} alt="Qualimenti" style={{width: '100%', maxWidth: '300px', display: 'block'}} />
                                    </Col>
                                    <Col xs={12} className='d-flex justify-content-center'>
                                        <ButtonGroup>
                                            <Button variant="qualimenti" active style={{width: 120}}>Accedi</Button>
                                            <Button variant="light" className='text-secondary' onClick={(e) => navigate('/register')} style={{width: 120}}>Registrati</Button>
                                        </ButtonGroup>
                                    </Col>
                                    <Col xs={12}>
                                        <Form onSubmit={handleLogin}>
                                            <Row>
                                                <Col xs={12} className="mt-3">
                                                    <Form.Group>
                                                        <Form.Label style={{marginBottom: 0}}>Email*</Form.Label>
                                                        <Form.Control id="email" type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} className="mt-3">
                                                    <Form.Group>
                                                        <Form.Label style={{marginBottom: 0}}>Password*</Form.Label>
                                                        <InputGroup>
                                                            <Form.Control id="password" type={displayPassword ? "text" : "password"} placeholder="Password" aria-describedby="basic-addon2" value={password} onChange={(e) => setPassword(e.target.value)} required style={{
                                                                borderRight: 0,
                                                            }} />
                                                            <Button variant="outline-secondary" id="button-addon2" onClick={() => setDisplayPassword(!displayPassword)} style={{
                                                                borderTopRightRadius: 5, 
                                                                borderBottomRightRadius: 5,
                                                                border: '1px solid #ced4da',
                                                                borderLeft: 0,
                                                            }}>
                                                                {displayPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                                                            </Button>
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Col>
                                                {loginError && (
                                                    <Col xs={12} className="mt-3">
                                                        <Alert variant="danger m-0"><span dangerouslySetInnerHTML={{__html: loginError}} /></Alert>
                                                    </Col>
                                                )}
                                                <Col xs={12} className="mt-3 text-center">
                                                    <Link to="/my-account/lost-password" onClick={(e) => {
                                                        e.preventDefault();
                                                        setDisplayLostPassword(true);
                                                    }} className="qualimenti-link" style={{textDecoration: 'underline'}}>
                                                        <small>Hai dimenticato la password</small>
                                                    </Link>
                                                </Col>
                                                <Col xs={12} className="mt-3 d-grid">
                                                    <Button variant="qualimenti" type="submit" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                        {loading ? (
                                                            <>
                                                                <ThreeDots 
                                                                    width="40"
                                                                    height="25"
                                                                    color="#fff"
                                                                />
                                                                &nbsp;
                                                            </>
                                                        ) : (
                                                            <><TbLock size="1.2em" className="me-2" style={{transform: 'rotate(-15deg)'}} /> Login</>
                                                        )}
                                                    </Button>  
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                            </Col>
                        )}
                    </Row>
                </Col>
                <Col xs={12} md={6} className="d-none d-md-block p-0" style={{
                    backgroundColor: '#F5F5F5',
                    content:'',
                    backgroundRepeat: 'no-repeat',
                    bottom: '-0.1vw',
                    left: '-0.1vw',
                    right: '-0.1vw',
                    top: '-0.1vw', 
                    backgroundSize: "83px 100%",
                    backgroundPosition: '0% 50%',
                    backgroundImage: 'url(\'data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2.17 35.28" preserveAspectRatio="none"><path d="M.5 35.28C1.04 32.2.07 26 .5 19.13.9 12.26 1.06 5.4.38 0H0v35.28z" fill="%23ffffff"/></svg>\')'
                }}>
                    <Row className='justify-content-start align-items-center h-100'>
                        <Col xs={12} md={8} style={{ paddingLeft: '100px', marginTop: '-3em'}}>
                            <div className="d-flex flex-column align-items-end text-right">
                                <img src={logo} alt="Qualimenti" style={{width: '100%', maxWidth: '300px', display: 'block'}} />
                                <p style={{fontSize: '1.3em', marginTop: '2em'}}>Accedi al più importante mercato online dedicato a ristoranti, bar, hotel, enoteche e negozi alimentari</p>
                                <small>Scegli tra oltre 12.000 prodotti selezionati</small>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}