import { useEffect, useContext, useState } from 'react';
import { Row, Col, Modal, Button, Table } from 'react-bootstrap';
import { useNavigate, Navigate } from 'react-router-dom';
import numeral from 'numeral';
import moment from 'moment';
import 'moment/locale/it';
import { getOrders, getOrderStatus, duplicateOrder } from '../../utils/products';
import { getCart } from '../../utils/users';
import { GlobalContext } from '../Layout/Layout';
// import HomeCategories from '../Products/components/HomeCategories'
import Loader from '../Products/components/Loader';

export default function Orders() {
    const [ orders, setOrders ] = useState([]);
    const [ displayedOrders, setDisplayedOrders ] = useState([]);
    const [ orderDetails, setOrderDetails ] = useState(null);
    const [ loading, setLoading ] = useState(true);
    const [ loadingDuplicate, setLoadingDuplicate ] = useState(false);
    const { user, setDisplayCart, setCart } = useContext(GlobalContext);
    const [ monthes, setMonthes ] = useState([]);
    const [ selectedMonth, setSelectedMonth ] = useState("");
    const navigate = useNavigate();
    numeral.locale('it');
    moment.locale('it');

    const handleDuplicateOrder = (order) => {
        setLoadingDuplicate(true);
        duplicateOrder(order).then(response => {
            getCart(user).then(cart => {
                setCart(cart);
                setLoadingDuplicate(false);
                setOrderDetails(null);
                navigate('/')
                setDisplayCart(true);
            }).catch(error => {
                console.log(error);
            })
        }).catch(error => {
            console.log(error);
        })
    }

    useEffect(() => {
        getOrders().then(response => {
            setOrders(response);
            let tmpMonthes = [];
            if(response.length > 0) {
                let olderMonth = moment(response[response.length - 1].date)

                while(olderMonth.isBefore(moment().endOf('month'))) {
                    tmpMonthes.push({"identifier": olderMonth.format('MM-YYYY'), "value": olderMonth.format('MMMM YYYY')});
                    olderMonth.add(1, 'month');
                }
                
                tmpMonthes = tmpMonthes.reverse();
                setMonthes(tmpMonthes);
                setSelectedMonth(tmpMonthes[0].identifier);
            }
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            console.log(error);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(selectedMonth) {
            let tmpDisplayedOrders = orders.filter(order => moment(order.date).format('MM-YYYY') === selectedMonth);
            setDisplayedOrders(tmpDisplayedOrders);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMonth]);

    if(!user) return <Navigate to="/login" />
    if(user.iva === "") return <Navigate to="/register" />
    
    return <Row className="justify-content-center">
        <Col xs={12} md={8} className="mt-4">
            <Row>
                <Col xs={12} md={6}>
                    <h2>I miei ordini</h2>
                </Col>
                <Col xs={12} md={6}>
                    <select className="form-control" value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
                        {!loading && monthes.length === 0 && <option value="">Non hai ancora effettuato nessun ordine</option>}
                        {monthes.map(month => <option key={month.identifier} value={month.identifier}>{month.value}</option>)}
                    </select>
                </Col>
            </Row>
            {loading && <Row><Col xs={12} className="position-relative"><Loader /></Col></Row>}
            {!loading && displayedOrders.length === 0 && <Row><Col xs={12}>Non hai ordini per il mese selezionato</Col></Row>}
            {!loading && displayedOrders.length > 0 && <Row>
                <Col xs={12}>
                    <Table striped responsive>
                        <thead>
                            <tr>
                                <th scope="col">ID</th>
                                <th scope="col">Data</th>
                                <th scope="col">Totale</th>
                                <th scope="col">Stato</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {displayedOrders.map(order => <tr key={order.id}>
                                <th scope="row">{order.id}</th>
                                <td className="d-none d-md-table-cell">{moment(order.date).format('LL')}</td>
                                <td className="text-nowrap d-table-cell d-md-none">{moment(order.date).format('Y-MM-DD')}</td>
                                <td className="text-nowrap">{numeral(order.total).format('0,0.00 $')}</td>
                                <td>{getOrderStatus(order.status) ? getOrderStatus(order.status) : order.status_name}</td>
                                <td className="text-right">
                                    <Button variant="qualimenti" onClick={() => setOrderDetails(order)}>Dettagli</Button>
                                </td>
                            </tr>)}
                        </tbody>
                    </Table>
                </Col>
            </Row>}
        </Col>
        <Modal show={orderDetails ? true : false} size={"xl"} onHide={() => setOrderDetails(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{orderDetails && `Ordinato ${orderDetails.id}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={12}>
                        <Row>
                            <Col xs={12} md={6}>
                            </Col>
                            <Col xs={12} md={6}>
                                <h4>Indirizzo di spedizione</h4>
                                <p>{orderDetails && orderDetails.shipping_address.company}<br />
                                {orderDetails && orderDetails.shipping_address.first_name} {orderDetails && orderDetails.shipping_address.last_name}<br />
                                {orderDetails && orderDetails.shipping_address.address_1}<br />
                                {orderDetails && orderDetails.shipping_address.address_2}<br />
                                {orderDetails && orderDetails.shipping_address.postcode} {orderDetails && orderDetails.shipping_address.city}<br />
                                {orderDetails && orderDetails.shipping_address.country}</p>

                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th scope="col">Venditore</th>
                                            <th scope="col">Prodotto</th>
                                            <th scope="col">Quantità</th>
                                            <th scope="col">Prezzo</th>
                                            <th scope="col">Totale</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderDetails && orderDetails.items.map(item => <tr key={item.id}>
                                            <td>{item.seller_name}</td>
                                            <td>{item.name}</td>
                                            <td className="text-right">{item.quantity} {item.quantity_type}</td>
                                            <td className="text-right text-nowrap">{numeral(item.price).format('0,0.00 $')}</td>
                                            <td className="text-right text-nowrap">
                                                <b>{numeral(item.line_total).format('0,0.00 $')}</b>
                                                {item.price < item.product_price && (
                                                    <>
                                                        <br />
                                                        <small>
                                                            {item.discount_type === "seller" ? "Sconto venditore : " : null}
                                                            {item.discount_type === "product" ? "Sconto prodotto : " : null}
                                                            {item.discount_type === "seller_volume" ? "Sconto volume venditore : " : null}
                                                            {item.discount_type === "" ? "Sconto : " : null}
                                                            {numeral((item.product_price - item.price)*item.quantity).format('0,0.00 $')}
                                                        </small>
                                                    </>
                                                )}
                                            </td>
                                        </tr>)}
                                    </tbody>
                                </Table>
                                {orderDetails && orderDetails.fees && orderDetails.fees.length > 0 && <>
                                    <Table responsive>
                                        <tbody>
                                            <tr>
                                                <td colSpan={5}><h5>Sconti</h5></td>
                                            </tr>
                                            {orderDetails.fees.map(fee => <tr key={fee.id}>
                                                <td>{fee.seller_name}</td>
                                                <td>{fee.name}</td>
                                                <td></td>
                                                <td></td>
                                                <td className="text-right text-nowrap">{numeral(fee.amount).format('0,0.00 $')}</td>
                                            </tr>)}
                                        </tbody>
                                    </Table>
                                </>}
                                {orderDetails && <>
                                    <Table responsive>
                                        <tbody>
                                            {orderDetails.shipping && (<tr>
                                                <td colSpan={4} className="text-right">Spedizione{orderDetails.delivery_time && orderDetails.delivery_time !== "" && <><br /><small>Orario desiderato per la consegna</small></>}</td>
                                                <td className="text-right text-nowrap">{numeral(orderDetails.shipping.price).format('0,0.00 $')}{orderDetails.delivery_time && orderDetails.delivery_time !== "" && <><br /><small>{orderDetails.delivery_time} - {parseInt(orderDetails.delivery_time.split(':')[0]) + 2}:00</small></>}</td>
                                            </tr>)}
                                            {orderDetails.taxes && Object.keys(orderDetails.taxes).length > 0 && Object.keys(orderDetails.taxes).map(taxKey => <tr key={taxKey}>
                                                <td colSpan={4} className="text-right">{orderDetails.taxes[taxKey].label}</td>
                                                <td className="text-right text-nowrap">{numeral(orderDetails.taxes[taxKey].amount).format('0,0.00 $')}</td>
                                            </tr>)}
                                            <tr>
                                                <td colSpan={4} className="text-right"><h5>Totale</h5></td>
                                                <td className="text-right text-nowrap">
                                                    <h5>{numeral(orderDetails.total).format('0,0.00 $')}</h5>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </>}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                {orderDetails && orderDetails.isReactOrder && <Button variant="qualimenti" onClick={() => handleDuplicateOrder(orderDetails.id)}>{loadingDuplicate ? 'Attesa...' : 'Riordina'}</Button>}
                <Button variant="secondary" onClick={() => setOrderDetails(null)}>Chiudere</Button>
            </Modal.Footer>
        </Modal>
    </Row>
}