import React, { useState, useEffect } from 'react';
import { getPartnerCommissions } from '../../../utils/users';
import { Table, Row, Col, Form, Button } from 'react-bootstrap';
import numeral from 'numeral';
import { CgPlayTrackNextR, CgPlayTrackPrevR } from 'react-icons/cg';

export default function CommissionList() {
    const [ commissions, setCommissions ] = useState([]);
    const [ commissionsDisplay, setCommissionsDisplay ] = useState([]);
    const [ month, setMonth ] = useState(new Date().getMonth() + 1);
    const [ year, setYear ] = useState(new Date().getFullYear());
    const [ years, setYears ] = useState([]);
    const [ page, setPage ] = useState(1);
    const perPage = 10;
    const months = [
        {id: 1, name: 'Gennaio'},
        {id: 2, name: 'Febbraio'},
        {id: 3, name: 'Marzo'},
        {id: 4, name: 'Aprile'},
        {id: 5, name: 'Maggio'},
        {id: 6, name: 'Giugno'},
        {id: 7, name: 'Luglio'},
        {id: 8, name: 'Agosto'},
        {id: 9, name: 'Settembre'},
        {id: 10, name: 'Ottobre'},
        {id: 11, name: 'Novembre'},
        {id: 12, name: 'Dicembre'},
    ];

    const statusList = {
        "ptr-pending": "In attesa",
        "ptr-paid": "Pagato",
        "ptr-refused": "Negato",
        "ptr-asked": "Pagamento richiesto",
        "ptr-validated": "Convalidato"
    }

    useEffect(() => {
        let tmpYears = [];
        for(let i = 2023; i <= new Date().getFullYear(); i++) {
            if(!tmpYears.includes(i)) tmpYears.push(i);
            setYears(tmpYears);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setPage(1);
        getPartnerCommissions(month, year).then(commissions => {
            setCommissions(commissions);
            // Set commissions display with 10 commissions max
            let tmpCommissionsDisplay = [];
            for(let i = 0; i < perPage; i++) {
                if(commissions[i]) tmpCommissionsDisplay.push(commissions[i]);
            }
            setCommissionsDisplay(tmpCommissionsDisplay);
        })
    }, [month, year]);

    useEffect(() => {
        if(commissions.length > 0) {
            let offset = page === 0 ? 0 : ((page - 1)*perPage);
            let limit = perPage;

            let tmpCommissionsDisplay = [];
            for(let i = offset; i < (offset + limit); i++) {
                if(commissions[i]) tmpCommissionsDisplay.push(commissions[i]);
            }
            setCommissionsDisplay(tmpCommissionsDisplay);
        }
        // eslint-disable-next-line
    }, [page]);


    return (<Row>
        <Col xs={12}>
            <Row className='mb-3'>
                <Col xs={6}>
                    <Form.Group controlid="formBasicEmail">
                        <Form.Label>Filtra per anno</Form.Label>
                        <Form.Control as="select" onChange={(e) => setYear(e.target.value)}>
                            {years.map(yearItem => (
                                <option key={yearItem} value={yearItem} selected={yearItem === year ? true : false}>{yearItem}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col xs={6}>
                    <Form.Group controlid="formBasicEmail">
                        <Form.Label>Filtra per mese</Form.Label>
                        <Form.Control as="select" onChange={(e) => setMonth(e.target.value)}>
                            {months.map(monthItem => (
                                <option key={monthItem.id} value={monthItem.id} selected={monthItem.id === month ? true : false}>{monthItem.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Comitato</th>
                                <th>Importo</th>
                                <th>Data</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {commissions.length === 0 && <tr><td colSpan={3}>Nessuna commissione trovata</td></tr>}
                            {commissionsDisplay && commissionsDisplay.map(commission => (
                                <tr key={commission.id}>
                                    <td>{commission.title}</td>
                                    <td>{numeral(parseFloat(commission.amount)).format('0,0.00')} €</td>
                                    <td>{commission.date}</td>
                                    <td>{statusList[commission.status]}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            {/* pagination */}
            <Row>
                <Col xs={6} className='text-center'>
                    {page > 1 && <Button variant="light" onClick={() => setPage(page - 1)}>
                        <CgPlayTrackPrevR size={24} /> Precedente
                    </Button>}
                </Col>
                <Col xs={6} className='text-center'>
                    {commissions && commissions.length > page * perPage &&  <Button variant="light" onClick={() => setPage(page + 1)}>
                        Prossimo <CgPlayTrackNextR size={24} />
                    </Button>}
                </Col>
            </Row>
        </Col>
    </Row>)
}