import { useEffect, useState, useContext } from 'react';
import { GlobalContext } from '../../Layout/Layout';

// Data
import { getB2BPaymentMethod, removeB2BPaymentMethod, attachPaymentMethod, getCustomerAdresses } from '../../../utils/users';
import { getStripePubKey } from '../../../utils/common';

// Third party libraries
import { Elements, CardElement, IbanElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Design elements
import { Row, Col, Button, Card, Tabs, Tab, Alert } from 'react-bootstrap';
import { ThreeDots } from 'react-loader-spinner'
import { MdOutlineDeleteOutline } from "react-icons/md";

export default function Payment() {
    const { user } = useContext(GlobalContext);
    const [ paymentMethod, setPaymentMethod ] = useState(null);
    const [ showPaymentForm, setShowPaymentForm ] = useState(false);
    const [ stripePromise, setStripePromise ] = useState(null);
    const [ billingName, setBillingName ] = useState('');
    const [ email, setEmail ] = useState('');

    useEffect(() => {
        getB2BPaymentMethod().then(response => {
            if(response.success) {
                setPaymentMethod(response.payment_method);
            } else {
                console.log(response.message)
                setPaymentMethod({});
            }
        }).catch(error => {
            // console.log(error)
            setPaymentMethod({});
        })

        getStripePubKey().then(response => {
            setStripePromise(loadStripe(response));
        }).catch(error => {
            console.log(error)
        })

        // console.log(user.user_id, user.id, user)
        getCustomerAdresses(user.id).then(response => {
            if(response.billing) {
                setBillingName(response.billing.company ? response.billing.company : response.billing.first_name + ' ' + response.billing.last_name);
            } else {
                console.log(response.message)
            }
        });

        setEmail(user.user_email);
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        console.log(billingName)
    }, [billingName])

    const handleRemovePaymentMethod = () => {
        console.log('remove payment method')
        removeB2BPaymentMethod().then(response => {
            if(response.success) {
                setPaymentMethod({});
            } else {
                console.log(response.message)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const PaymentForm = ({ showPaymentForm, setShowPaymentForm }) => {
        const stripe = useStripe();
        const elements = useElements();
        const [ error, setError ] = useState('');
        const [ loading, setLoading ] = useState(false);

        const handleUpdatePaymentMethod = () => {
            console.log('update payment method')
            if(!stripe || !elements) return;
            setLoading(true);
            // Create payment method and send to server to attach to customer
            if(showPaymentForm === 'card') {
                stripe.createPaymentMethod({
                    type: 'card',
                    card: elements.getElement(CardElement),
                }).then(response => {
                    console.log('Card response', response);
                    if(response.error) {
                        console.log(response.error)
                        setError(response.error.message);
                    } else {
                        console.log(response.paymentMethod)
                        attachPaymentMethod(response.paymentMethod.id).then(payment_method => {
                            setLoading(false);
                            setPaymentMethod(payment_method);
                            setShowPaymentForm(false);
                        }).catch(error => {
                            setLoading(false);
                            console.log(error)
                            setError("Errore durante la creazione del metodo di pagamento");
                        })
                    }
                }).catch(error => {
                    setLoading(false);
                    console.log(error)
                    setError("Errore durante la creazione del metodo di pagamento");
                })
            } else if(showPaymentForm === 'sepa') {
                stripe.createPaymentMethod({
                    type: 'sepa_debit',
                    sepa_debit: elements.getElement(IbanElement),
                    billing_details: {
                        name: billingName,
                        email: email,
                    },
                }).then(response => {
                    console.log('Iban response', response);
                    if(response.error) {
                        console.log(response.error)
                        setError(response.error.message);
                    } else {
                        console.log(response.paymentMethod)
                        attachPaymentMethod(response.paymentMethod.id).then(payment_method => {
                            setLoading(false);
                            setPaymentMethod(payment_method);
                            setShowPaymentForm(false);
                        }).catch(error => {
                            setLoading(false);
                            console.log(error)
                            setError("Errore durante la creazione del metodo di pagamento");
                        })
                    }
                }).catch(error => {
                    setLoading(false);
                    console.log(error)
                    setError("Errore durante la creazione del metodo di pagamento");
                })
            }
        }

        const handlePaymentChange = (event) => {
            if(event.error) {
                setError(event.error.message);
            } else {
                setError('');
            }
        }

        return <>
            <Tabs defaultActiveKey={showPaymentForm} id="uncontrolled-tab-example" className="mb-3 paymentTabs" onSelect={(key) => setShowPaymentForm(key)}>
                <Tab eventKey="card" title="Carta di credito">
                    <Row style={{ justifyContent: 'center' }}>
                        <Col xs={12} md={6} className='p-2'>
                            <CardElement options={{
                                hidePostalCode: true,
                            }} onChange={handlePaymentChange} />
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="sepa" title="Bonifico bancario">
                    <Row style={{ justifyContent: 'center' }}>
                        <Col xs={12} md={6} className='p-2'>
                            <IbanElement options={{
                                supportedCountries: ['SEPA']
                            }} onChange={handlePaymentChange} />
                        </Col>
                    </Row>
                </Tab>
            </Tabs>
            {error && <Row className='justify-content-center mt-3'>
                <Col xs={12} md={6} className='error'>
                    <Alert variant='danger'><small>{error}</small></Alert>
                </Col>
            </Row>}
            <Button variant="qualimenti" className='mt-3 w-100' onClick={handleUpdatePaymentMethod}>
                <div className='d-flex justify-content-center'>{loading ? <ThreeDots width="40" height="25" color="#fff" /> : "Salva"}</div>
            </Button>
        </>
    }

    return <Row>
        <Col xs={12}>
            <Card>
                <Card.Body>
                    <Row>
                        <Col xs={12}>
                            <h4>Metodi di pagamento</h4>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 10 }}>
                        {!showPaymentForm && <Col xs={12} style={{ display: 'flex', justifyContent: 'center'}}>
                            {!paymentMethod && <ThreeDots width="40" height="25" color="var(--qualimenti-color)" />}
                            {paymentMethod && !paymentMethod.type && <div>
                                <p>Non hai ancora inserito un metodo di pagamento</p>
                                <Button variant='qualimenti' style={{ width: '100%' }} onClick={() => setShowPaymentForm('sepa')}>Aggiungi un metodo di pagamento</Button>
                            </div>}
                            {paymentMethod && paymentMethod.type && paymentMethod.type === 'card' && <Card style={{
                                backgroundColor: "#ddd", 
                                minHeight: '200px', 
                                width: '400px'
                            }}>
                                <Card.Body style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'flex-start',
                                    flexDirection: 'column'
                                }}>
                                    <Button onClick={handleRemovePaymentMethod} variant="link" style={{ position: 'absolute', right: 10, top: 10, color: 'var(--qualimenti-color)' }}><MdOutlineDeleteOutline size={20} /></Button>
                                    <Row>
                                        <Col xs={12}>
                                            <h5>Carta di credito</h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <p style={{ textAlign: 'center' }}>**** **** **** {paymentMethod.card.last4} - {paymentMethod.card.exp_month.toString().length === 1 && "0"}{paymentMethod.card.exp_month}/{paymentMethod.card.exp_year}</p>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: '100%' }}>
                                        <Col xs={6}></Col>
                                        <Col xs={6} style={{ textAlign: 'right' }}>{paymentMethod.card.brand}</Col>
                                    </Row>
                                </Card.Body>
                            </Card>}
                            {paymentMethod && paymentMethod.type && paymentMethod.type === 'sepa_debit' && <Card style={{
                                backgroundColor: "#ddd", 
                                minHeight: '200px', 
                                width: '400px'
                            }}>
                                <Card.Body style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'flex-start',
                                    flexDirection: 'column'
                                }}>
                                    <Button onClick={handleRemovePaymentMethod} variant="link" style={{ position: 'absolute', right: 10, top: 10, color: 'var(--qualimenti-color)' }}><MdOutlineDeleteOutline size={20} /></Button>
                                    <Row>
                                        <Col xs={12}>
                                            <h5>Bonifico bancario</h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <p style={{ textAlign: 'center' }}>{paymentMethod.sepa_debit.country}XXXXXXXXXXXXXXXXXX{paymentMethod.sepa_debit.last4}</p>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: '100%' }}>
                                        <Col xs={6}></Col>
                                        <Col xs={6} style={{ textAlign: 'right' }}>SEPA</Col>
                                    </Row>
                                </Card.Body>
                            </Card>}
                        </Col>}
                        {showPaymentForm && <Col xs={12}>
                            <Elements stripe={stripePromise} options={{}}>
                                <PaymentForm showPaymentForm={showPaymentForm} setShowPaymentForm={setShowPaymentForm} />
                            </Elements>
                        </Col>}
                    </Row>
                </Card.Body>
            </Card>
        </Col>
    </Row>
}