import { Row, Col, ButtonGroup, Button } from 'react-bootstrap'
import { useState, useContext, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { GlobalContext } from '../../Layout/Layout'
import { getCustomerAdresses } from '../../../utils/users'

import { Steps } from './Parts/Steps'
import Step1 from './Parts/Step1'
import Step2 from './Parts/Step2'
import Step3 from './Parts/Step3'

import { ThreeDots } from 'react-loader-spinner'
import logo from '../../../assets/img/qualimenti_logo.png'

export const Register = () => {
    const { user } = useContext(GlobalContext)
    const [ step, setStep ] = useState(1);
    const [ loading, setLoading ] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if(user) {
            setLoading(true)
            getCustomerAdresses().then(addresses => {
                setLoading(false)
                if(addresses && addresses.shipping && addresses.shipping.address_1 === "") {
                    setStep(2)
                } else if (user.iva === "") {
                    setStep(3)
                } else {
                    navigate('/')
                }
            }).catch(error => {
            })
        } else {
            setStep(1)
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            overflowX: 'hidden',
        }}>
            <Row className='h-100 w-100' style={{justifyContent: 'center', position: 'relative', backgroundColor: 'white'}}>
                <Col xs={12} md={6}>
                    <Row className='justify-content-end align-items-center h-100'>
                        <Col xs={12} md={9} style={window.width > 768 ? {marginTop: '-3em'} : {}} >
                            <Row>
                                <Col xs={12} className='d-flex justify-content-center'>
                                    <img src={logo} alt="Qualimenti" style={{width: '70%', maxWidth: '300px'}} className='d-block d-md-none' />
                                </Col>
                                <Col xs={12} className='d-flex justify-content-center mt-3'>
                                    <ButtonGroup>
                                        <Button variant="light" className='text-secondary' onClick={(e) => navigate('/login')} style={{width: 120}}>Accedi</Button>
                                        <Button variant="qualimenti" active style={{width: 120}}>Registrati</Button>
                                    </ButtonGroup>
                                </Col>
                                <Row className='mt-3'>
                                     <Col xs={12}>
                                         <Steps step={step} />
                                     </Col>
                                 </Row>
                                {loading && <Col xs={12} className='d-flex justify-content-center mt-3'><ThreeDots color="#ae3335" height={50} width={50} /></Col>}
                                {!loading && <Col xs={12} className='mt-3'>
                                    {step === 1 && <Step1 setStep={setStep} />}
                                    {step === 2 && <Step2 setStep={setStep} />}
                                    {step === 3 && <Step3 setStep={setStep} />}
                                </Col>}
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} md={6} className="d-none d-md-block p-0" style={{
                    backgroundColor: '#F5F5F5',
                    content:'',
                    backgroundRepeat: 'no-repeat',
                    bottom: '-0.1vw',
                    left: '-0.1vw',
                    right: '-0.1vw',
                    top: '-0.1vw', 
                    backgroundSize: "83px 100%",
                    backgroundPosition: '0% 50%',
                    backgroundImage: 'url(\'data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2.17 35.28" preserveAspectRatio="none"><path d="M.5 35.28C1.04 32.2.07 26 .5 19.13.9 12.26 1.06 5.4.38 0H0v35.28z" fill="%23ffffff"/></svg>\')'
                }}>
                    <Row className='justify-content-start align-items-center h-100'>
                        <Col xs={12} md={8} style={{ paddingLeft: '100px', marginTop: window.width > 768 ? 0 : '-3em'}}>
                            <div className="d-flex flex-column align-items-end text-right">
                                <img src={logo} alt="Qualimenti" style={{width: '100%', maxWidth: '300px', display: 'block'}} />
                                <p style={{fontSize: '1.3em', marginTop: '2em'}}>Accedi al più importante mercato online dedicato a ristoranti, bar, hotel, enoteche e negozi alimentari</p>
                                <small>Scegli tra oltre 12.000 prodotti selezionati</small>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}