import { useEffect, useContext } from 'react'
import { getProductCategories } from '../../utils/products'
import { Row, Col, Form, InputGroup, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import { TbHeart } from 'react-icons/tb'
import { GlobalContext } from './Layout'
import '@szhsin/react-menu/dist/index.css';
import glyphe from '../../assets/img/glyphe-white.png'

export default function DesktopMenuCategories() {
    const { searchTerm, setSearchTerm, setCategoryImages, categories, setCategories, setDisplayCart } = useContext(GlobalContext);
    const navigate = useNavigate()

    useEffect(() => {
        if(Object.keys(categories).length === 0) {
            getProductCategories().then(response => {
                let categoryImages = {};
                response.list.map(category => {
                    if(category.categorimage) categoryImages[category.id] = category.categorimage;
                    return true;
                })
                setCategoryImages(categoryImages);
                setCategories(response.tree)
            }).catch(error => {
                console.log(error)
                alert('Unable to fetch categories')
            })
        }
        // eslint-disable-next-line
    }, [])

    const handleSearch = (e) => {
        e.preventDefault();
        if(searchTerm.length >= 3) {
            // navigate('/products/s/' + encodeURIComponent(searchTerm.replace(/[/-]/g, "-")))
            navigate('/products/s/' + encodeURIComponent(searchTerm))
        }
    }

    const handleHomeClick = (e) => {
        e.preventDefault();
        setDisplayCart(false)
        setSearchTerm('')
        navigate('/')
    }

    const goTo = (url) => {
        setSearchTerm('')
        navigate(url)
    }

    return (
        <Row style={{minHeight: '3em', display: 'flex', alignItems: 'center'}} className="d-none d-md-flex text-white">
            <Col xs={12} className="scrollBox">
                {Object.keys(categories).map(key => (
                    <span key={key} className="menu-item" onClick={e => Object.keys(categories[key].childs).length > 0 ? null : goTo(`/products/c/${categories[key].slug}`)}>
                        {Object.keys(categories[key].childs).length > 0 ? (
                            <Menu menuButton={<MenuButton className="me-3">{categories[key].name}</MenuButton>}>
                                <MenuItem onClick={e => goTo(`/products/c/${categories[key].slug}`)}>Tutti i prodotti</MenuItem>
                                {Object.keys(categories[key].childs).map(childKey => (
                                    <div key={childKey}>
                                        {Object.keys(categories[key].childs[childKey].childs).length > 0 ? (
                                            <SubMenu label={categories[key].childs[childKey].name}>
                                                <MenuItem onClick={e => goTo(`/products/c/${categories[key].slug}/${categories[key].childs[childKey].slug}`)}>Tutti i prodotti</MenuItem>
                                                {Object.keys(categories[key].childs[childKey].childs).map(grandChildKey => (
                                                    <MenuItem key={grandChildKey} data-url={`/products/c/${categories[key].slug}/${categories[key].childs[childKey].slug}/${categories[key].childs[childKey].childs[grandChildKey].slug}`} onClick={e => goTo(`/products/c/${categories[key].slug}/${categories[key].childs[childKey].slug}/${categories[key].childs[childKey].childs[grandChildKey].slug}`)} id={grandChildKey}>{categories[key].childs[childKey].childs[grandChildKey].name}</MenuItem>
                                                ))}
                                            </SubMenu>
                                        ) : (
                                            <MenuItem onClick={e => goTo(`/products/c/${categories[key].slug}/${categories[key].childs[childKey].slug}`)}>{categories[key].childs[childKey].name}</MenuItem>
                                        )}
                                    </div>
                                ))}
                            </Menu>
                        ) : categories[key].name}
                    </span>
                ))}
                <Button variant="link" onClick={handleHomeClick} className="text-white me-3 p-0 d-flex align-items-center" style={{textDecoration: 'none'}}><TbHeart className="me-2" style={{marginTop: '-1px'}} /> Il mio catalogo</Button>
                <form onSubmit={handleSearch}>
                    <InputGroup style={{width: 200, marginLeft: 10}}>
                        <Form.Control style={{backgroundColor: "#ffffff85", border: 'none', color: "#fff"}} className="search-control" type="text" placeholder="Cerca..." value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />
                        <Button variant="search-close" onClick={handleSearch}>
                            {/* <FaSearch /> */}
                            <img src={glyphe} alt="glyphe" style={{width: 20}} />
                        </Button>
                    </InputGroup>
                </form>
            </Col>
        </Row>
    )
}