import QualimentiLine from '../../../../assets/img/icons/qualimenti-line.svg'
import QualimentiLineDisabled from '../../../../assets/img/icons/qualimenti-line-disabled.svg'

export const Steps = ({ step }) => {
    if(step > 1) {
        return <div className='d-flex justify-content-center align-items-center'>
            <div className={`rounded-step ${step >= 1 ? 'text-bg-qualimenti' : 'text-bg-qualimenti-disabled'}`}>1</div>
            <img src={step >= 2 ? QualimentiLine : QualimentiLineDisabled } alt="Qualimenti" style={{ width: '40px', marginLeft: 5, marginRight: 5 }} />
            <div className={`rounded-step ${step >= 2 ? 'text-bg-qualimenti' : 'text-bg-qualimenti-disabled'}`}>2</div>
            <img src={step >= 3 ? QualimentiLine : QualimentiLineDisabled} alt="Qualimenti" style={{ width: '40px', marginLeft: 5, marginRight: 5 }} />
            <div className={`rounded-step ${step >= 3 ? 'text-bg-qualimenti' : 'text-bg-qualimenti-disabled'}`}>3</div>
        </div>
    } else {
        return null;
    }
}