import { useState, useEffect } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { getPartnerData } from '../../../utils/users';
import numeral from 'numeral';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';

export default function Dashboard() {
    numeral.locale('it');
    const [ data, setData ] = useState({});
    const [ monthlyData, setMonthlyData ] = useState({});
    const [ dailyData, setDailyData ] = useState({});

    const chartOptions = {
        plugins: {
            tooltip: {
                callbacks: {
                    label: function(context) {
                        var label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += context.parsed.y.toLocaleString('it-IT', {style: 'currency', currency: 'EUR'});
                        }
                        return label;
                    }
                }
            }
        }
    };
    
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    useEffect(() => {
        getPartnerData().then(dashboardData => {
            console.log(dashboardData)
            setData(dashboardData);

            setMonthlyData({
                labels: dashboardData.monthly_commissions.map(item => `${item.year}-${item.month}`),
                datasets: [
                    {
                        label: 'Commissioni mensili',
                        data: dashboardData.monthly_commissions.map(item => item.total),
                        backgroundColor: '#AE3335',
                        // Format data values to euro currency
                        formatter: (value) => numeral(value).format('0,0.00 $')
                    }
                ]
            })

            setDailyData({
                labels: dashboardData.daily_commissions.map(item => item.day),
                datasets: [
                    {
                        label: 'Commissioni giornaliere',
                        data: dashboardData.daily_commissions.map(item => item.total),
                        backgroundColor: '#AE3335'
                    }
                ]
            })
        }).catch(error => {
            console.log(error)
        })
    }, [])

    return (
        <>
            <Row>
                <Col xs={12} md={6}>
                    <Card style={{
                        boxShadow: '1px 1px 5px 0px rgba(0,0,0,0.2)',
                    }}>
                        <Card.Body>
                            <Card.Title style={{
                                fontSize: 16,
                                fontWeight: 'normal',
                                textAlign: 'center'
                            }}>Commissioni del mese</Card.Title>
                            <h2 className='text-center'>{data.month_commissions && numeral(data.month_commissions).format('0,0.00 $')}</h2>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={6}>
                    <Card style={{
                        boxShadow: '1px 1px 5px 0px rgba(0,0,0,0.2)',
                    }}>
                        <Card.Body>
                            <Card.Title style={{
                                fontSize: 16,
                                fontWeight: 'normal',
                                textAlign: 'center'
                            }}>Comitato dell'anno</Card.Title>
                            <h2 className='text-center'>{data.year_commissions && numeral(data.year_commissions).format('0,0.00 $')}</h2>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {dailyData && dailyData.labels && dailyData.labels.length > 0 && <Row className='mt-3'>
                <Col xs={12}>
                    <h2 className="text-center">Commissioni giornaliere</h2>
                    <Bar options={chartOptions} data={dailyData} />
                </Col>
            </Row>}
            {monthlyData && monthlyData.labels && monthlyData.labels.length > 0 && <Row className='mt-3'>
                <Col xs={12}>
                    <h2 className="text-center">Commissioni mensili</h2>
                    <Bar options={chartOptions} data={monthlyData} />
                </Col>
            </Row>}
        </>
    )
}