import { Row, Col, ListGroup } from 'react-bootstrap';
import { useState, useContext, useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { GlobalContext } from '../Layout/Layout';
import Facturation from './parts/Facturation';
import Consegna from './parts/Consegna';
import Security from './parts/Security';
import Payment from './parts/Payment';

export default function Account() {
    const { user } = useContext(GlobalContext);
    const [ mode, setMode ] = useState('personal');

    // Get params from route
    const { key } = useParams();

    useEffect(() => {
        if(key && key === 'delivery') {
            setMode('addresses');
        }

        // eslint-disable-next-line
    }, [])

    if(!user) return <Navigate to="/login" />
    if(user.iva === "") return <Navigate to="/register" />

    return <Row className="justify-content-center">
        <Col xs={12} md={8} className="mt-4">
            <Row>
                <Col xs={12}>
                    <h2>Il mio account</h2>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col xs={12} md={3} className="mb-3">
                    <ListGroup>
                        <ListGroup.Item action active={mode === 'personal' ? true : false} onClick={() => setMode('personal')}>Profilo</ListGroup.Item>
                        <ListGroup.Item action active={mode === 'addresses' ? true : false} onClick={() => setMode('addresses')}>Indirizzo di consegna</ListGroup.Item>
                        <ListGroup.Item action active={mode === 'payment' ? true : false} onClick={() => setMode('payment')}>Metodi di pagamento</ListGroup.Item>
                        <ListGroup.Item action active={mode === 'security' ? true : false} onClick={() => setMode('security')}>Sicurezza</ListGroup.Item>
                    </ListGroup>
                </Col>
                <Col xs={12} md={9}>
                    {mode === 'personal' && <Facturation />}
                    {mode === 'addresses' && <Consegna />}
                    {mode === 'payment' && <Payment />}
                    {mode === 'security' && <Security />}
                </Col>
            </Row>
        </Col>
    </Row>
}