import { useState, useContext, useEffect } from 'react';
import { Row, Col, Form, Button, Alert, Card } from 'react-bootstrap';
import { GlobalContext } from '../../Layout/Layout';
import { getCustomerAdresses, getStates, updateAddress } from '../../../utils/users';


export default function Consegna() {
    const { user, refreshUser, refreshCategories } = useContext(GlobalContext);
    const [addresses, setAddresses] = useState({
        shipping:{},
        billing:{}
    });
    const [ states, setStates ] = useState([]);
    const [ error, setError ] = useState(false);
    const [ success, setSuccess ] = useState(false);

    const handleUpdate = (e) => {
        e.preventDefault();
        // if(typeof addresses.shipping.same_as_billing === "undefined" || addresses.shipping.same_as_billing === "" || addresses.shipping.same_as_billing === "true") {
        if(addresses.shipping.same_as_billing === "true") {
            setAddresses({...addresses, shipping: { ...addresses.shipping, same_as_billing: "true" }})
            setAddresses({...addresses, shipping: { ...addresses.shipping, first_name: addresses.billing.first_name }})
            setAddresses({...addresses, shipping: { ...addresses.shipping, last_name: addresses.billing.last_name }})
            setAddresses({...addresses, shipping: { ...addresses.shipping, address_1: addresses.billing.address_1 }})
            setAddresses({...addresses, shipping: { ...addresses.shipping, address_2: addresses.billing.address_2 }})
            setAddresses({...addresses, shipping: { ...addresses.shipping, city: addresses.billing.city }})
            setAddresses({...addresses, shipping: { ...addresses.shipping, state: addresses.billing.state }})
            setAddresses({...addresses, shipping: { ...addresses.shipping, postcode: addresses.billing.postcode }})
            setAddresses({...addresses, shipping: { ...addresses.shipping, country: addresses.billing.country }})
        } else {
            setAddresses({...addresses, shipping: { ...addresses.shipping, same_as_billing: "false" }})
        }

        updateAddress('shipping', addresses.shipping).then((res) => {
            setSuccess(true);
            refreshUser()
            refreshCategories()
            setTimeout(() => {
                setSuccess(false);
            }, 3000);
        }).catch(error => {
            console.log(error)
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 3000);
        })
    }

    useEffect(() => {
        getCustomerAdresses(user.user_id).then((res) => {
            setAddresses(res);
            getStates(res.shipping.country.length === 2 ? res.shipping.country : "IT").then(response => {
                setStates(response);
            }).catch(error => {
                console.log(error)
            })
        }).catch(error => {
            console.log(error)
        })
        // eslint-disable-next-line
    }, [])

    return <Row>
        <Col xs={12}>
            <Card>
                <Card.Header style={{backgroundColor: '#ae3335', color: 'white'}} className="d-flex justify-content-between">
                    <h5 className="m-0">Indirizzo di consegna</h5>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleUpdate}>
                        <Row className='mt-2'>
                            <Col xs={12} md={12}>
                                {/* Check box input */}
                                <Form.Group controlid="formBasicCheckbox">
                                    <Form.Check 
                                        type="checkbox"
                                        label="L'indirizzo di consegna è lo stesso dell'indirizzo di fatturazione"
                                        checked={addresses.shipping.same_as_billing === "true" ? true : false}
                                        onChange={(e) => setAddresses({...addresses, shipping: { ...addresses.shipping, same_as_billing: e.target.checked === true ? "true" : "false" }})}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <hr />
                        {addresses.shipping.same_as_billing !== "true" && <>
                            <Row className='mt-2'>
                                <Col xs={12} md={6}>
                                    <Form.Group controlid="formBasicEmail">
                                        <Form.Label>Nome</Form.Label>
                                        <Form.Control type="text" placeholder="Nome" value={addresses.shipping.first_name ? addresses.shipping.first_name : ""} onChange={(e) => setAddresses({...addresses, shipping: { ...addresses.shipping, first_name: e.target.value }})} />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group controlid="formBasicEmail">
                                        <Form.Label>Cognome</Form.Label>
                                        <Form.Control type="text" placeholder="Cognome" value={addresses.shipping.last_name ? addresses.shipping.last_name : ""} onChange={(e) => setAddresses({...addresses, shipping: { ...addresses.shipping, last_name: e.target.value }})} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <hr />
                            <Row className='mt-2'>
                                <Col xs={12} md={12}>
                                    <Form.Group controlid="formBasicEmail">
                                        <Form.Label>Nome del ristorante</Form.Label>
                                        <Form.Control type="text" placeholder="Nome del ristorante" value={addresses.shipping.company ? addresses.shipping.company : ""} onChange={(e) => setAddresses({...addresses, shipping: { ...addresses.shipping, company: e.target.value }})} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xs={12} md={12}>
                                    <Form.Group controlid="formBasicEmail">
                                        <Form.Label>Indirizzo di spedizione</Form.Label>
                                        <Form.Control type="text" placeholder="Indirizzo Fatturazione" value={addresses.shipping.address_1 ? addresses.shipping.address_1 : ""} onChange={(e) => setAddresses({...addresses, shipping: { ...addresses.shipping, address_1: e.target.value }})} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xs={12} md={12}>
                                    <Form.Group controlid="formBasicEmail">
                                        <Form.Label>Indirizzo aggiuntivo</Form.Label>
                                        <Form.Control type="text" placeholder="Indirizzo aggiuntivo" value={addresses.shipping.address_2 ? addresses.shipping.address_2 : ""} onChange={(e) => setAddresses({...addresses, shipping: { ...addresses.shipping, address_2: e.target.value }})} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xs={12} md={2}>
                                    <Form.Group controlid="formBasicEmail">
                                        <Form.Label>CAP</Form.Label>
                                        <Form.Control type="text" placeholder="CAP" value={addresses.shipping.postcode ? addresses.shipping.postcode : ""} onChange={(e) => setAddresses({...addresses, shipping: { ...addresses.shipping, postcode: e.target.value }})} />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={10}>
                                    <Form.Group controlid="formBasicEmail">
                                        <Form.Label>Città</Form.Label>
                                        <Form.Control type="text" placeholder="Città" value={addresses.shipping.city ? addresses.shipping.city : ""} onChange={(e) => setAddresses({...addresses, shipping: { ...addresses.shipping, city: e.target.value }})} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xs={12} md={3}>
                                    <Form.Group controlid="formBasicEmail">
                                        <Form.Label>Provincia</Form.Label>
                                        <Form.Select
                                            value={addresses.shipping.state}
                                            onChange={(e) => setAddresses({...addresses, shipping: { ...addresses.shipping, state: e.target.value }})}>
                                                <option value="">Seleziona uno stato</option>
                                                {Object.keys(states).map((stateCode, index) => {
                                                    return <option key={stateCode} value={stateCode}>{states[stateCode]}</option>
                                                })}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={9}>
                                    <Form.Group controlid="formBasicEmail">
                                        <Form.Label>Paese</Form.Label>
                                        <Form.Select
                                            onChange={() => {}}
                                            value={addresses.shipping.country ? addresses.shipping.country : ""}>
                                                <option value="IT">IT</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <hr />
                        </>}
                        <Row className='mt-2'>
                            <Col xs={12} md={12}>
                                <Form.Group controlid="formBasicEmail">
                                    <Form.Label>Telefono</Form.Label>
                                    <Form.Control type="text" placeholder="Telefono" value={addresses.shipping.phone ? addresses.shipping.phone : "" } onChange={(e) => setAddresses({...addresses, shipping: { ...addresses.shipping, phone: e.target.value }})} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <hr />
                        <Row className="mt-2">
                            <Col xs={12} className='mb-2'>
                                <b>Istruzioni per la consegna</b>
                            </Col>
                            <Col xs={6}>
                                <Form.Control
                                    as="textarea"
                                    type="text"
                                    placeholder="Note sull'ordine"
                                    value={addresses.shipping.delivery_notes}
                                    onChange={(e) => setAddresses({...addresses, shipping: { ...addresses.shipping, delivery_notes: e.target.value }})}
                                />
                            </Col>
                            <Col xs={6}>
                                <Form.Select
                                    value={addresses.shipping.delivery_time && addresses.shipping.delivery_time !== "" ? addresses.shipping.delivery_time : "08:00"}
                                    onChange={(e) => setAddresses({...addresses, shipping: { ...addresses.shipping, delivery_time: e.target.value }})}>
                                        {["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"].map((hour, index) => (
                                            <option key={index} value={hour + ":00"}>{hour + ":00"}</option>
                                        ))}
                                </Form.Select>
                            </Col>
                        </Row>
                        <hr />
                        <Row className='mt-2'>
                            <Col xs={12} md={12} className='d-grid'>
                                <Button type="submit" variant="qualimenti" className='btn-lg'>Salva</Button>
                            </Col>
                        </Row>
                        {error && <Row className='mt-2'>
                            <Col xs={12} md={12}>
                                <Alert variant='danger'>Si è verificato un errore durante l'aggiornamento del tuo profilo</Alert>
                            </Col>
                        </Row>}
                        {success && <Row className='mt-2'>
                            <Col xs={12} md={12}>
                                <Alert variant='success'>Profilo aggiornato con successo</Alert>
                            </Col>
                        </Row>}
                    </Form>
                </Card.Body>
            </Card>
        </Col>
    </Row>
}