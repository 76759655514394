import { useState, useEffect } from 'react';

// Third party
import { Row, Col, Form, Button, Alert } from 'react-bootstrap';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import Cookies from 'universal-cookie';

// Functions
import { registerStep2, getStates } from '../../../../utils/users';
import { getPlace } from '../../../../utils/maps';

// Assets
import { ThreeDots } from 'react-loader-spinner'


export default function Step3({ setStep }) {
    const cookies = new Cookies();
    const mapsApiKey = "AIzaSyCm5p1S0oexj3My9srtc4gupMa8l5ypmD8";
    
    const [formattedAddress, setFormattedAddress] = useState({});
    const [displayAddress, setDisplayAddress] = useState(false);
    const [activityType, setActivityType] = useState('');
    const [codice_agent, setCodiceAgent] = useState('');
    const [displayCodiceAgent, setDisplayCodiceAgent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [registerError, setRegisterError] = useState('');
    const [states, setStates] = useState([]);

    useEffect(() => {
        if(cookies.get('ptr') && cookies.get('ptr') !== "") {
            setCodiceAgent(cookies.get('ptr'))
            setDisplayCodiceAgent(true)
        }

        getStates().then(states => {
            setStates(states);
        }).catch(error => {
            console.log(error);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleStep2 = (e) => {
        // console.log(address)
        e.preventDefault();
        setLoading(true);
        registerStep2(formattedAddress, activityType, codice_agent).then(response => {
            if(response) {
                setLoading(false);
                // refreshUser()
                // refreshCategories()
                setStep(3);
            } else {
                setRegisterError("Impossible to salvarsi l'indirizzo");
            }
        }).catch(error => {
            setRegisterError("Impossibile trovare l'indirizzo");
        })
    }

    const handleAddressChange = (address) => {
        setDisplayAddress(true);
        getPlace(address.value.place_id).then(result => {
            console.log(result)
            if(result && result.addressComponents) {
                let addressComponents = result.addressComponents;
                let address = '';
                address = addressComponents.filter(component => component.types.includes('route')).map(component => component.longText).join(' ');
                address += ", " + addressComponents.filter(component => component.types.includes('street_number')).map(component => component.longText).join(' ');
                let addressObject = {
                    company: result.displayName && result.displayName.text && result.primaryTypeDisplayName && result.primaryTypeDisplayName.text !== "Construction" ? result.displayName.text : '',
                    address_1: address,
                    address_2: '',
                    city: addressComponents.filter(component => component.types.includes('locality')).map(component => component.longText).join(' '),
                    state: addressComponents.filter(component => component.types.includes('administrative_area_level_2')).map(component => component.shortText).join(' '),
                    postcode: addressComponents.filter(component => component.types.includes('postal_code')).map(component => component.longText).join(' '),
                    country: addressComponents.filter(component => component.types.includes('country')).map(component => component.shortText).join(' '),
                }
                setFormattedAddress(addressObject);
            }
        });
    }

    return <Row>
        <Col xs={12}>
            <h3 className="text-uppercase text-center">Il tuo locale</h3>
            <Form onSubmit={handleStep2}>
                <Row className="mt-3">
                <Col xs={12}>
                        <label htmlFor="typo" className="form-label">Tipo di attività*</label>
                        <Form.Select
                            id="typo"
                            value={activityType}
                            onChange={(e) => setActivityType(e.target.value)}
                            placeholder='Tipo di attività'
                            required>
                            <option value="">Tipo di attività</option>
                            <option value="Ristoranti">Ristoranti: Gourmet, Trattorie, Cucina etnica...</option>
                            <option value="Caffeterie e Bar">Caffeterie e Bar: Caffetteria, Bar, Cocktail bar, Wine bar, Pub...</option>
                            <option value="Pizzerie">Pizzerie : tradizionali, al taglio...</option>
                            <option value="Negozio alimentare">Negozio alimentare : Gastronomia, Macelleria, Panificio, Pescheria...</option>
                            <option value="Alberghi">Alberghi : Hotel, B&B, Agriturismi...</option>
                            <option value="Catering">Catering : Eventi, Matrimoni, Feste...</option>
                            <option value="Altro">Altro</option>
                        </Form.Select>
                    </Col>
                    <Col xs={12} className="mt-3">
                        <label htmlFor="address" className="form-label">Trova il mio locale</label>
                        <GooglePlacesAutocomplete
                            apiKey={mapsApiKey}
                            id="address"
                            minLengthAutocomplete={3}
                            apiOptions={{ 
                                language: 'it', 
                                region: 'it',
                                fields: ['formatted_address', 'address_components', 'geometry'],
                            }}
                            textInputProps={{
                                required: true,
                            }}
                            
                            selectProps={{
                                onChange: handleAddressChange,
                                placeholder: "Seleziona un indirizzo",
                                required: true,
                                noOptionsMessage: () => "Nessun risultato trovato",
                            }}
                        />
                    </Col>
                    {displayAddress && <Col xs={12} className="mt-3">
                        <Row>
                            <Col xs={12}>
                                <Form.Group controlid="address_1">
                                    <Form.Label>Nome del locale*</Form.Label>
                                    <Form.Control type="text" placeholder="Nome del locale" value={formattedAddress.company} onChange={(e) => setFormattedAddress({...formattedAddress, company: e.target.value})} required />
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                <Form.Group controlid="address_1">
                                    <Form.Label>Indirizzo*</Form.Label>
                                    <Form.Control type="text" placeholder="Indirizzo" value={formattedAddress.address_1} onChange={(e) => setFormattedAddress({...formattedAddress, address_1: e.target.value})} required />
                                </Form.Group>
                            </Col>
                            <Col xs={12} className="mt-3">
                                <Form.Group controlid="address_2">
                                    <Form.Label>Indirizzo aggiuntivo</Form.Label>
                                    <Form.Control type="text" placeholder="Indirizzo aggiuntivo" value={formattedAddress.address_2} onChange={(e) => setFormattedAddress({...formattedAddress, address_2: e.target.value})} />
                                </Form.Group>
                            </Col>
                            <Col xs={3} className="mt-3">
                                <Form.Group controlid="postcode">
                                    <Form.Label>CAP*</Form.Label>
                                    <Form.Control type="text" placeholder="CAP" value={formattedAddress.postcode} onChange={(e) => setFormattedAddress({...formattedAddress, postcode: e.target.value})} required />
                                </Form.Group>
                            </Col>
                            <Col xs={9} className="mt-3">
                                <Form.Group controlid="city">
                                    <Form.Label>Città*</Form.Label>
                                    <Form.Control type="text" placeholder="Città" value={formattedAddress.city} onChange={(e) => setFormattedAddress({...formattedAddress, city: e.target.value})} required />
                                </Form.Group>
                            </Col>
                            <Col xs={6} className="mt-3">
                                <Form.Group controlid="state">
                                    <Form.Label>Provincia*</Form.Label>
                                    {/* <Form.Control type="text" placeholder="Provincia" value={formattedAddress.state} onChange={(e) => setFormattedAddress({...formattedAddress, state: e.target.value})} required /> */}
                                    {/* Select states */}
                                    <Form.Select
                                        value={formattedAddress.state}
                                        onChange={(e) => setFormattedAddress({...formattedAddress, state: e.target.value})}
                                        placeholder='Provincia'
                                        required>
                                        <option value="">Provincia</option>
                                        {Object.keys(states).map((stateCode) => {
                                            return <option key={stateCode} value={stateCode}>{states[stateCode]}</option>
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs={6} className="mt-3">
                                <Form.Group controlid="country">
                                    <Form.Label>Paese*</Form.Label>
                                    <Form.Control type="text" placeholder="Paese" value={formattedAddress.country} onChange={(e) => setFormattedAddress({...formattedAddress, country: e.target.value})} required />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>}
                    <Col xs={12} className="mt-3">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        {!displayCodiceAgent && <Button variant='link' onClick={() => setDisplayCodiceAgent(!displayCodiceAgent)} className='qualimenti-link'>Ho un codice agente</Button>}
                        {displayCodiceAgent && (
                            <Form.Group controlid="codice-agent">
                                <Form.Label>Codice agente</Form.Label>
                                <Form.Control type="text" placeholder="Codice agente" value={codice_agent} onChange={(e) => setCodiceAgent(e.target.value)} />
                            </Form.Group>
                        )}
                    </Col>
                    {registerError && (
                        <Col xs={12} className="mt-3">
                            <Alert variant="danger m-0"><span dangerouslySetInnerHTML={{__html: registerError}} /></Alert>
                        </Col>
                    )}
                    <Col xs={12} className="mt-3 d-flex justify-content-center">
                        <Button variant="qualimenti" type="submit" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50%'}}>
                            {loading ? (
                                <>
                                    <ThreeDots 
                                        width="40"
                                        height="25"
                                        color="#fff"
                                    />
                                    &nbsp;
                                </>
                            ) : (
                                <>Avanti</>
                            )}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Col>
    </Row>
}