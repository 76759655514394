import { Row, Col, Image, Offcanvas, Form, InputGroup, Button } from 'react-bootstrap'
import { GlobalContext } from '../Layout/Layout'
import { useContext, useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'

import { BiLogInCircle } from 'react-icons/bi';
import { FaSearch, FaRegHandshake } from 'react-icons/fa';
import { AiOutlineLogout } from 'react-icons/ai';
import { HiShoppingCart } from 'react-icons/hi';
import { AiOutlineHome } from 'react-icons/ai';
import { MdOutlineAccountCircle } from 'react-icons/md';
import { TbShoppingCart } from 'react-icons/tb';
import { GrMenu } from 'react-icons/gr';
import { MdAdminPanelSettings } from "react-icons/md";

import Cookies from 'universal-cookie';
import MobileMenuCategories from './MobileMenuCategories'
import DesktopMenuCategories from './DesktopMenuCategories'
import MiniCart from '../Products/MiniCart'
// import logo from '../../assets/img/qualimenti-qfb-white.png'
import homeBg from '../../assets/img/bg/home-bg.jpg'
import userIcon from '../../assets/img/user-icon-optimized-2.svg'
import logo from '../../assets/img/qualimenti-logo-white.svg'

const cookies = new Cookies();

export default function Header({props}) {
    const { searchTerm, setSearchTerm, user, cart, setDisplayCart, setDisplayMiniCart } = useContext(GlobalContext)
    const [ showMenu, setShowMenu ] = useState(false)
    const [showAdminBack, setShowAdminBack] = useState(false)
    const [ showCategories, setShowCategories ] = useState(false)
    const [ currentPath, setCurrentPath ] = useState(window.location.pathname)
    const navigate = useNavigate()
    const location = useLocation()

    const handleLogout = (e) => {
        cookies.remove('token');
        document.location.href="/"
    }

    const handleDisplayCart = (e) => {
        setDisplayCart(true);
        setSearchTerm('');
        navigate('/')
    }

    const handleSearch = (e) => {
        e.preventDefault();
        if(searchTerm.length >= 3) {
            setShowMenu(false)
            // navigate('/products/s/' + encodeURIComponent(searchTerm.replace(/[/-]/g, "-")))
            navigate('/products/s/' + encodeURIComponent(searchTerm))
        }
    }

    useEffect(() => {
        setCurrentPath(location.pathname)
    }, [location])

    useEffect(() => {
        // Display admin back button if olduser cookie is set including secure cookies
        const cookiesList = cookies.getAll()
        Object.keys(cookiesList).forEach((key, index) => {
            if(key.indexOf('qualilogin_olduser_') !== -1) {
                setShowAdminBack(true);
            }
        })
    }, [])

    return (
        <>
            <Offcanvas show={showMenu} onHide={() => setShowMenu(false)} placement="end" style={{width: '320px'}}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <div onClick={handleLogout} style={{cursor: 'pointer'}}><AiOutlineLogout /> Logout</div>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Row style={{overflowY: 'auto'}}>
                        <Col xs={12} className="d-flex align-items-center">
                            <Link to="/" onClick={() => setShowMenu(false)} className="sidebar-menu-item"><AiOutlineHome size={32} /> Home</Link>
                        </Col>
                        {user && user.is_partner && (
                            <Col xs={12} className="d-flex align-items-center">
                                <Link to="/partner" onClick={() => setShowMenu(false)} className="sidebar-menu-item"><FaRegHandshake size={32} /> Dashboard agente</Link>
                            </Col>
                        )}
                        <Col xs={12} className="d-flex align-items-center">
                            <Link to="/my-account/orders" onClick={() => setShowMenu(false)} className="sidebar-menu-item"><HiShoppingCart size={32} /> I miei ordini</Link>
                        </Col>
                        <Col xs={12} className="d-flex align-items-center">
                            <Link to="/my-account" onClick={() => setShowMenu(false)} className="sidebar-menu-item"><MdOutlineAccountCircle size={32} /> Il mio account</Link>
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>
            {user && user.id && window.innerWidth < 768 && <MobileMenuCategories showCategories={showCategories} setShowCategories={setShowCategories} />}
            <Row style={{backgroundColor: 'rgba(174, 51, 53, 255)', padding: '10px', paddingLeft: 0, paddingRight: 0}}>
                <Col xs={12} className="pt-2 pb-2">
                    <div className="wrapper">
                        <Row>
                            <Col xs={3} md={3} className='d-flex align-items-center'>
                                {!props.minimalist && window.innerWidth < 768 && user && user.id && (
                                    <div className="category-menu-container">
                                        <GrMenu className='redicon' size={window.innerWidth < 768 ? 20 : 30} onClick={() => setShowCategories(true)} />
                                    </div>
                                )}
                            </Col>
                            <Col xs={6}>
                                <Row>
                                    <Col xs={12} className="d-none d-md-flex align-items-center justify-content-center">
                                        <Link to="/" onClick={() => { setSearchTerm(''); setDisplayCart(false); }}>
                                            <img alt="logo" src={logo} style={{maxHeight: '100px', maxWidth: '100%'}} />
                                        </Link>
                                    </Col>
                                    <Col xs={12} className="d-flex d-md-none align-items-center justify-content-center">
                                        <Link to="/" onClick={() => { setSearchTerm(''); setDisplayCart(false); }} style={{maxWidth: '100%'}}>
                                            <img alt="logo" className="d-block d-md-none" src={logo} style={{maxHeight: '40px', maxWidth: '90%'}} />
                                        </Link>
                                    </Col>
                                </Row>
                            </Col>
                            {!props.minimalist && <Col xs={3} md={3} className='d-flex align-items-center justify-content-end'>
                                {showAdminBack && <div className="cart-container pointer" onClick={() => document.location.href = '/admin-auth?user=back'}>
                                    <MdAdminPanelSettings size={window.innerWidth < 768 ? 20 : 30} />
                                </div>}
                                {user && user.id && (
                                    <div className="position-relative me-0">
                                        <div 
                                            className="cart-container pointer" 
                                            onMouseEnter={() => setDisplayMiniCart(true)} 
                                            onMouseLeave={() => setDisplayMiniCart(false)} 
                                            onClick={handleDisplayCart}>
                                            <TbShoppingCart size={window.innerWidth < 768 ? 20 : 30} />
                                            <div className="cart-quantity">{cart ? cart.count : 0}</div>
                                        </div>
                                        <MiniCart />
                                    </div>
                                )}
                                
                                {user && user.id ? (
                                    <Image src={userIcon} style={window.innerWidth < 768 ? {width: '40px', height: '40px'} : {width: '60px', height: '60px'}} className="pointer" roundedCircle onClick={() => setShowMenu(true)} />
                                ) : (
                                    <a href="/login" style={{color: 'white', textDecoration: 'none'}}>
                                        <BiLogInCircle className='redicon' size={window.innerWidth < 768 ? 30 : 60} color="white" />
                                    </a>
                                )}
                            </Col>}
                        </Row>
                        {!props.minimalist && window.innerWidth >= 768 && user && user.id && (
                            <>
                                <center><hr className="d-none d-md-block" style={{border: '1px solid #fff', marginTop: '2em', marginBottom:'2em', width: '70%', textAlign: 'center'}} /></center>
                                <Row className="d-none d-md-block">
                                    <Col xs={12}>
                                        <DesktopMenuCategories />
                                    </Col>
                                </Row>
                            </>
                        )}
                    </div>
                </Col>
            </Row>
            {!props.minimalist && <Row style={{position: 'relative', marginBottom: 10}}>
                <Col xs={12}>
                    {/* <svg id="wave-header" style={{marginTop: '-1px', left: 0, transform: 'rotate(180deg)', transition: '0.3s'}} viewBox="0 0 1440 100" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient id="sw-gradient-0" x1={0} x2={0} y1={1} y2={0}><stop stopColor="rgba(174, 51, 53, 1)" offset="0%" /><stop stopColor="rgba(174, 51, 53, 1)" offset="100%" /></linearGradient></defs><path style={{transform: 'translate(0, 0px)', opacity: 1}} fill="url(#sw-gradient-0)" d="M0,30L80,40C160,50,320,70,480,71.7C640,73,800,57,960,48.3C1120,40,1280,40,1440,33.3C1600,27,1760,13,1920,6.7C2080,0,2240,0,2400,13.3C2560,27,2720,53,2880,63.3C3040,73,3200,67,3360,68.3C3520,70,3680,80,3840,73.3C4000,67,4160,43,4320,31.7C4480,20,4640,20,4800,26.7C4960,33,5120,47,5280,56.7C5440,67,5600,73,5760,71.7C5920,70,6080,60,6240,51.7C6400,43,6560,37,6720,33.3C6880,30,7040,30,7200,36.7C7360,43,7520,57,7680,58.3C7840,60,8000,50,8160,51.7C8320,53,8480,67,8640,66.7C8800,67,8960,53,9120,43.3C9280,33,9440,27,9600,30C9760,33,9920,47,10080,55C10240,63,10400,67,10560,71.7C10720,77,10880,83,11040,71.7C11200,60,11360,30,11440,15L11520,0L11520,100L11440,100C11360,100,11200,100,11040,100C10880,100,10720,100,10560,100C10400,100,10240,100,10080,100C9920,100,9760,100,9600,100C9440,100,9280,100,9120,100C8960,100,8800,100,8640,100C8480,100,8320,100,8160,100C8000,100,7840,100,7680,100C7520,100,7360,100,7200,100C7040,100,6880,100,6720,100C6560,100,6400,100,6240,100C6080,100,5920,100,5760,100C5600,100,5440,100,5280,100C5120,100,4960,100,4800,100C4640,100,4480,100,4320,100C4160,100,4000,100,3840,100C3680,100,3520,100,3360,100C3200,100,3040,100,2880,100C2720,100,2560,100,2400,100C2240,100,2080,100,1920,100C1760,100,1600,100,1440,100C1280,100,1120,100,960,100C800,100,640,100,480,100C320,100,160,100,80,100L0,100Z" /></svg> */}
                    {window.innerWidth < 768 && currentPath === '/' && (
                        <Row className="justify-content-center" style={{minHeight: 250, backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.30), rgba(0, 0, 0, 0.30)), url(${homeBg})`, backgroundSize: 'cover', backgroundPosition: 'center', paddingTop: '20px'}}>
                            <Col xs={10}>
                                <form onSubmit={handleSearch}>
                                    <InputGroup style={{marginTop: 20}}>
                                        <Form.Control style={{backgroundColor: 'rgba(255,255,255,.9)', borderTopLeftRadius: '40px', borderBottomLeftRadius: '40px'}} type="text" placeholder="Cerca..." value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />
                                        <Button variant="search-close" style={{backgroundColor: 'rgba(255,255,255,.9)', borderTopRightRadius: '40px', borderBottomRightRadius: '40px'}} onClick={handleSearch}>
                                            <FaSearch />
                                        </Button>
                                    </InputGroup>
                                </form>
                            </Col>
                            <Col xs={12} className='ps-4'>
                                <span style={{fontWeight: '700', fontSize: '22px', color: "white"}}>Alimenti di Qualità<br />per la Ristorazione.</span>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>}
        </>
    )
}