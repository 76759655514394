import { useState, useContext } from 'react';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { GlobalContext } from '../Layout/Layout';
import Dashboard from './parts/Dashboard.js';
import CommissionList from './parts/CommissionList.js';

export default function Partner() {
    const { user } = useContext(GlobalContext);
    const [ tab, setTab ] = useState('dashboard');

    if(!user) return <Navigate to="/login" />
    if(user.iva === "") return <Navigate to="/register" />

    return (<Row className="justify-content-center">
        <Col xs={12} md={8} className="mt-4">
            <h1>Dashboard agente</h1>
            <Tabs activeKey={tab} onSelect={(k) => setTab(k)}>
                <Tab eventKey="dashboard" title="Dashboard" style={{backgroundColor: 'white', padding: 10, borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopRightRadius: 10}}>
                    <Dashboard />
                </Tab>
                <Tab eventKey="list" title="Elenco dei comitati" style={{backgroundColor: 'white', padding: 10, borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopRightRadius: 10}}>
                    <CommissionList />
                </Tab>
            </Tabs>
            
        </Col>
    </Row>)

}