import { useEffect, useContext, useState } from 'react'
import { getProductCategories } from '../../utils/products'
import { Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { GlobalContext } from './Layout'
import '@szhsin/react-menu/dist/index.css';
import { GrMenu, GrClose } from 'react-icons/gr';
import logo from '../../assets/img/qualimenti_logo.png';


import MultilevelSidebar from "react-multilevel-sidebar";
import "react-multilevel-sidebar/src/Sidebar.css";

export default function MobileMenuCategories({showCategories, setShowCategories}) {
    const { setSearchTerm, setCategoryImages, categories, setCategories } = useContext(GlobalContext);
    const [ options, setOptions ] = useState([]);
    const navigate = useNavigate()

    const handleCategorySiteBarToggle = (isOpen) => {
        setShowCategories(isOpen)
    }

    const handleClick = (e) => {
        console.log(e)
        if(e.goTo) {
            handleCategorySiteBarToggle(false)
            setSearchTerm('')
            navigate(e.goTo)
        }
    }

    useEffect(() => {
        if(Object.keys(categories).length === 0) {
            getProductCategories().then(response => {
                let categoryImages = {};
                response.list.map(category => {
                    if(category.categorimage) categoryImages[category.id] = category.categorimage;
                    return true;
                })
                setCategoryImages(categoryImages);
                setCategories(response.tree)
            }).catch(error => {
                console.log(error)
                alert('Unable to fetch categories')
            })
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let tmpOptions = [{
            hideBorder: false,
            content: [{
                id: 0,
                name: <span style={{ color: '#ae3335' }}>Il mio catalogo</span>,
                titleIcon: <i className="fa fa-paragraph"></i>,
                hideBorder: true,
                goTo: `/`
            }]
        }];
        Object.keys(categories).map(key => {
            let tmpCategory = {
                id: parseInt(key.replace('cat', '')),
                name: categories[key].name,
                titleIcon: <i className="fa fa-paragraph"></i>,
                hideBorder: false,

                // content: [{
                //     id: parseInt(key.replace('cat', '')),
                //     name: 'Tutti i prodotti',
                //     titleIcon: <i className="fa fa-paragraph"></i>,
                //     hideBorder: true,
                //     goTo: `/products/c/${categories[key].slug}`
                // }]
            };

            if(Object.keys(categories[key].childs).length > 0) {
                tmpCategory.children = [{
                    name: <span style={{fontWeight: 500}}>Tutti i prodotti</span>,
                    titleIcon: <i className="fa fa-paragraph"></i>,
                    hideBorder: true,
                    content: [
                        {
                            name: <span style={{fontWeight: 500}}>Tutti i prodotti</span>,
                            titleIcon: <i className="fa fa-paragraph"></i>,
                            hideBorder: true,
                            goTo: `/products/c/${categories[key].slug}`
                        }
                    ]
                }];
                Object.keys(categories[key].childs).map(childKey => {
                    let tmpChild = {
                        id: parseInt(childKey.replace('cat', '')),
                        name: categories[key].childs[childKey].name,
                        titleIcon: <i className="fa fa-paragraph"></i>,
                        hideBorder: true,
                        content: [
                            {
                                id: parseInt(childKey.replace('cat', '')),
                                name: categories[key].childs[childKey].name,
                                titleIcon: <i className="fa fa-paragraph"></i>,
                                hideBorder: true,
                            }
                        ]
                    };
                    if(Object.keys(categories[key].childs[childKey].childs).length > 0) {
                        tmpChild.content[0].children = [{
                            name: <span style={{fontWeight: 500}}>Tutti i prodotti</span>,
                            icon: <i className="fa fa-paragraph"></i>,
                            hideBorder: true,
                            content: [
                                {
                                    name: <span style={{fontWeight: 500}}>Tutti i prodotti</span>,
                                    icon: <i className="fa fa-paragraph"></i>,
                                    hideBorder: true,
                                    goTo: `/products/c/${categories[key].slug}/${categories[key].childs[childKey].slug}`
                                }
                            ]
                        }];
                        Object.keys(categories[key].childs[childKey].childs).map(grandChildKey => {
                            tmpChild.content[0].children.push({
                                id: parseInt(grandChildKey.replace('cat', '')),
                                name: categories[key].childs[childKey].childs[grandChildKey].name,
                                icon: <i className="fa fa-chrome"></i>,
                                hideBorder: true,
                                content: [
                                    {
                                        id: parseInt(grandChildKey.replace('cat', '')),
                                        name: categories[key].childs[childKey].childs[grandChildKey].name,
                                        icon: <i className="fa fa-chrome"></i>,
                                        hideBorder: true,
                                        goTo: `/products/c/${categories[key].slug}/${categories[key].childs[childKey].slug}/${categories[key].childs[childKey].childs[grandChildKey].slug}`
                                    }
                                ]
                            })
                            return true;
                        })
                    } else {
                        tmpChild.content[0].goTo = `/products/c/${categories[key].slug}/${categories[key].childs[childKey].slug}`
                    }
                    tmpCategory.children.push(tmpChild);
                    return true;
                });
            }
            tmpOptions[0].content.push(tmpCategory);
            return true;
        })
        setOptions(tmpOptions)
    }, [categories])

    return (
        <MultilevelSidebar
                open={showCategories}
                onToggle={handleCategorySiteBarToggle}
                options={options}
                persist={false}
                onItemClick={handleClick}
                wrapperClassName="mobile-menu"
                headerClassName="mobile-menu-header"
                header={
                    <Row style={{backgroundColor: '#fff', padding: '10px', paddingLeft: 10, paddingRight: 10}}>
                        <Col xs={12} className="pt-2 pb-2">
                            <Row>
                                <Col xs={3}>
                                    <div className="category-menu-container inverted">
                                        <GrMenu className='whiteicon' size={window.innerWidth < 768 ? 20 : 30} onClick={() => handleCategorySiteBarToggle(false)} />
                                    </div>
                                </Col>
                                <Col xs={6}>
                                    <img alt="logo" src={logo} style={{maxWidth: '100%'}} />
                                </Col>
                                <Col xs={3} style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <div className="category-menu-container outlined">
                                        <GrClose className='redicon' size={window.innerWidth < 768 ? 20 : 30} onClick={() => handleCategorySiteBarToggle(false)} />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                </Row>
                }
            />
    )
}